import React, { useState } from "react";
import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Box } from "@mui/material";
import { format, parseISO } from "date-fns";
import { CalenderTimeIcon } from "../Icons";

const API_DATE_FORMAT = "yyyy-MM-dd"; // Format for API

interface DateRangePickerProps {
  control: any; // React Hook Form's control
  name: string;
  startDateKey?: string;
  endDateKey?: string;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  control,
  name,
  startDateKey = "start_date",
  endDateKey = "end_date",
}) => {
  const [tempDates, setTempDates] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        // Parse stored values into Date objects if they exist
        const startDate = value?.[startDateKey]
          ? parseISO(value[startDateKey])
          : null;
        const endDate = value?.[endDateKey]
          ? parseISO(value[endDateKey])
          : null;

        return (
          <Box className="flex">
            <DatePicker
              icon={
                <div className="flex h-full !w-[24px] mt-[2px] !pr-3">
                  <CalenderTimeIcon />
                </div>
              }
              showIcon
              selectsRange
              startDate={startDate}
              endDate={endDate}
              selected={startDate}
              onChange={(dates: [Date | null, Date | null]) => {
                setTempDates(dates);

                // Ensure API gets formatted strings
                onChange({
                  [startDateKey]: dates[0]
                    ? format(dates[0], API_DATE_FORMAT)
                    : null,
                  [endDateKey]: dates[1]
                    ? format(dates[1], API_DATE_FORMAT)
                    : null,
                });
              }}
              onBlur={() => {
                if (tempDates[0] && !tempDates[1]) {
                  setTempDates([null, null]); // Clear selection if only one date is selected
                  onChange({ [startDateKey]: null, [endDateKey]: null });
                }
              }}
              popperClassName="!z-50"
              dateFormat="MM/dd/yyyy"
              className="!px-[12px] !pl-8 !py-[10px] rounded-md border w-full border-[#E9ECF2] bg-[#F4F5F8] focus:outline-none focus:ring-0"
              placeholderText="Select Days"
            />
          </Box>
        );
      }}
    />
  );
};

export default DateRangePicker;
