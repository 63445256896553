import { Box, Button, Typography } from "@mui/material";
import { ArrowLeftIcon, ArrowRightIcon2 } from "../../Icons";
import ReportsStepOne from "./reportsStepOne";
import ReportsStepTwo from "./reportsStepTwo";
import ReportsStepThree from "./reportsStepThree";
import ReportsSectionFour from "./reportsStepFour";
import ReportHeader from "../../common/ReportHeader";
import {
  ReportStepperWrapperType,
  ReportType,
} from "../../../types/reportTypes";
import { reportStepperTitleAndSubtitle } from "../../../utils/data";
import { useEffect, useState } from "react";
import { isValid } from "date-fns";
import { useSelector } from "react-redux";
import { campaignsSelector } from "../../../store/campaigns/selector";

const ReportStepperWrapper = ({
  handleNextSection,
  handlePreviousSection,
  reportStepperForm,
  stepper,
  setStepper,
}: ReportStepperWrapperType) => {
  const { getValues } = reportStepperForm;
  const formValues = getValues();
  const campaignList = useSelector(campaignsSelector);

  const [isRequired, setIsRequired] = useState(true);

  const validationRules = [
    (formValues: ReportType) => formValues?.platform?.length > 0,
    (formValues: ReportType) => formValues?.campaignIds?.length > 0 && campaignList?.data?.length > 0,
    (formValues: ReportType) => formValues?.metrics?.length > 0,
    (formValues: ReportType) =>
      isValid(formValues?.start_date) && isValid(formValues?.end_date),
  ];

  const validateCurrentStep = () => {
    const isValid = validationRules[stepper](formValues);
    setIsRequired(!isValid);
  };

  useEffect(() => {
    validateCurrentStep();
  }, [stepper, formValues]);

  const handleNextStep = () => {
    if (stepper < reportStepperTitleAndSubtitle.length - 1 && !isRequired) {
      setStepper(stepper + 1);
    }
  };

  const handlePreviousStep = () => {
    if (stepper > 0) {
      setStepper(stepper - 1);
    }
  };

  const handleSection = (section: number) => {
    switch (section) {
      case 0:
        return <ReportsStepOne reportStepperForm={reportStepperForm} />;
      case 1:
        return <ReportsStepTwo reportStepperForm={reportStepperForm} />;
      case 2:
        return <ReportsStepThree reportStepperForm={reportStepperForm} />;
      case 3:
        return <ReportsSectionFour reportStepperForm={reportStepperForm} />;
      default:
        return <ReportsStepOne reportStepperForm={reportStepperForm} />;
    }
  };

  return (
    <Box className="flex justify-center">
      <Box className="lg:w-[80%] p-10 flex-column bg-white gap-10 outline-none rounded-xl">
        {/* Header */}
        <Box className="flex-column gap-[15px] ">
          <Typography className="!text-base !font-medium text-primary-black-600">
            Step {stepper + 1} of 4
          </Typography>
          <ReportHeader
            title={reportStepperTitleAndSubtitle[stepper].title}
            subtitle={reportStepperTitleAndSubtitle[stepper].subtitle}
          />
        </Box>

        {handleSection(stepper)}

        <Box className="flex justify-center md:justify-end gap-[15px] p-7 rounded-[20px] bg-primary-blue-50">
          {stepper > 0 ? (
            <Button
              className="!px-5 !py-2.5 !rounded-xl !border !border-primary-blue-950 !text-primary-blue-950 !capitalize gap-[5px]"
              variant="outlined"
              onClick={() => {
                if (stepper === 0) handlePreviousSection();
                else handlePreviousStep();
              }}
            >
              {ArrowLeftIcon}
              <Typography className="!text-base !font-medium">Back</Typography>
            </Button>
          ) : (
            <></>
          )}

          <Button
            disabled={isRequired}
            onClick={() => {
              if (stepper === 3) handleNextSection();
              else handleNextStep();
            }}
            className={`!gap-[5px] !cursor-pointer !rounded-xl !px-[30px] !py-4  flex-center ${
              !isRequired ? "!bg-primary-blue-950" : "!bg-primary-black-600"
            } `}
          >
            <Typography className="!text-base !font-medium text-white !normal-case">
              Next
            </Typography>
            {ArrowRightIcon2}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ReportStepperWrapper;
