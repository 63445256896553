import { createSlice } from "@reduxjs/toolkit";
import { CampaignsInitialStateType } from "./types";
import { subDays } from "date-fns";

// SAGA FLOW EXAMPLE FOR FUTURE API INTEGRATION

export const initialState: CampaignsInitialStateType = {
  isLoading: false,
  campaigns: {
    data: [],
    total: 0,
  },
  filter: {
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
    dataSource: "All",
  },
  search: "",
  skip: 0,
};

const campaigns = createSlice({
  name: "campaignsState",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    // Step-3: Saving the payload in state
    setCampaigns(state, action) {
      state.campaigns = action.payload;
    },
    setCampaignFilter(state, action) {
      state.filter = action.payload;
    },
    setCampaignSkip(state, action) {
      state.skip = action.payload;
    },
    setCampaignSearch(state, action) {
      state.search = action.payload;
    },
  },
});

export const {
  actions: CampaignsReducerAction,
  name: CampaignsReducerName,
  reducer: CampaignsReducer,
} = campaigns;
