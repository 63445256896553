import { Box, Stack, Typography } from "@mui/material";
import { getCurrentUser } from "../../utils/utils";

const ProfileForm = () => {
  const currentUser = getCurrentUser();

  return (
    <Box className="gap-5 grid md:grid-cols-2">
      <ProfileField label="First Name" value={currentUser?.first_name} />
      <ProfileField label="Last Name" value={currentUser?.last_name} />
      <ProfileField label="Phone Number" value={currentUser?.phone_number} />
      <ProfileField label="Email" value={currentUser?.email} />
    </Box>
  );
};

const ProfileField = ({
  label,
  value,
}: {
  label: string;
  value: string | undefined;
}) => (
  <Stack className="flex-col gap-2.5">
    <Box>
      <Typography
        variant="subtitle2"
        className="!text-primary-black-600 !text-sm !font-medium"
      >
        {label}
      </Typography>
    </Box>
    <Box className="flex items-center relative w-full p-3 rounded-lg border border-primary-blue-100 gap-2.5 bg-primary-blue-50">
      <Typography variant="body1" className="!text-sm !font-normal text-primary-black-950">
        {value || "N/A"}
      </Typography>
    </Box>
  </Stack>
);

export default ProfileForm;
