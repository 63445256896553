import { createSliceSaga } from "../saga-helper";
import { call, put } from "redux-saga/effects";
import { BreadcrumbItem, BreadcrumbReducerAction } from "./slice";
import { PayloadAction } from "@reduxjs/toolkit";
import { breadcrumbMap } from "../../utils/appConstants";

function* updateBreadcrumbsSaga(
  action: PayloadAction<{ path: string; dynamicName?: string }>
) {
  try {
    const { path, dynamicName } = action.payload;
    const breadcrumbInfo = breadcrumbMap[path];

    if (!breadcrumbInfo) return;

    const breadcrumbs: BreadcrumbItem[] = [];

    breadcrumbs.push({
      label: breadcrumbInfo.parent,
      path: breadcrumbInfo.parentPath || "",
    });

    if (breadcrumbInfo.dynamicChild && dynamicName) {
      breadcrumbs.push({
        label: dynamicName,
        path: breadcrumbInfo.subChild ? "-1" : "",
      });
    }

    if (breadcrumbInfo.child) {
      breadcrumbs.push({ label: breadcrumbInfo.child, path: "" });
    }

    yield put(BreadcrumbReducerAction.setBreadcrumbs(breadcrumbs));
  } catch (error) {
    console.error("Error updating breadcrumbs:", error);
  }
}

const saga = createSliceSaga({
  name: "breadcrumb-saga-state",
  caseSagas: {
    *updateBreadcrumbs(
      action: PayloadAction<{ path: string; dynamicName?: string }>
    ) {
      yield call(updateBreadcrumbsSaga, action);
    },
  },
  sagaType: "takeLatest",
});

export const {
  actions: BreadcrumbSagaAction,
  saga: BreadcrumbSaga,
  name: BreadcrumbSagaName,
} = saga;
