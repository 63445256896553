import axiosClient from "../apiClient";

export const GetClientDropDownListService = async () => {
  try {
    const response = await axiosClient.get("accounts");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const SetSelectedClientService = async (account_id: string) => {
  try {
    const response = await axiosClient.post("accounts", { account_id });
    return response.data;
  } catch (error) {
    throw error;
  }
};
