import React, { useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { InfoDarkIcon } from "../../../Icons";
import { UseFormReturn } from "react-hook-form";
import { MetricsType, ReportType } from "../../../../types/reportTypes";
import { useDispatch } from "react-redux";
import { ReportsSagaAction } from "../../../../store/report/sagas";
import { useSelector } from "react-redux";
import {
  isReportLoadingSelector,
  metricsSelector,
} from "../../../../store/report/selector";

const ReportsStepThree = ({
  reportStepperForm,
}: {
  reportStepperForm: UseFormReturn<ReportType>;
}) => {
  const { watch, setValue } = reportStepperForm;
  const dispatch = useDispatch();
  const isMetricsLoading = useSelector(isReportLoadingSelector);
  const metrics = useSelector(metricsSelector);

  const selectedMetrics = watch("metrics") || [];

  const handleCheckboxChange = (name: string) => {
    const isSelected = selectedMetrics.includes(name);

    if (isSelected) {
      setValue(
        "metrics",
        selectedMetrics.filter((metric: string) => metric !== name)
      );
    } else {
      setValue("metrics", [...selectedMetrics, name]);
    }
  };

  useEffect(() => {
    dispatch(ReportsSagaAction.getMetrics());
  }, []);

  const formatName = (name: string) => {
    return name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  return (
    <Box>
      {isMetricsLoading ? (
        <Box className="flex flex-col justify-center items-center">
          <CircularProgress size={24} />
          Loading metrics...
        </Box>
      ) : metrics?.length === 0 ? (
        <Box className="flex flex-col justify-center items-center">
          <Typography>No metrics found!</Typography>
        </Box>
      ) : (
        <Box className="grid grid-cols-1 md:grid-cols-2 gap-5">
          {metrics.map((metric: MetricsType) => (
            <Box
              key={metric.id}
              className="flex-between-center w-full border rounded-xl p-5 border-primary-blue-100"
            >
              <Box className="flex items-center gap-[5px]">
                <Checkbox
                  checked={selectedMetrics.includes(metric.name)}
                  onChange={() => handleCheckboxChange(metric.name)}
                />
                <Typography>{formatName(metric.name)}</Typography>
              </Box>
              {InfoDarkIcon}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default ReportsStepThree;
