import { Box, Button, Typography } from "@mui/material";
import { ArrowLeftIcon } from "../../Icons";
import CustomTextField from "../../formControls/CustomTextField";
import { RendererType } from "../../../types/enums/enums";
import ReportHeader from "../../common/ReportHeader";
import { useDispatch } from "react-redux";
import { ReportNameSectionType } from "../../../types/reportTypes";
import { ReportsSagaAction } from "../../../store/report/sagas";

const ReportNameSection = ({
  handlePreviousSection,
  reportStepperForm,
  handleNextSection,
}: ReportNameSectionType) => {
  const dispatch = useDispatch();

  const {
    formState: { errors, isValid },
    control,
    getValues,
  } = reportStepperForm;

  const handleGenerateReport = async () => {
    const formValues = getValues();

    const transformedData = {
      ...formValues,
      platforms: formValues.platform,
      campaignIds: formValues.campaignIds,
      metrics: formValues.metrics,
    };

    await new Promise<void>((resolve) => {
      dispatch(ReportsSagaAction.postReport({transformedData, resolve}));
    });
    handleNextSection();
  };

  return (
    <Box className="flex justify-center">
      <Box className="lg:w-[80%] p-10 flex-column bg-white gap-10 outline-none rounded-xl">
        {/* Header */}
        <ReportHeader
          title="Define Report Name"
          subtitle="Lorem ipsum dolor sit amet consectetur. Sapien quis laoreet tempor
              dolor massa dignissim in tellus tellus."
        />

        <CustomTextField
          label=""
          name="report_name"
          required
          placeholder="Enter Report Name Here... "
          type={RendererType.string}
          control={control}
          errors={errors.report_name?.message?.toString()}
          rules={{
            required: "Report Name is Required",
          }}
        />

        <Box className="flex-column md:flex-row justify-end gap-[15px] p-7 rounded-[20px] bg-primary-blue-50">
          <Button
            className="!px-5 !py-2.5 !rounded-xl !border !border-primary-blue-950 !text-primary-blue-950 !capitalize gap-[5px]"
            variant="outlined"
            onClick={() => {
              handlePreviousSection();
            }}
          >
            {ArrowLeftIcon}
            <Typography className="!text-sm !font-medium">Back</Typography>
          </Button>

          <Button
            onClick={handleGenerateReport}
            disabled={!isValid}
            className={`!cursor-pointer !capitalize !rounded-xl !p-[10px] ${
              isValid ? "!bg-primary-blue-950" : "!bg-primary-black-600"
            } flex-center`}
          >
            <Typography className="!text-sm !font-medium text-white">
              Generate Report
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ReportNameSection;
