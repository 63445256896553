import { AxiosResponse } from "axios";
import { createSliceSaga } from "../saga-helper";
import { ManageDataSourceReducerAction } from "./slice";
import { call, put } from "redux-saga/effects";
import {
  DisconnectDataSourceService,
  FacebookAuthService,
  GoogleAuthService,
  ManageDataSources,
} from "../../services/manageDataSourceServices";
import { DataSourceEnum } from "./type";

// SAGA FLOW EXAMPLE FOR FUTURE API INTEGRATION

const saga = createSliceSaga({
  name: "manage-data-source-saga-state",
  caseSagas: {
    *getDataSource() {
      try {
        yield put(
          ManageDataSourceReducerAction.setIsLoading({
            key: "all",
            value: true,
          })
        );
        // Step-2: Calling the Service file API and setting the data in reducer
        const { data, status }: AxiosResponse = yield call(ManageDataSources);
        if (status === 200) {
          yield put(ManageDataSourceReducerAction.setDataSource(data.data));
        }
      } catch (error) {
        console.error("Error fetching users list");
      } finally {
        yield put(
          ManageDataSourceReducerAction.setIsLoading({ key: "", value: false })
        );
      }
    },
    *connectDataSource(action: any) {
      try {
        yield put(
          ManageDataSourceReducerAction.setIsLoading({
            key: action.payload,
            value: true,
          })
        );

        const serviceMap: any = {
          [DataSourceEnum.Google]: GoogleAuthService,
          [DataSourceEnum.Facebook]: FacebookAuthService,
          [DataSourceEnum.Tiktok]: FacebookAuthService,
        };

        const service = serviceMap[action.payload];

        if (!service) {
          throw new Error("Invalid data source type");
        }

        const { status, data } = yield call(service);
        if (status === 200) {
          window.location.href = data.redirectUrl;
        }
      } catch (error) {
        console.error("Error connecting Data Source", error);
      } finally {
        yield put(
          ManageDataSourceReducerAction.setIsLoading({ key: "", value: false })
        );
      }
    },
    *authGoogleLogin() {
      try {
        yield put(
          ManageDataSourceReducerAction.setIsLoading({
            key: "all",
            value: true,
          })
        );
        const { status }: AxiosResponse = yield call(GoogleAuthService);
        if (status === 200) {
          yield call(ManageDataSourceSagaAction.getDataSource());
        }
      } catch (error) {
        console.error("Error authenicating google login");
      } finally {
        yield put(
          ManageDataSourceReducerAction.setIsLoading({ key: "", value: false })
        );
      }
    },
    *authFacebookLogin() {
      try {
        yield put(
          ManageDataSourceReducerAction.setIsLoading({
            key: "all",
            value: true,
          })
        );
        const { status }: AxiosResponse = yield call(GoogleAuthService);
        if (status === 200) {
          yield call(ManageDataSourceSagaAction.getDataSource());
        }
      } catch (error) {
        console.error("Error authenicating meta login");
      } finally {
        yield put(
          ManageDataSourceReducerAction.setIsLoading({ key: "", value: false })
        );
      }
    },
    *disconnectDataSource(action: {
      payload: {
        provider: string;
      };
    }) {
      try {
        yield put(
          ManageDataSourceReducerAction.setIsLoading({
            key: action.payload,
            value: true,
          })
        );

        const { status }: AxiosResponse = yield call(
          DisconnectDataSourceService,
          action.payload
        );

        if (status === 200) {
          yield put(ManageDataSourceSagaAction.getDataSource());
        }
      } catch (error) {
        console.error("Error disconnecting Data Source", error);
      } finally {
        yield put(
          ManageDataSourceReducerAction.setIsLoading({ key: "", value: false })
        );
      }
    },
    //TODO: Add later once confirmed
    // *authTiktokLogin() {
    //   try {
    //     yield put(ManageDataSourceReducerAction.setIsLoading(true));
    //     const { status }: AxiosResponse = yield call(TikTokAuthService);
    //     if (status === 200) {
    //       yield call(ManageDataSourceSagaAction.getDataSource());
    //     }
    //   } catch (error) {
    //     console.error("Error authenicating tiktok login");
    //   } finally {
    //     yield put(ManageDataSourceReducerAction.setIsLoading(false));
    //   }
    // },
  },
  sagaType: "takeEvery",
});

export const {
  actions: ManageDataSourceSagaAction,
  saga: ManageDataSourceSaga,
  name: ManageDataSourceSagaName,
} = saga;
