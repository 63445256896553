import {
  Box,
  Checkbox,
  CircularProgress,
  TableCell,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { CampaignIdsType, ReportType } from "../../../../types/reportTypes";
import VirtuosoList from "../../../common/VirtuosoList";
import { useSelector } from "react-redux";
import {
  campaignsSearchSelector,
  campaignsSelector,
  campaignsSkipSelector,
  isCampaignsLoadingSelector,
} from "../../../../store/campaigns/selector";
import { useDispatch } from "react-redux";
import { CampaignsSagaAction } from "../../../../store/campaigns/sagas";
import { endOfYear, format, startOfYear } from "date-fns";
import { CampaignsReducerAction } from "../../../../store/campaigns/slice";
import { DATE_FORMAT } from "../../../../utils/appConstants";
import CustomTextField from "../../../formControls/CustomTextField";
import { GridSearchIcon } from "@mui/x-data-grid";
import { RendererType } from "../../../../types/enums/enums";
import { debounce } from "lodash";

const ReportsStepTwo = ({
  reportStepperForm,
}: {
  reportStepperForm: UseFormReturn<ReportType>;
}) => {
  const dispatch = useDispatch();
  const campaignList = useSelector(campaignsSelector);
  const isCampaignsLoading = useSelector(isCampaignsLoadingSelector);
  const campaignsSkip = useSelector(campaignsSkipSelector);
  const campaignsSearch = useSelector(campaignsSearchSelector);
  const [hasMore, setHasMore] = useState(true);
  const [selectedPlatform, setSelectedPlatform] = useState<string[]>([]);

  const { watch, setValue } = reportStepperForm;

  const selectedCampaigns = watch("campaignIds") || [];
  const watchPlatform = watch("platform") || [];
  const step2Form = useForm();

  const transformSelectedPlatform = (input: any) => {
    if (input.length === 4) return null;

    return input.map((item: { name: string; icon: any }) =>
      item.name.toLowerCase()
    );
  };

  const debouncedSearch = useCallback(
    debounce((query: string) => {
      dispatch(CampaignsReducerAction.setCampaignSkip(0));
      dispatch(CampaignsReducerAction.setCampaigns({ data: [], total: 0 }));
      dispatch(CampaignsReducerAction.setCampaignSearch(query));
    }, 500),
    []
  );

  const handleCheckboxChange = (campaign: CampaignIdsType) => {
    const isSelected = selectedCampaigns.some(
      (selected: any) => selected.campaign_name === campaign.campaign_name
    );

    if (isSelected) {
      setValue(
        "campaignIds",
        selectedCampaigns.filter(
          (selected: any) => selected.campaign_name !== campaign.campaign_name
        )
      );
    } else {
      setValue("campaignIds", [...selectedCampaigns, campaign]);
    }
  };
  useEffect(() => {
    dispatch(CampaignsReducerAction.setCampaignSkip(0));
    dispatch(CampaignsReducerAction.setCampaigns({ data: [], total: 0 }));
  }, []);

  useEffect(() => {
    const transformed = transformSelectedPlatform(watchPlatform);
    setSelectedPlatform(transformed);
  }, [watchPlatform]);

  useEffect(() => {
    if (
      (selectedPlatform && selectedPlatform.length > 0) ||
      selectedPlatform === null
    ) {
      dispatch(
        CampaignsSagaAction.getCampaigns({
          source: selectedPlatform?.toString(),
          start_date: startOfYear(new Date()),
          end_date: endOfYear(new Date()),
          skip: campaignsSkip,
          limit: 10,
          search: campaignsSearch,
        })
      );
    }
  }, [selectedPlatform,campaignsSkip, campaignsSearch]);

  useEffect(() => {
    setHasMore(campaignList.data.length < campaignList.total);
  }, [campaignList]);

  const onLoadMore = () => {
    if (hasMore) {
      dispatch(CampaignsReducerAction.setCampaignSkip(campaignsSkip + 10));
      dispatch(
        CampaignsSagaAction.getCampaigns({
          source: selectedPlatform?.toString(),
          start_date: format(startOfYear(new Date()), DATE_FORMAT),
          end_date: format(endOfYear(new Date()), DATE_FORMAT),
          skip: campaignsSkip + 10,
          limit: 10,
        })
      );
    }
  };

  const CustomTableRows = ({ row }: { row: any }) => {
    return (
      <TableCell className="flex-between-center w-full border rounded-xl p-5 border-primary-blue-100">
        <Box className="flex items-center gap-2.5">
          <Checkbox
            checked={selectedCampaigns.some(
              (selected: CampaignIdsType) =>
                selected.campaign_name === row.campaign_name
            )}
            onChange={() => handleCheckboxChange(row)} // TODO: change to id
          />
          <Box className="flex-column md:flex-row md:items-center md:gap-2.5">
            <Typography>{row.campaign_name}</Typography>
          </Box>
        </Box>
      </TableCell>
    );
  };

  return (
    <Box>
      <Box className="mb-4">
        <CustomTextField
          label=""
          name="search"
          icon={<GridSearchIcon />}
          placeholder="Search"
          type={RendererType.search}
          control={step2Form.control}
          onChange={() => {
            const value = (step2Form.getValues("search") || "") as string;
            debouncedSearch(value);
          }}
        />
      </Box>
      <Box>
        {isCampaignsLoading && campaignList?.data?.length === 0 ? (
          <Box className="flex flex-col justify-center items-center min-h-[200px]">
            <CircularProgress size={24} />
            Loading campaigns...
          </Box>
        ) : (
          <>
            {!isCampaignsLoading && campaignList?.data?.length === 0 ? (
              <Box className="flex flex-col justify-center items-center min-h-[200px]">
                <Typography>No campaigns found!</Typography>
              </Box>
            ) : (
              <>
                <Box className="flex items-center gap-2 ml-4">
                  <Checkbox
                    checked={
                      selectedCampaigns.length === campaignList.data.length
                    }
                    onChange={() => {
                      if (
                        selectedCampaigns.length === campaignList.data.length
                      ) {
                        setValue("campaignIds", []);
                      } else {
                        setValue("campaignIds", campaignList?.data);
                      }
                    }}
                  />
                  <Box className="flex-column md:flex-row md:items-center md:gap-1">
                    <Typography>Select All</Typography>
                  </Box>
                </Box>
                <VirtuosoList
                  data={campaignList?.data}
                  dataLength={campaignList?.data?.length}
                  isLoading={isCampaignsLoading}
                  onLoadMore={onLoadMore}
                  totalData={campaignList?.total}
                  CustomTableRows={CustomTableRows}
                  tableCellCount={1}
                  height={"400px"}
                  CustomTableRowsCustomClassName="flex mb-3"
                />
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ReportsStepTwo;
