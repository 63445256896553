import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./slice";
import { StoreInterface } from "../storeType";

// SAGA FLOW EXAMPLE FOR FUTURE API INTEGRATION

const selectDomain = (state: StoreInterface) =>
  state.manageDataSourceState || initialState;

export const isLoadingSelector = createSelector(
  selectDomain,
  (state) => state.isLoading
);
export const dataSourceSelector = createSelector(
  selectDomain,
  (state) => state.dataSource
);
