import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { ArrowLeftIcon } from "../../Icons";
import ReportHeader from "../../common/ReportHeader";
import SavePlatformCard from "./SavePlatformCard";
import SelectedGoogleCampaignCard from "./SelectedGoogleCampaignCard";
import SelectedMetricsCard from "./SelectedMetricsCard";
import SelectedDateRangeCard from "./SelectedDateRangeCard";
import { SaveAndGenerateSectionType } from "../../../types/reportTypes";

const SaveAndGenerateSection = ({
  handleNextSection,
  handlePreviousSection,
  reportStepperForm,
  setStepper,
}: SaveAndGenerateSectionType) => {
  const [generate, setGenerate] = useState("Save & Generate");
  return (
    <Box className="flex justify-center">
      <Box className="lg:w-[80%] p-10 flex-column bg-white gap-10 outline-none rounded-xl">
        {/* Header */}
        <ReportHeader
          title=" Preview of your selected data to generate Report"
          subtitle="Lorem ipsum dolor sit amet consectetur. Sapien quis laoreet tempor
              dolor massa dignissim in tellus tellus."
        />

        <Box className="flex-column  py-5 gap-[30px]">
          <SavePlatformCard
            reportStepperForm={reportStepperForm}
            setStepper={setStepper}
            handlePreviousSection={handlePreviousSection}
          />
          <SelectedGoogleCampaignCard
            reportStepperForm={reportStepperForm}
            setStepper={setStepper}
            handlePreviousSection={handlePreviousSection}
          />
          <SelectedMetricsCard
            reportStepperForm={reportStepperForm}
            setStepper={setStepper}
            handlePreviousSection={handlePreviousSection}
          />
          <SelectedDateRangeCard
            reportStepperForm={reportStepperForm}
            setStepper={setStepper}
            handlePreviousSection={handlePreviousSection}
          />
        </Box>
        {/* Footer */}
        <Box className="flex-column md:flex-row justify-end gap-[15px] p-7 rounded-[20px] bg-primary-blue-50">
          <Button
            className="!px-5 !py-2.5 !rounded-xl !border !border-primary-blue-950 !text-primary-blue-950 !capitalize gap-[5px]"
            variant="outlined"
            onClick={() => {
              handlePreviousSection();
              setStepper(3);
            }}
          >
            {ArrowLeftIcon}
            <Typography className="!text-sm !font-medium">Back</Typography>
          </Button>

          <Box
            onClick={() => {
              setGenerate("Generate");
              if (generate === "Generate") handleNextSection();
            }}
            className="cursor-pointer rounded-xl p-[10px] px-4 !bg-primary-blue-950 flex-center"
          >
            <Typography className="!text-sm !font-medium text-white">
              {generate}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SaveAndGenerateSection;
