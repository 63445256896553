import {
  FacebookIcon,
  GoogleIcon,
  InstagramIcon,
  TikTokIcon,
} from "../components/Icons";
import { SelectOptionType } from "../types/forms";
import routeConstants from "./routeConstants";

export const DATE_FORMAT = "yyyy-MM-dd";
export const DATE_PICKER_FORMAT = "dd/MM/yyyy";

export enum CampaignsFilterEnum {
  dataSource = "dataSource",
  startDate = "startDate",
  endDate = "endDate",
}

export const dateFilterOptions: SelectOptionType[] = [
  {
    label: "Last 30 days",
    value: "Last 30 days",
  },
  {
    label: "Last 15 days",
    value: "Last 15 days",
  },
  {
    label: "Last 7 days",
    value: "Last 7 days",
  },
];

export const sortByFilterOptions: SelectOptionType[] = [
  {
    label: "Sort By : Assending by Date",
    value: "asc",
  },
  {
    label: "Sort By : Descending by Date",
    value: "desc",
  },
];

export const statusFilterOptions: SelectOptionType[] = [
  {
    label: "New",
    value: "New",
  },
  {
    label: "Contact attempted",
    value: "Contact attempted",
  },
  {
    label: "Disqualified",
    value: "Disqualified",
  },
  {
    label: "Not interested",
    value: "Not interested",
  },
  {
    label: "Pending",
    value: "Pending",
  },
  {
    label: "Scheduled",
    value: "Scheduled",
  },
  {
    label: "Screened",
    value: "Screened",
  },
  {
    label: "Screen failed",
    value: "Screen failed",
  },
  {
    label: "Consented",
    value: "Consented",
  },
  {
    label: "Withdrawn",
    value: "Withdrawn",
  },
  {
    label: "Randomized",
    value: "Randomized",
  },
];

export const creativeTypeFilterOptions: SelectOptionType[] = [
  {
    label: "Video",
    value: "Video",
  },
  {
    label: "Image",
    value: "Image",
  },
];

export const conditionFilterOptions: SelectOptionType[] = [
  {
    label: "Enable",
    value: "Enable",
  },
  {
    label: "Disable",
    value: "Disable",
  },
];

export const platformFilterOptions: SelectOptionType[] = [
  {
    label: "Google",
    value: "Google",
  },
  {
    label: "Facebook",
    value: "Facebook",
  },
];

export type AccordianPlatformType =
  | "google"
  | "facebook"
  | "instagram"
  | "tikTok";

export const adCopiesAccordian = {
  google: GoogleIcon(),
  facebook: FacebookIcon(),
  instagram: InstagramIcon(),
  tikTok: TikTokIcon(),
};

// constants/breadcrumbMap.ts
export const breadcrumbMap: Record<
  string,
  {
    parent: string;
    parentPath?: string;
    child?: string;
    dynamicChild?: boolean;
    subChild?: boolean;
  }
> = {
  [routeConstants.dashboard]: { parent: "Dashboard" },
  [routeConstants.myProfile]: { parent: "My Profile" },
  [routeConstants.campaigns]: { parent: "Campaigns" },
  [routeConstants.manageDataSource]: { parent: "Manage Data Source" },
  [routeConstants.reports]: { parent: "Reports" },
  [routeConstants.generatedReport]: {
    parent: "Reports",
    child: "Generated Report",
  },
  [routeConstants.generateReports]: {
    parent: "Reports",
    child: "Generate Report",
  },
  [routeConstants.conversions]: {
    parent: "Conversions",
    parentPath: routeConstants.conversions,
  },
  [routeConstants.conversionsAddForm]: {
    parent: "Conversions",
    parentPath: routeConstants.conversions,
    child: "Add Form",
  },
  [routeConstants.conversionsUpdateForm]: {
    parent: "Conversions",
    parentPath: routeConstants.conversions,
    child: "Edit Form",
  },
  [routeConstants.conversionAccountDetails]: {
    parent: "Conversions",
    parentPath: routeConstants.conversions,
    dynamicChild: true,
  },
  [routeConstants.conversionDetails]: {
    parent: "Conversions",
    parentPath: routeConstants.conversions,
    child: "Details",
    dynamicChild: true,
    subChild: true,
  },
  [routeConstants.adCopies]: { parent: "Ad Copies" },
  [routeConstants.learnAboutTrialPartners]: {
    parent: "Learn about Trial Partners",
  },
  [routeConstants.sendFeedback]: { parent: "Send Feedback" },
  [routeConstants.aboutUs]: { parent: "About Us" },
  [routeConstants.privacyPolicy]: { parent: "Privacy Policy" },
};
