import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  ArrowRightIcon,
  CalenderTimeIcon,
  CustomBlueDeleteIcon,
  EditIcon,
  PlusIconWhite,
} from "../components/Icons";
import { useNavigate } from "react-router-dom";
import routeConstants from "../utils/routeConstants";
import BasicSelect from "../components/formControls/BasicSelect";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  conversionsFilterSelector,
  conversionsSelector,
  conversionsSkipSelector,
  isConversionsLoadingSelector,
} from "../store/conversions/selector";
import { ConversionsReducerAction } from "../store/conversions/slice";
import { ConversionsSagaAction } from "../store/conversions/saga";
import { differenceInDays, format, subDays } from "date-fns";
import { ConversionItemType } from "../store/conversions/types";
import { Search } from "@mui/icons-material";
import DeleteModal from "../components/common/DeleteModal";

const dateFilterOptions = [
  { label: "Last 30 days", value: "30" },
  { label: "Last 15 days", value: "15" },
  { label: "Last 7 days", value: "7" },
];

const Conversions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const conversions = useSelector(conversionsSelector);
  const isLoading = useSelector(isConversionsLoadingSelector);
  const filter = useSelector(conversionsFilterSelector);
  const skip = useSelector(conversionsSkipSelector);
  const filterDays = differenceInDays(new Date(), filter.startDate).toString();
  const observer = useRef<IntersectionObserver | null>(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<string>("");
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  useEffect(() => {
    // Clear existing data before fetching new data
    dispatch(ConversionsReducerAction.setConversionInitial({}));

    fetchConversions(0);
  }, [filter]);

  const fetchConversions = (newSkip: number) => {
    dispatch(
      ConversionsSagaAction.getConversions({
        start_date: format(filter.startDate, "yyyy-MM-dd"),
        end_date: format(filter.endDate, "yyyy-MM-dd"),
        skip: newSkip,
        limit: 10,
      })
    );
  };

  const lastElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          conversions.data.length < conversions.total
        ) {
          dispatch(ConversionsReducerAction.setConversionskip(skip + 10));
          fetchConversions(skip + 10);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, conversions.data.length, conversions.total, skip]
  );

  const handleDeleteForm = async (id: string) => {
    setIsDeleteLoading(true);
    await new Promise<void>((resolve) => {
      dispatch(ConversionsSagaAction.deleteConversion({ id, resolve }));
    });
    setIsDeleteLoading(false);
    setIsOpenDeleteModal("");

    // After deletion, adjust skip value if necessary
    if (conversions.data.length - 1 < conversions.total) {
      dispatch(
        ConversionsReducerAction.setConversionskip(Math.max(skip - 10, 0))
      );
    }
  };

  const ConversionCard = ({
    id,
    title,
    link,
  }: {
    id: string;
    title: string;
    link: string;
  }) => {
    return (
      <Box className="flex w-full justify-center">
        <Box className="w-[90%] flex rounded-xl border py-3 px-4 border-primary-blue-100 justify-between items-center">
          <Box className="flex flex-col gap-[2px]">
            <Typography className="">{title}</Typography>
            <Typography className="!text-xs font-normal text-primary-blue-800 ">
              {link}
            </Typography>
          </Box>
          <Box className="flex justify-center items-center gap-2">
            <Box className="flex">
              <Typography
                onClick={() =>
                  navigate(`${routeConstants.conversionAccountDetails}/${id}`)
                }
                className="!text-sm font-normal text-primary-blue-800 underline !cursor-pointer"
              >
                See Details
              </Typography>
              {ArrowRightIcon}
            </Box>
            <Box className="flex gap-1">
              <Button
                className="!min-w-0 !p-1 !text-base !text-primary-blue-950 !border !border-primary-blue-400 !rounded-[10px] !bg-primary-blue-100"
                onClick={() => {
                  navigate(`${routeConstants.conversionsUpdateForm}/${id}`);
                }}
              >
                <Box>{EditIcon}</Box>
              </Button>
              <Button
                className="!min-w-0 !p-1 !text-base !text-primary-blue-950 !border !border-primary-blue-400 !rounded-[10px] !bg-primary-blue-100"
                onClick={() => setIsOpenDeleteModal(id)}
              >
                <Box>{CustomBlueDeleteIcon}</Box>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box className="rounded-[20px] p-[30px] bg-white flex-column gap-7">
      <Box className="w-full flex gap-3 justify-end">
        <BasicSelect
          icon={CalenderTimeIcon}
          options={dateFilterOptions}
          value={
            dateFilterOptions.find((option) => option.value === filterDays)
              ?.label || "Last 30 days"
          }
          onChange={(value: string) => {
            const days = parseInt(value, 10);
            dispatch(
              ConversionsReducerAction.setCampaignFilter({
                startDate: subDays(new Date(), days),
                endDate: new Date(),
              })
            );
          }}
        />
        <Button
          onClick={() => navigate(routeConstants.conversionsAddForm)}
          className="!gap-[5px] !cursor-pointer !rounded-lg !px-[20px] !py-[8px] !bg-primary-blue-950 flex-center !text-white"
        >
          {PlusIconWhite}
          <Typography className="!text-sm !font-normal !text-white  !normal-case">
            Add Form
          </Typography>
        </Button>
      </Box>
      <Box className="flex w-full justify-center flex-col gap-4">
        {isLoading && conversions.data.length === 0 ? (
          <Box className=" flex flex-col justify-center items-center gap-4 min-h-80">
            <CircularProgress size={34} />
            <Typography className="">Loading Conversions...</Typography>
          </Box>
        ) : conversions.data.length ? (
          conversions.data.map((data: ConversionItemType, index: number) => (
            <Box
              ref={
                index === conversions.data.length - 1 ? lastElementRef : null
              }
              key={data.id}
              className="flex w-full justify-center"
            >
              <ConversionCard
                key={index}
                id={data.id}
                title={data.name}
                link={data.form_url}
              />
            </Box>
          ))
        ) : (
          <Box className="flex flex-col justify-center items-center min-h-80">
            <Box className="text-primary-blue-200">
              <Search fontSize="large" />
            </Box>
            <Typography variant="h6" className="mt-2 text-primary-blue-200">
              No conversions available
            </Typography>
          </Box>
        )}
      </Box>
      {isLoading && conversions.data.length ? (
        <Box className=" flex justify-center items-center">
          <CircularProgress size={34} />
        </Box>
      ) : null}
      <DeleteModal
        open={!!isOpenDeleteModal}
        isLoading={isDeleteLoading}
        onClose={() => setIsOpenDeleteModal("")}
        onConfirm={() => handleDeleteForm(isOpenDeleteModal)}
      />
    </Box>
  );
};

export default Conversions;
