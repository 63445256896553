import React from "react";
import { Button, CircularProgress } from "@mui/material";

interface CustomButtonProps {
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  type = "button",
  disabled = false,
  loading = false,
  onClick,
  children,
  className = "",
}) => {
  return (
    <Button
      type={type}
      disabled={disabled || loading}
      onClick={!loading ? onClick : undefined}
      className={`w-min !px-5 !py-2 !capitalize !text-white !rounded-lg ${
        disabled || loading ? "!bg-primary-blue-400" : "!bg-primary-blue-950"
      } ${className}`}
      startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
    >
      {children}
    </Button>
  );
};

export default CustomButton;