import { User } from "../types/profileTypes";

export const getCurrentUser = (): User | null => {
  try {
    const storedUser = localStorage.getItem("currentUser");
    if (!storedUser) return null; // Return null if no user is stored

    const parsedUser: User = JSON.parse(storedUser);

    // Ensure parsed object has required properties
    if (!parsedUser.email) {
      console.warn("Invalid user data in localStorage");
      return null;
    }

    return parsedUser;
  } catch (error) {
    console.error("Error parsing user data from localStorage:", error);
    return null;
  }
};

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const formatName = (name: string) => {
  return name
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};
