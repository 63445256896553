export interface ManageDataSource {
  isLoading: { key: string; value: boolean };
  dataSource: DataSource;
}

export interface DataSource {
  [key: string]: boolean;
}

export enum DataSourceEnum {
  Facebook = "facebook",
  Google = "google",
  Tiktok = "tiktok",
}
