import { Box } from "@mui/material";
import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import CustomTextField from "../components/formControls/CustomTextField";
import CustomTextArea from "../components/formControls/CustomTextArea";
import { RendererType } from "../types/enums/enums";
import { FeedBackFormType } from "../types/forms";
import { FeedbackService } from "../services/FeedbackService";
import { AxiosResponse } from "axios";
import CustomButton from "../components/common/CustomButton";
import { toast } from "react-toastify";

const SendFeedback = () => {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FeedBackFormType>();

  const onSubmit: SubmitHandler<FeedBackFormType> = async (formData) => {
    try {
      const { status }: AxiosResponse = await FeedbackService(formData);

      if (status === 200) {
        toast.success("Feedback submitted successfully!");
        reset(); // Reset form on success
      } else {
        toast.error("Failed to submit feedback. Please try again.");
      }
    } catch (error) {
      console.error("API Error:", error);
      toast.error("Something went wrong. Please try again later.");
    }
  };

  return (
    <Box className="rounded-[30px] p-[30px] bg-white flex-column gap-7">
      <form className="flex-column gap-7" onSubmit={handleSubmit(onSubmit)}>
        <Box className="gap-5 flex-column">
          <CustomTextField
            label="Title"
            name="title"
            required
            placeholder="Enter Title"
            type={RendererType.string}
            control={control}
            errors={errors.title?.message?.toString()}
            rules={{
              required: "Title is Required",
            }}
          />
          <CustomTextArea
            name="description"
            label="Description"
            rows={3}
            required
            placeholder="Enter Description"
            type={RendererType.textarea}
            control={control}
            errors={errors.description?.message?.toString()}
            rules={{
              required: "Description is Required",
            }}
          />
        </Box>
        <Box>
          <CustomButton
            type="submit"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Submit
          </CustomButton>
        </Box>
      </form>
    </Box>
  );
};

export default SendFeedback;
