// Enumeration of renderer types for different form fields.
export enum RendererType {
  string = "string",
  boolean = "boolean",
  date = "date",
  number = "number",
  enum = "enum",
  optionalSelect = "optionalSelect",
  integer = "integer",
  password = "password",
  checkbox = "checkbox",
  divider = "divider",
  textarea = "textarea",
  select = "select",
  radio = "radio",
  search = "search"
}
