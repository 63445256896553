import { Avatar, AvatarGroup, Box } from "@mui/material";
import { FacebookIcon, GoogleIcon, InstagramIcon, TikTokIcon } from "../Icons";

const SocialsHeader = () => {
  return (
    <Box className="flex justify-start items-center gap-5 p-5">
      <a href="/">
        <GoogleIcon />
      </a>
      <a href="/">
        <FacebookIcon />
      </a>
      <a href="/">
        <InstagramIcon />
      </a>
      <a href="/">
        <TikTokIcon />
      </a>
    </Box>
  );
};

export const SocialIconGroup = ({ icons = ['facebook','google','tiktok','instagram'] }) => {
  const iconComponents: any = {
    google: <GoogleIcon />,
    facebook: <FacebookIcon />,
    instagram: <InstagramIcon />,
    tiktok: <TikTokIcon />,
  };

  return (
    <AvatarGroup>
      {icons.map((icon, index) => (
        <Avatar key={index} className="!bg-primary-blue-50">
          {iconComponents[icon.toLowerCase()]}
        </Avatar>
      ))}
    </AvatarGroup>
  );
};

export default SocialsHeader;
