import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getConversationDetailService } from "../../services/ConversionsService";
import { Search } from "@mui/icons-material";
import { BreadcrumbSagaAction } from "../../store/breadcrumb/saga";
import routeConstants from "../../utils/routeConstants";
import { useDispatch } from "react-redux";

// Define TypeScript type for conversationDetailsData item
interface ConversationDetail {
  key: string;
  header: string;
  value: string | number | null;
  chips?: string[];
}

const ConversionDetails: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [conversationDetailsData, setConversationDetailsData] = useState<
    ConversationDetail[]
  >([]);
  const [formHeaderData, setFormHeaderData] = useState<{
    name: string;
    url: string;
  }>({
    name: "",
    url: "",
  });

  useEffect(() => {
    if (formHeaderData.name) {
      dispatch(
        BreadcrumbSagaAction.updateBreadcrumbs({
          path: routeConstants.conversionDetails,
          dynamicName: formHeaderData.name,
        })
      );
    }
  }, [formHeaderData]);

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const fetchData = async () => {
    if (!id) return;
    setLoading(true);
    try {
      const response = await getConversationDetailService({
        conversion_id: id,
      });
      setFormHeaderData(response.data?.["form-data"]);
      setConversationDetailsData(response.data?.data || []);
    } catch (error) {
      console.error("Failed to fetch conversation details", error);
      setConversationDetailsData([]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className="rounded-[20px] bg-white flex-column gap-7">
      {loading ? (
        <Box className="flex justify-center items-center min-h-[50vh]">
          <CircularProgress size={42} />
        </Box>
      ) : formHeaderData.name &&
        formHeaderData.url &&
        conversationDetailsData.length ? (
        <React.Fragment>
          <Box className="flex flex-col gap-[2px] px-[20px] py-[20px] border-b border-b-primary-blue-100">
            <Typography className="!text-xl font-semibold">
              {formHeaderData.name}
            </Typography>
            <Typography
              className="!text-l font-normal text-primary-black-600 cursor-pointer hover:underline"
              onClick={() => window.open(formHeaderData.url, "_blank")}
            >
              {formHeaderData.url}
            </Typography>
          </Box>

          {/* Two-column grid */}
          <Box className="flex-column gap-[15px] px-20 py-5">
            <Box className="grid grid-cols-2 p-5 gap-y-8 gap-x-28 rounded-xl border border-primary-blue-100">
              {conversationDetailsData.map((item, index) => (
                <Box
                  key={item.key}
                  className={`flex flex-col gap-2 pb-4 border-b border-b-primary-black-200 ${
                    index % 2 === 0 ? "col-start-1" : "col-start-2"
                  }`}
                >
                  <Typography className="!text-xs text-primary-blue-800">
                    {item.header}
                  </Typography>
                  {item.chips ? (
                    <Box className="flex gap-3">
                      {item.chips.map((value) => (
                        <Box
                          key={value}
                          className="flex px-4 py-1.5 items-center gap-1.5 rounded-[10px] border-2 !bg-primary-blue-100 !text-primary-blue-950 !border-primary-blue-400"
                        >
                          <Typography className="font-normal">
                            {value}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    <Typography className="font-normal">
                      {item.value ?? "-"}
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        </React.Fragment>
      ) : (
        <Box className="flex flex-col justify-center items-center min-h-[50vh]">
          <Box className="text-primary-blue-400">
            <Search fontSize="large" />
          </Box>
          <Typography variant="h6" className="mt-2 text-primary-blue-400">
            No information available for this conversation.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ConversionDetails;
