import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./slice";

// SAGA FLOW EXAMPLE FOR FUTURE API INTEGRATION

const selectDomain = (state: any) => state.adCopiesState || initialState;

// Step-4: Making the state available for the files to be called through
export const adCopiesSelector = createSelector(
  selectDomain,
  (state) => state.adCopies
);
export const isAdCopiesLoadingSelector = createSelector(
  selectDomain,
  (state) => state.isLoading
);
// export const campaignsFilterSelector = createSelector(
//   selectDomain,
//   (state) => state.filter
// );
export const adCopiesSkipSelector = createSelector(
  selectDomain,
  (state) => state.skip
);

export const dropdownListSkipSelector = createSelector(
  selectDomain,
  (state) => state.dropdownList
);

export const adCopiesDetailsSelector = createSelector(
  selectDomain,
  (state) => state.adCopiesDetails
);
