import { RendererType } from "../../../../types/enums/enums";
import { UseFormReturn } from "react-hook-form";
import { Box } from "@mui/material";
import CustomDatePicker from "../../../formControls/CustomDatePicker";
import { ReportType } from "../../../../types/reportTypes";
import { DATE_PICKER_FORMAT } from "../../../../utils/appConstants";

const ReportsSectionFour = ({
  reportStepperForm,
}: {
  reportStepperForm: UseFormReturn<ReportType>;
}) => {
  const {
    formState: { errors },
    control,
    watch,
    setValue,
  } = reportStepperForm;

  // Watch for current values
  const startDate = watch("start_date");
  const endDate = watch("end_date");

  return (
    <Box className="flex-column md:flex-row gap-[25px]">
      <Box className="w-full">
        <CustomDatePicker
          type={RendererType.date}
          label="Start Date"
          name="start_date"
          control={control}
          errors={errors.start_date?.message?.toString()}
          rules={{
            required: "Start Date is Required",
          }}
          onChange={() => {
            setValue("start_date", watch("start_date"));
          }}
          value={startDate}
          placeholder={"DD/MM/YYYY"}
          dateFormat={DATE_PICKER_FORMAT}
          maxDate={new Date()}
        />
      </Box>
      <Box className="w-full">
        <CustomDatePicker
          type={RendererType.date}
          label="End Date"
          name="end_date"
          control={control}
          errors={errors.end_date?.message?.toString()}
          rules={{
            required: "End Date is Required",
          }}
          onChange={() => {
            setValue("end_date", watch("end_date"));
          }}
          value={endDate}
          placeholder={"DD/MM/YYYY"}
          dateFormat={DATE_PICKER_FORMAT}
          maxDate={new Date()}
        />
      </Box>
    </Box>
  );
};

export default ReportsSectionFour;
