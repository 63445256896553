import { Box } from "@mui/material";
import React from "react";
import { Virtuoso, VirtuosoGrid } from "react-virtuoso";
import VirtuosoTableComponents from "../table/AllTableComponents";
import { gridComponents } from "../table/GridComponents";
const TableComponents = VirtuosoTableComponents({});

const VirtuosoList = ({
  data,
  isLoading,
  dataLength,
  totalData,
  onLoadMore,
  CustomTableRows,
  tableCellCount,
  height,
  CustomTableRowsCustomClassName,
  useGridLayout,
}: {
  data: any[];
  isLoading: boolean;
  dataLength: number;
  totalData: number;
  onLoadMore: () => void;
  CustomTableRows?: any;
  tableCellCount?: any;
  height: string;
  CustomTableRowsCustomClassName?: string;
  useGridLayout?: boolean;
}) => {
  const tblContext = {
    isFetching: isLoading,
    isUninitialized: false,
    currentRowCount: dataLength,
    totalRecords: totalData ?? 0,
    tableCellCount: tableCellCount,
  };

  const { Footer } = TableComponents;

  if (useGridLayout) {
    return (
      <VirtuosoGrid
        style={{ height: 900 }}
        context={tblContext}
        data={data}
        totalCount={dataLength}
        components={{
          List: gridComponents.List,
          Item: gridComponents.Item,
          Footer: Footer,
        }}
        itemContent={(index) => <CustomTableRows row={data[index]} />}
        endReached={onLoadMore}
      />
    );
  }

  return (
    <div>
      <Virtuoso
        style={{ height: height }}
        context={tblContext}
        data={data}
        totalCount={dataLength}
        components={TableComponents}
        itemContent={(index) => {
          return (
            <Box className={CustomTableRowsCustomClassName}>
              <CustomTableRows row={data[index]} />
            </Box>
          );
        }}
        endReached={onLoadMore}
      />
    </div>
  );
};

export default VirtuosoList;
