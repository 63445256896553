import { format } from "date-fns";
import axiosClient from "../apiClient";
import { DATE_FORMAT } from "../utils/appConstants";

export const AdCopiesService = async (params: Record<string, any>) => {
  try {
    if (params.start_date && params.end_date) {
      params.start_date = format(params.start_date, DATE_FORMAT);
      params.end_date = format(params.end_date, DATE_FORMAT);
    }

    const cleanedParams: Record<string, any> = {};
    Object.keys(params).forEach((key) => {
      const value = params[key];
      if (
        value !== undefined &&
        value !== null &&
        value !== "" &&
        !(Array.isArray(value) && value.length === 0)
      ) {
        cleanedParams[key] = value;
      }
    });

    const queryString = Object.keys(cleanedParams)
      .map((key) => {
        const value = cleanedParams[key];
        if (Array.isArray(value)) {
          return value
            .map(
              (item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`
            )
            .join("&");
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      })
      .join("&");

    const response = await axiosClient.get(`ad-copies?${queryString}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const DropdownListService = async () => {
  try {
    const response = await axiosClient.get(`dropdown/list`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const AdCopiesDetailsService = async (params: {
  ad_copy_id: number;
}) => {
  try {
    const response = await axiosClient.get(
      `ad-copies/show?ad_copy_id=${params.ad_copy_id}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const PostAdCopiesService = async (formData: FormData) => {
  try {
    const response = await axiosClient.post(`ad-copies/save`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};
