import SectionHeader from "../SectionHeader";
import { Box } from "@mui/material";
import AdCopiesCard from "../../common/AdCopiesCard";
import { dashboardSelector } from "../../../store/dashboard/selector";
import { useSelector } from "react-redux";
import { AdCopy, DashboardState } from "../../../store/dashboard/types";

const AdCopiesSection = () => {
  const { ad_copies }: DashboardState = useSelector(dashboardSelector);
  const addCopiesData: AdCopy[] = ad_copies["ad-copies"];

  return (
    <Box className="bg-white rounded-xl border border-primary-blue-100">
      <SectionHeader title="Ad Copies - Top Creative" withoutInfo />
      <Box className="p-5 border-t border-t-primary-blue-100 flex-column gap-5 lg:grid-cols-4 md:grid md:grid-cols-2">
        {addCopiesData.map((copyData, index) => (
          <AdCopiesCard key={index} adCopy={copyData} />
        ))}
      </Box>
    </Box>
  );
};

export default AdCopiesSection;
