import { Box, Typography } from "@mui/material";
import React from "react";
import SaveAndGenerateCardHeader from "../../common/SaveAndGenerateCardHeader";
import { CancelIcon } from "../../Icons";
import { UseFormReturn } from "react-hook-form";
import { ReportType } from "../../../types/reportTypes";

const SelectedGoogleCampaignCard = ({
  reportStepperForm,
  setStepper,
  handlePreviousSection,
}: {
  reportStepperForm: UseFormReturn<ReportType>;
  setStepper: React.Dispatch<React.SetStateAction<number>>;
  handlePreviousSection: () => void;
}) => {
  const campaignList = reportStepperForm.watch("campaignIds") ?? [];

  const handleItemCancel = (selectedCampaign: {
    campaign_name: string;
    id: string;
  }) => {
    reportStepperForm.setValue(
      "campaignIds",
      campaignList.filter(
        (campaign: { campaign_name: string; id: string }) =>
          campaign.campaign_name !== selectedCampaign.campaign_name
      )
    );
  };

  const containerHeight = campaignList.length * 60;
  const maxContainerHeight = 450;

  return (
    <Box className="border p-[30px] border-primary-blue-100 rounded-[20px] flex-column gap-[30px]">
      {/* Header  */}
      <SaveAndGenerateCardHeader
        title="Selected Campaign"
        setStepper={setStepper}
        step={1}
        handlePreviousSection={handlePreviousSection}
      />

      <Box
        className="flex-column gap-[15px] overflow-auto"
        style={{
          height:
            campaignList.length > 0
              ? `${Math.min(containerHeight, maxContainerHeight)}px`
              : "auto",
        }}
      >
        {campaignList.length > 0 ? (
          campaignList.map(
            (
              campaign: {
                campaign_name: string;
                id: string;
              },
              index: number
            ) => (
              <Box
                key={index}
                className="flex-between-center w-full border rounded-xl p-5 border-primary-blue-100"
              >
                <Box className="flex items-center gap-5">
                  <Box
                    onClick={() => handleItemCancel(campaign)}
                    className="flex-center cursor-pointer !w-6 !h-6 !rounded-full !bg-primary-black-50"
                  >
                    {CancelIcon}
                  </Box>
                  <Box className="flex-column md:flex-row md:items-center md:gap-2.5">
                    <Typography>{campaign.campaign_name}</Typography>
                  </Box>
                </Box>
              </Box>
            )
          )
        ) : (
          <p className="text-center text-sm">No data found!</p>
        )}
      </Box>
    </Box>
  );
};

export default SelectedGoogleCampaignCard;
