import { Box, styled, Typography } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import SectionHeader from "../SectionHeader";
import { dashboardSelector } from "../../../store/dashboard/selector";
import { useSelector } from "react-redux";
import { DashboardState } from "../../../store/dashboard/types";

export const StyledLineChart = styled(LineChart)(() => ({
  "& svg": {
    overflow: "visible",
  },
}));

const formatDate = (dateStr: string) => {
  const date = new Date(dateStr);
  const day = date.getDate();
  const suffix = ["th", "st", "nd", "rd"][
    day % 10 > 3 || Math.floor((day % 100) / 10) === 1 ? 0 : day % 10
  ];
  return `${day}${suffix}`;
};

const ConversionsByDaySection = () => {
  const { conversions_by_day }: DashboardState = useSelector(dashboardSelector);

  const transformedData = Object.keys(
    conversions_by_day?.conversions_by_day_graph?.Google || {}
  ).map((date) => ({
    date: formatDate(date),
    Google: conversions_by_day.conversions_by_day_graph.Google[date] || 0,
    Facebook: conversions_by_day.conversions_by_day_graph.Facebook[date] || 0,
    Instagram: conversions_by_day.conversions_by_day_graph.Instagram[date] || 0,
    TikTok: conversions_by_day.conversions_by_day_graph.Tiktok[date] || 0,
  }));

  return (
    <Box className="bg-white rounded-xl border border-primary-blue-100">
      <SectionHeader title="Conversions by Day" />
      <Box className="flex-column items-center bg-white p-6 rounded-lg shadow-md border-t border-t-primary-blue-100">
        <ResponsiveContainer minWidth={200} height={400}>
          <StyledLineChart data={transformedData}>
            <CartesianGrid
              stroke="#E9ECF2"
              strokeDasharray="0"
              vertical={false}
            />
            <XAxis
              dataKey="date"
              className="text-xs text-primary-black-600"
              axisLine={{ stroke: "#E9ECF2" }}
              tick={{ fill: "#666" }}
            />
            <YAxis
              tickLine={true}
              stroke="#E9ECF2"
              tick={{ fill: "#666" }}
              axisLine={{ stroke: "#E9ECF2" }}
              className="text-xs text-primary-black-600"
              label={{
                value: "Conversions",
                angle: -90,
                position: "insideLeft",
                dy: 55,
              }}
            />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="Google"
              stroke="#F4B400"
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="Facebook"
              stroke="#4B73B8"
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="Instagram"
              stroke="#BA3478"
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="TikTok"
              stroke="#60C0C8"
              strokeWidth={2}
            />
          </StyledLineChart>
        </ResponsiveContainer>
        <Box className="flex-column md:flex-row gap-4 md:gap-0 justify-center md:space-x-8 mt-10">
          {Object.entries(
            conversions_by_day?.conversions_by_day_percentage || {}
          ).map(([platform, percent]) => (
            <Box
              key={platform}
              className="md:w-[157px] lg:w-[177px] flex gap-5 px-5 py-2.5 rounded-[10px] border border-primary-blue-100"
            >
              <Box
                className="w-3 h-full rounded-full mr-2"
                style={{
                  background:
                    platform === "Google"
                      ? "#F4B400"
                      : platform === "Facebook"
                      ? "#4B73B8"
                      : platform === "Instagram"
                      ? "#BA3478"
                      : "#60C0C8",
                }}
              />
              <Box className="flex-column gap-3">
                <Typography className="!text-xl font-bold">
                  {percent}
                </Typography>
                <Typography className="!text-sm !text-primary-black-600">
                  {platform}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ConversionsByDaySection;
