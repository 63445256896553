import { format } from "date-fns";
import axiosClient from "../apiClient";
import { DATE_FORMAT } from "../utils/appConstants";

export const CampaignsService = async (params: {
  source?: string[];
  skip: number;
  limit: number;
  start_date: string;
  end_date: string;
}) => {
  try {
    const startDateString = format(params.start_date, DATE_FORMAT);
    const endDateString = format(params.end_date, DATE_FORMAT);

    let queryString = `?start_date=${startDateString}&end_date=${endDateString}&skip=${params.skip}&limit=${params.limit}`;
    if (params.source && params.source.length !== 0)
      queryString = `${queryString}&source=${params.source}`;

    const response = await axiosClient.get(`/campaigns${queryString}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const ReportService = async (formData: FormData) => {
  try {
    const response = await axiosClient.post(`reports/create`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const GetReportsListService = async (params: {
  skip: number;
  limit: number;
}) => {
  try {
    const response = await axiosClient.get(
      `reports/list?skip=${params.skip}&limit=${params.limit}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const GetRecommendedReportsListService = async (params: {
  skip: number;
  limit: number;
}) => {
  try {
    const response = await axiosClient.get(
      `reports/top-recommended?skip=${params.skip}&limit=${params.limit}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const ViewReportService = async (params: {
  skip: number;
  limit: number;
  report_id: number;
}) => {
  try {
    const response = await axiosClient.get(
      `reports/list-details?id=${params.report_id}&skip=${params.skip}&limit=${params.limit}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const ExportReportService = async (params: {
  skip: number;
  limit: number;
  report_id: number;
}) => {
  try {
    const response = await axiosClient.get(
      `reports/export?report_id=${params.report_id}&skip=${params.skip}&limit=${params.limit}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const MetricsService = async () => {
  try {
    const response = await axiosClient.get("reports/metric-list");
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteReportService = async (id: string) => {
  try {
    let body = {
      report_id: id,
    };
    const response = await axiosClient.post(`reports/delete`, body);
    return response;
  } catch (error) {
    console.error("Error fetching patient list:", error);
    throw error;
  }
};

export const GetReportDetailsService = async (params: { id: string }) => {
  try {
    const response = await axiosClient.get(`reports/details?id=${params.id}`);
    return response;
  } catch (error) {
    return error;
  }
};
