import { Box, TableCell, TableRow } from "@mui/material";
import CampaignHeader from "../components/campaign/CampaignHeader";
import { columns } from "../components/campaign/CampaignTableColumnHeaders";
import { CampaignsSagaAction } from "../store/campaigns/sagas";
import { CampaignsReducerAction } from "../store/campaigns/slice";
import { useSelector } from "react-redux";
import {
  campaignsFilterSelector,
  campaignsSelector,
  campaignsSkipSelector,
  isCampaignsLoadingSelector,
} from "../store/campaigns/selector";
import { useState, useEffect, useCallback } from "react";
import { TableVirtuoso } from "react-virtuoso";
import { useDispatch } from "react-redux";
import VirtuosoTableComponents from "../components/table/AllTableComponents";
import CustomChip from "../components/common/CustomChip";
import { colorType } from "../types/common";

const TableComponents = VirtuosoTableComponents({});
export type CampaignsFilterType = {
  dataSource: string;
  dateFilter: string;
};
const Campaigns = () => {
  const dispatch = useDispatch();
  const campaignsFilter = useSelector(campaignsFilterSelector);
  const campaignsSkip = useSelector(campaignsSkipSelector);
  const [hasMore, setHasMore] = useState(true);

  const campaignList = useSelector(campaignsSelector);
  const isCampaignsLoading = useSelector(isCampaignsLoadingSelector);

  useEffect(() => {
    dispatch(CampaignsReducerAction.setCampaignSkip(0));
    dispatch(CampaignsReducerAction.setCampaigns({ data: [], total: 0 }));
    dispatch(
      CampaignsSagaAction.getCampaigns({
        source: campaignsFilter.dataSource,
        start_date: campaignsFilter.startDate,
        end_date: campaignsFilter.endDate,
        skip: campaignsSkip,
        limit: 10,
      })
    );
  }, []);

  useEffect(() => {
    setHasMore(campaignList.data.length < campaignList.total);
  }, [campaignList]);

  const onLoadMore = () => {
    if (hasMore) {
      dispatch(CampaignsReducerAction.setCampaignSkip(campaignsSkip + 10));
      dispatch(
        CampaignsSagaAction.getCampaigns({
          source: campaignsFilter.dataSource,
          start_date: campaignsFilter.startDate,
          end_date: campaignsFilter.endDate,
          skip: campaignsSkip + 10,
          limit: 10,
        })
      );
    }
  };

  const getCustomChipStatus = (status: any) => {
    if (status === "ACTIVE") return "success";
    else if (status === "PAUSED") return "info";
  };

  const CustomTableRows = ({ row }: { row: any }) => {
    return (
      <>
        {columns.map((column, columnIndex) => (
          <TableCell
            key={columnIndex}
            className="!border border-primary-black-50"
          >
            {row && (
              <TableCell className="!border-none !p-0" key={column.field}>
                {column?.field === "status" ? (
                  <CustomChip
                    color={getCustomChipStatus(row[column?.field]) as colorType}
                    label={row[column?.field]}
                  />
                ) : (
                  row[column?.field]
                )}
              </TableCell>
            )}
          </TableCell>
        ))}
      </>
    );
  };

  const tblContext = {
    isFetching: isCampaignsLoading,
    isUninitialized: false,
    currentRowCount: campaignList?.data?.length,
    totalRecords: campaignList?.total ?? 0,
    tableCellCount: columns.length,
  };

  // Fixed header content for the table
  const fixedHeaderContent = useCallback(
    () => (
      <TableRow className="bg-white">
        {columns.map((col) => (
          <TableCell
            key={col.field}
            className="!border !border-primary-black-50 !text-primary-black-600 !font-normal"
            style={{ width: col.width }}
          >
            {col.headerName}
          </TableCell>
        ))}
      </TableRow>
    ),
    []
  );

  return (
    <Box className="flex justify-center">
      <Box className="w-full flex-column bg-white  outline-none rounded-[20px]">
        <CampaignHeader />

        <Box className="rounded-xl pb-5">
          {!isCampaignsLoading && campaignList.data.length === 0 ? (
            <Box textAlign="center" py={2}>
              <span>No Records Found</span>
            </Box>
          ) : (
            <Box height={"70vh"} width="100%">
              <TableVirtuoso
                context={tblContext}
                data={campaignList.data}
                components={TableComponents}
                fixedHeaderContent={fixedHeaderContent}
                fixedFooterContent={() => <></>}
                itemContent={(row) => {
                  return <CustomTableRows row={campaignList.data[row]} />;
                }}
                endReached={onLoadMore}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Campaigns;
