import { useEffect, useState } from "react";
import ReportStepperWrapper from "../components/reports/reportStepper";
import ReportNameSection from "../components/reports/reportNameSection";
import SaveAndGenerateSection from "../components/reports/saveAndGenerateSection";
import { FormProvider, useForm } from "react-hook-form";
import { ReportDetailsType, ReportType } from "../types/reportTypes";
import routeConstants from "../utils/routeConstants";
import { useLocation, useNavigate } from "react-router-dom";
import { ReportsSagaAction } from "../store/report/sagas";
import {
  initialReportDetails,
  ReportReducerAction,
} from "../store/report/slice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { reportDetailsSelector } from "../store/report/selector";
import { capitalize } from "../utils/utils";

const Reports = () => {
  const dispatch = useDispatch();
  const reportDetails: ReportDetailsType = useSelector(reportDetailsSelector);
  const location = useLocation();
  const navigate = useNavigate();
  const reportId = location.state?.reportId || null;
  const [section, setSection] = useState<number>(0);
  const [stepper, setStepper] = useState<number>(0);
  const reportStepperForm = useForm<ReportType>();

  const { reset } = reportStepperForm;

  useEffect(() => {
    if (reportId) {
      dispatch(ReportsSagaAction.getReportDetails({ id: reportId }));
    }

    return () => {
      dispatch(ReportReducerAction.setReportDetails(initialReportDetails));
    };
  }, [reportId]);

  useEffect(() => {
    if (reportDetails.report_id) {
      const transformedData = transformResponseToStaticFormat(reportDetails);
      reset(transformedData as ReportType);
    }
  }, [reportDetails, reset]);

  const transformResponseToStaticFormat = (reportData: ReportDetailsType) => {
    return {
      report_id: reportData.report_id.toString(),
      platform: Array.from(new Set(reportData.platforms)).map((name) => ({
        name: capitalize(name),
      })),
      report_name: reportData.report_name,
      start_date: new Date(reportData.start_date) as any,
      end_date: new Date(reportData.end_date) as any,
      campaignIds: reportData.campaigns.map((campaign) => ({
        ...campaign,
        id: String(campaign.id),
      })),
      metrics: reportData.matrics || [],
    };
  };

  const handleNextSection = () => {
    setSection(section + 1);
  };

  const handlePreviousSection = () => {
    setSection(section - 1);
  };

  const handleSection = (section: number) => {
    switch (section) {
      case 0:
        return (
          <FormProvider {...reportStepperForm}>
            <ReportStepperWrapper
              stepper={stepper}
              setStepper={setStepper}
              reportStepperForm={reportStepperForm}
              handleNextSection={handleNextSection}
              handlePreviousSection={handlePreviousSection}
            />
          </FormProvider>
        );
      case 1:
        return (
          <SaveAndGenerateSection
            setStepper={setStepper}
            reportStepperForm={reportStepperForm}
            handleNextSection={handleNextSection}
            handlePreviousSection={handlePreviousSection}
          />
        );
      case 2:
        return (
          <ReportNameSection
            reportStepperForm={reportStepperForm}
            handleNextSection={handleNextSection}
            handlePreviousSection={handlePreviousSection}
          />
        );
      case 3:
        navigate(routeConstants.reports);
    }
  };
  return <>{handleSection(section)}</>;
};

export default Reports;
