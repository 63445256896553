import axiosClient from "../apiClient";

export const CMSPageService = async (id: number) => {
  try {
    const response = await axiosClient.get(`cms/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
