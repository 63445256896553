import { createSliceSaga } from "../saga-helper";
import { call, put } from "redux-saga/effects";
import { ClientReducerAction } from "./slice";
import {
  GetClientDropDownListService,
  SetSelectedClientService,
} from "../../services/ClientDropDown";
import { AxiosResponse } from "axios";

function* fetchClientsAndSetAuth(action: { payload: any }) {
  const { isLogin, resolve } = action.payload || {}; // Extract promise resolver
  try {
    yield put(ClientReducerAction.setIsLoading(true));

    // Fetch Clients
    const { data, status }: AxiosResponse = yield call(
      GetClientDropDownListService
    );

    if (status === 200) {
      const clientList = data?.accounts || [];
      yield put(ClientReducerAction.setClientList(clientList));

      if (clientList.length > 0) {
        let selectedClientId = localStorage.getItem("selectedClientId");

        if (!selectedClientId) {
          const lastActiveAccount = clientList.find(
            (client: any) => !!client?.active
          );
          selectedClientId = lastActiveAccount?.id || clientList[0]?.id;
          if (selectedClientId) {
            localStorage.setItem("selectedClientId", selectedClientId);
          }
        }

        if (selectedClientId) {
          const alreadySet = localStorage.getItem("selectedClientId");
          if (!alreadySet || isLogin) {
            yield call(SetSelectedClientService, selectedClientId);
          }
          yield put(ClientReducerAction.setClientSelected(selectedClientId));
        }
      }
    }
  } catch (error) {
    console.error("Error in fetchClientsAndSetAuth:", error);
  } finally {
    yield put(ClientReducerAction.setIsLoading(false));
    if (resolve) resolve();
  }
}

const saga = createSliceSaga({
  name: "client-saga-state",
  caseSagas: {
    *fetchClientsAndSetAuth(action: { payload: any }) {
      yield call(fetchClientsAndSetAuth, action);
    },
    *fetchClien3ts() {
      try {
        yield put(ClientReducerAction.setIsLoading(true));
        const { data, status }: AxiosResponse = yield call(
          GetClientDropDownListService
        );
        if (status === 200) {
          const clientList = data?.accounts || [];
          yield put(ClientReducerAction.setClientList(clientList));
          return clientList;
        }
      } catch (error) {
        console.error("Error fetching clients:", error);
      } finally {
        yield put(ClientReducerAction.setIsLoading(false));
      }
    },
    *setClient(action: { payload: { clientId: string; resolve: () => void } }) {
      try {
        yield put(ClientReducerAction.setIsLoading(true));

        const { status }: AxiosResponse = yield call(
          SetSelectedClientService,
          action.payload.clientId
        );

        if (status === 200) {
          yield put(
            ClientReducerAction.setClientSelected(action.payload.clientId)
          );
        } else {
          yield put(ClientReducerAction.setIsLoading(false));
        }
      } catch (error) {
        console.error("Error setting selected client:", error);
      } finally {
        yield put(ClientReducerAction.setIsLoading(false));

        // Call resolve() to notify handleChange that saga is done
        if (action.payload.resolve) {
          action.payload.resolve();
        }
      }
    },
  },
  sagaType: "takeEvery",
});

export const {
  actions: ClientSagaAction,
  saga: ClientSaga,
  name: ClientSagaName,
} = saga;
