import AddFormContainer from "../components/conversions/AddFormContainer";
import ConversionAccountDetails from "../components/conversions/ConversionAccountDetails";
import ConversionDetails from "../components/conversions/ConversionDetails";
import EditFormContainer from "../components/conversions/EditFormContainer";
import GeneratedReport from "../components/reports/generatedReportSection";
import ReportsDetailSection from "../components/reports/reportsDetailSection";
import MainLayout from "../layout";
import AboutUs from "../pages/AboutUs";
import AdCopies from "../pages/AdCopies";
import Campaigns from "../pages/Campaigns";
import ComingInFuture from "../pages/ComingInFuture";
import Conversions from "../pages/Conversions";
import Dashboard from "../pages/Dashboard";
// import Dashboard from "../pages/Dashboard";
import ManageDataSource from "../pages/ManageDataSource";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Profile from "../pages/Profile";
import Reports from "../pages/Reports";
import SendFeedback from "../pages/SendFeedback";
import routeConstants from "../utils/routeConstants";
import AuthGuard from "./AuthGaurds";

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      {/* use '/dashboard' to access login page */}
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: routeConstants.dashboard,
      element: <Dashboard />,
    },
    {
      path: routeConstants.myProfile,
      element: <Profile />,
    },
    {
      path: routeConstants.campaigns,
      element: <Campaigns />,
    },
    {
      path: routeConstants.manageDataSource,
      element: <ManageDataSource />,
    },
    {
      path: routeConstants.reports,
      element: <ReportsDetailSection />,
    },
    {
      path: routeConstants.generateReports,
      element: <Reports />,
    },
    {
      path: routeConstants.generatedReport + "/:id",
      element: <GeneratedReport />,
    },
    {
      path: routeConstants.privacyPolicy,
      element: <PrivacyPolicy />,
    },
    {
      path: routeConstants.aboutUs,
      element: <AboutUs />,
    },
    // {
    //   path: routeConstants.learnAboutTrialPartners,
    //   element: <LearnAboutTrialPartners />,
    // },
    {
      path: routeConstants.sendFeedback,
      element: <SendFeedback />,
    },
    {
      path: routeConstants.conversions,
      element: <Conversions />,
    },
    {
      path: routeConstants.conversionsAddForm,
      element: <AddFormContainer />,
    },
    {
      path: routeConstants.conversionsUpdateForm + "/:id",
      element: <EditFormContainer />,
    },
    {
      path: routeConstants.conversionAccountDetails + "/:id",
      element: <ConversionAccountDetails />,
    },
    {
      path: routeConstants.conversionDetails + "/:id",
      element: <ConversionDetails />,
    },
    {
      path: routeConstants.adCopies,
      element: <AdCopies />,
    },
    {
      path: "*",
      element: <ComingInFuture />,
    },
  ],
};

export default MainRoutes;
