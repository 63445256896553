import { Box } from "@mui/material";
import InsightCards from "./InsightCards";
import { colorType } from "../../types/common";
import { dashboardSelector } from "../../store/dashboard/selector";
import { useSelector } from "react-redux";
import { DashboardState } from "../../store/dashboard/types";
import { CursorIcon, DollarIcon, GrowthChartBigBlueIcon } from "../Icons";

const iconMap: Record<string, any> = {
  impressions: GrowthChartBigBlueIcon,
  clicks: CursorIcon,
  conversion: DollarIcon,
  ctr: DollarIcon,
  cost: DollarIcon,
  cost_per_conversion: DollarIcon,
  cpc: DollarIcon,
  conversions_on_ad_spend: DollarIcon,
};

const titleMap: Record<string, string> = {
  impressions: "Impressions",
  clicks: "Clicks",
  conversion: "Conversions",
  ctr: "CTR",
  cost: "Cost",
  cost_per_conversion: "Cost Per Conversion",
  cpc: "Avg. CPC",
  conversions_on_ad_spend: "Conversions on Ad Spend",
};

const InsightCardsSection = () => {
  const { statistics }: DashboardState = useSelector(dashboardSelector);

  const insightCardsData = Object.entries(statistics || {}).map(
    ([key, value]) => ({
      status: value.type === "more" ? "success" : "warning",
      title:
        titleMap[key] ||
        key.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase()),
      quantity: value.score.toLocaleString(),
      icon: iconMap[key] || DollarIcon,
      growth: value.percentage,
      type: value.type,
    })
  );

  return (
    <Box className="grid sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
      {insightCardsData.map(
        ({ status, title, quantity, icon, growth, type }) => (
          <InsightCards
            status={status as colorType}
            title={title}
            quantity={quantity}
            icon={icon()}
            growth={growth}
            type={type}
          />
        )
      )}
    </Box>
  );
};

export default InsightCardsSection;
