import { Box, Typography } from "@mui/material";
import { campaignHeaderOptions } from "../../utils/data";
import { CalenderTimeIcon } from "../Icons";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { campaignsFilterSelector } from "../../store/campaigns/selector";
import { CampaignsSagaAction } from "../../store/campaigns/sagas";
import { CampaignsReducerAction } from "../../store/campaigns/slice";
import {
  CampaignsFilterEnum,
  DATE_PICKER_FORMAT,
} from "../../utils/appConstants";
import BasicSelect from "../formControls/BasicSelect";

const CampaignHeader = () => {
  const dispatch = useDispatch();
  const campaignsFilter = useSelector(campaignsFilterSelector);

  const handleFilter = (field: string, value: string | Date | null) => {
    const filterCopy = { ...campaignsFilter };

    switch (field) {
      case CampaignsFilterEnum.dataSource:
        filterCopy.dataSource = value;
        break;
      case CampaignsFilterEnum.startDate:
        filterCopy.startDate = value;
        break;
      case CampaignsFilterEnum.endDate:
        filterCopy.endDate = value;
        break;
      default:
        break;
    }

    dispatch(CampaignsReducerAction.setCampaignSkip(0));
    dispatch(CampaignsReducerAction.setCampaignFilter(filterCopy));
    dispatch(
      CampaignsReducerAction.setCampaigns({
        data: [],
        total: 0,
      })
    );

    dispatch(
      CampaignsSagaAction.getCampaigns({
        source: filterCopy.dataSource,
        start_date: filterCopy.startDate,
        end_date: filterCopy.endDate,
        skip: 0,
        limit: 10,
      })
    );
  };

  return (
    <Box>
      <Box className="p-5 py-2.5 flex-column sm:flex-row justify-between items-start gap-5 lg:gap-0 lg:items-center">
        <Typography className="!text-xl !font-medium !leading-6 !text-primary-black-950">
          All Campaigns
        </Typography>
        <Box className="flex-column md:flex-row gap-3 items-center">
          <Typography className="w-full flex-center !text-base !font-medium !leading-6 !text-primary-black-950">
            Data Source:
          </Typography>
          <Box className="flex-center w-full">
            <BasicSelect
              options={campaignHeaderOptions}
              value={campaignsFilter.dataSource}
              onChange={(value: string) =>
                handleFilter(CampaignsFilterEnum.dataSource, value)
              }
            />
          </Box>
          <Box className="flex gap-[10px]">
            <DatePicker
              icon={
                <div className="flex h-full mt-[2px]">
                  <CalenderTimeIcon />
                </div>
              }
              showIcon
              className="!px-[12px] !pl-8 !py-[10px] rounded-md border w-36 border-[#E9ECF2] bg-[#F4F5F8]"
              selectsStart
              selected={campaignsFilter.startDate}
              popperClassName=" !z-50"
              dateFormat={DATE_PICKER_FORMAT}
              startDate={campaignsFilter.startDate}
              endDate={campaignsFilter.endDate}
              onChange={(value) =>
                handleFilter(CampaignsFilterEnum.startDate, value)
              }
            />
            <DatePicker
              icon={
                <div className="flex h-full mt-[2px]">
                  <CalenderTimeIcon />
                </div>
              }
              showIcon
              selectsEnd
              popperClassName=" !z-50"
              className="!px-[12px] !pl-8 !py-[10px] rounded-md border w-36 border-[#E9ECF2] gap-2 bg-[#F4F5F8]"
              selected={campaignsFilter.endDate}
              dateFormat={DATE_PICKER_FORMAT}
              startDate={campaignsFilter.startDate}
              endDate={campaignsFilter.endDate}
              onChange={(value) =>
                handleFilter(CampaignsFilterEnum.endDate, value)
              }
              minDate={campaignsFilter.startDate}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CampaignHeader;
