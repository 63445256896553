import { Box } from "@mui/material";
import OverviewSection from "../components/dashboard/overviewSection";
import ReturnAndCostSection from "../components/dashboard/returnAndCostSection";
import CostPerConversionSection from "../components/dashboard/costPerConversionSection";
import ConversionsByDaySection from "../components/dashboard/conversionsByDaySection";
import ChannelAndAdVisitorsSection from "../components/dashboard/channelAndAdVisitorsSection";
import AdCopiesSection from "../components/dashboard/adCopiesSection";
import InsightsSection from "../components/dashboard/insightsSection";
import { useSelector } from "react-redux";
import {
  dashboardFilterSelector,
  dashboardLoadingSelector,
} from "../store/dashboard/selector";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { DashboardSagaAction } from "../store/dashboard/sagas";
import SkeletonLoader from "../components/common/SkeletonLoader";

const Dashboard = () => {
  const dispatch = useDispatch();
  const dayFilter = useSelector(dashboardFilterSelector);
  const isLoading = useSelector(dashboardLoadingSelector);

  useEffect(() => {
    dispatch(
      DashboardSagaAction.getDashBoardData({
        days: dayFilter,
      })
    );
  }, [dayFilter]);

  return (
    <Box className="flex-column gap-5 sm:mt-0 mt-[72px]">
      {isLoading ? (
        <SkeletonLoader variant="grid" count={8} className="grid sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4" />
      ) : (
        <InsightsSection />
      )}
      {isLoading ? (
        <SkeletonLoader variant="chart" height={327} />
      ) : (
        <OverviewSection />
      )}
      {isLoading ? (
        <Box className="flex flex-col lg:flex-row">
          <SkeletonLoader variant="card" height={470} />
          <SkeletonLoader variant="card" height={470} />
        </Box>
      ) : (
        <ReturnAndCostSection />
      )}
      {isLoading ? (
        <SkeletonLoader variant="chart" height={541} />
      ) : (
        <CostPerConversionSection />
      )}
      {isLoading ? (
        <SkeletonLoader variant="chart" height={541} />
      ) : (
        <ConversionsByDaySection />
      )}
      {isLoading ? (
        <Box className="flex flex-col lg:flex-row">
          <SkeletonLoader variant="card" height={400} />
          <SkeletonLoader variant="card" height={400} />
        </Box>
      ) : (
        <ChannelAndAdVisitorsSection />
      )}
      {isLoading ? (
        <SkeletonLoader variant="chart" height={670} />
      ) : (
        <AdCopiesSection />
      )}
    </Box>
  );
};

export default Dashboard;
