import { Controller, FieldValues } from "react-hook-form";
import { Stack } from "@mui/material";
import FormFieldLabel from "./FormFieldLabel";
import { FieldConfig } from "../../types/forms";
import DatePicker from "react-datepicker";

// Utility function to format date from 'DD.MM.YYYY' to 'YYYY-MM-DD'
export const formatDate = (date: string) => {
  if (!date || typeof date !== "string") {
    return ""; // Return an empty string or handle it as necessary
  }
  return date.split(".").reverse().join("-");
};

// DatePicker component for rendering a date input field
const CustomDatePicker = <T extends FieldValues, U extends FieldValues = T>({
  label,
  name,
  type,
  editable = true,
  onChange,
  control,
  rules,
  errors,
  required,
  value,
  dateFormat,
  placeholder,
  maxDate
}: FieldConfig<T, U>) => {

  return (
    <Controller
      name={name!}
      control={control}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      rules={rules as any}
      render={({ field }) => {
        return (
          <Stack className="flex-column gap-2.5">
            {label && <FormFieldLabel label={label} required={required} />}
            <DatePicker
              selected={value ? new Date(value) : null}
              onChange={(date) => {
                field.onChange(date);
                onChange && onChange();
              }}
              disabled={!editable}
              placeholderText={placeholder}
              dateFormat={dateFormat}
              className={`!w-full !border !border-[#E5E5E5] !rounded-xl !py-4 !px-5 !text-sm !font-normal !outline-none !text-primary-black-600 ${
                errors && "!border-red"
              }`}
              maxDate={maxDate}
            />
            <span className="text-red mx-0 text-sm font-semibold">
              {errors}
            </span>
          </Stack>
        );
      }}
    />
  );
};

export default CustomDatePicker;
