import { Box, Breadcrumbs, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { DrawerReducerAction } from "../../store/drawer/slice";
import ClientDropdown from "./ClientDropdown";
import { BreadcrumbSeparator, CalenderTimeIcon } from "../../components/Icons";
import { useEffect } from "react";
import { BreadcrumbSagaAction } from "../../store/breadcrumb/saga";
import { breadcrumbSelector } from "../../store/breadcrumb/selector";
import { breadcrumbMap } from "../../utils/appConstants";
import BasicSelect from "../../components/formControls/BasicSelect";
import routeConstants from "../../utils/routeConstants";
import { DashboardReducerAction } from "../../store/dashboard/slice";
import { dashboardFilterSelector } from "../../store/dashboard/selector";

const dateFilterOptions = [
  { label: "Last 10 days", value: "10" },
  { label: "Last 20 days", value: "20" },
  { label: "Last 30 days", value: "30" },
];

const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const breadcrumbs = useSelector(breadcrumbSelector) as {
    label: string;
    path?: string;
  }[];
  const daysSelector = useSelector(dashboardFilterSelector);

  useEffect(() => {
    const currentPath = location.pathname;
    if (breadcrumbMap[currentPath]) {
      dispatch(BreadcrumbSagaAction.updateBreadcrumbs({ path: currentPath }));
    }
  }, [location.pathname, dispatch]);

  const openDrawer = () => {
    dispatch(DrawerReducerAction.setDrawerOpen(true));
  };

  return (
    <Box className="fixed top-0 left-0 w-full lg:w-[calc(100%-256px)] flex-1 bg-white shadow-sm p-4 z-50 lg:ml-[256px]">
      <Box className="flex md:flex-nowrap items-center justify-between gap-4">
        <Box className="flex items-center gap-4 w-full">
          <Box onClick={openDrawer} className="cursor-pointer lg:hidden">
            <MenuIcon />
          </Box>

          <Breadcrumbs
            aria-label="breadcrumb"
            separator={BreadcrumbSeparator}
            className="truncate"
          >
            {breadcrumbs.map((item, index) => {
              const isLast = index === breadcrumbs.length - 1;
              const isFirst = index === 0;
              return (
                <Typography
                  key={index}
                  onClick={() => {
                    if (!item?.path) return;
                    const isChild = index === 1;
                    const hasSubChild = breadcrumbs.length > 2;
                    if (isChild && hasSubChild) {
                      navigate(-1);
                    } else {
                      navigate(item.path as string);
                    }
                  }}
                  className={`truncate ${
                    item?.path ? "cursor-pointer" : ""
                  } hover:text-black ${
                    isFirst
                      ? "!text-primary-black-950 !text-lg !font-medium" // Parent (Bold)
                      : isLast
                      ? "text-gray-800 !text-base !font-medium" // Sub-child (Darker)
                      : "text-gray-600 !text-sm !font-medium" // Child (Lighter)
                  }`}
                >
                  {item?.label}
                </Typography>
              );
            })}
          </Breadcrumbs>
        </Box>

        <Box className="flex flex-col sm:flex-row items-center gap-3 w-full sm:justify-end">
          {location.pathname === routeConstants.dashboard ? (
            <BasicSelect
              icon={CalenderTimeIcon}
              options={dateFilterOptions}
              value={
                dateFilterOptions.find(
                  (option) => option.value === String(daysSelector)
                )?.label || "Last 30 days"
              }
              onChange={(value: string) => {
                const days = parseInt(value, 10);
                dispatch(DashboardReducerAction.setFilter(days));
              }}
            />
          ) : null}
          <ClientDropdown />
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
