import { AxiosResponse } from "axios";
import { createSliceSaga } from "../saga-helper";
import { call, put, select } from "redux-saga/effects";
import { AdCopiesReducerAction } from "./slice";
import { adCopiesSelector } from "./selector";
import { AdCopiesListType } from "./types";
import {
  AdCopiesDetailsService,
  AdCopiesService,
  DropdownListService,
  PostAdCopiesService,
} from "../../services/AdCopiesService";
import { SORT } from "../../types/adCopiesFilterTypes";

const saga = createSliceSaga({
  name: "adcopies-saga-state",
  caseSagas: {
    *getAdCopies(action: {
      payload: {
        provider: string;
        start_date: string;
        end_date: string;
        skip: number;
        limit: number;
        creative_type: string[];
        status: string[];
        platform: string;
        sorting: SORT;
        indication: string[];
      };
      type: string;
    }) {
      try {
        const adCopiesList: AdCopiesListType = yield select(adCopiesSelector);
        yield put(AdCopiesReducerAction.setIsLoading(true));
        // Step-2: Calling the Service file API and setting the data in reducer
        const { data: apiResponse, status }: AxiosResponse = yield call(
          AdCopiesService,
          action.payload
        );

        if (status === 200) {
          let responseData = apiResponse["ad-copies"];

          if (adCopiesList["ad-copies"].length > 0 && responseData.length > 0)
            responseData = [...adCopiesList["ad-copies"], ...responseData];

          yield put(
            AdCopiesReducerAction.setAdCopies({
              total: apiResponse.total ?? 0,
              "ad-copies": responseData,
            })
          );
        }
      } catch (error) {
        console.error("Error fetching Ad-Copies!");
      } finally {
        yield put(AdCopiesReducerAction.setIsLoading(false));
      }
    },
    *getDropdownList() {
      try {
        const { data: apiResponse, status }: AxiosResponse = yield call(
          DropdownListService
        );

        if (status === 200) {
          yield put(
            AdCopiesReducerAction.setDropdownList({
              total: apiResponse.total ?? 0,
              dropdown: apiResponse.data,
            })
          );
        }
      } catch (error) {
        console.error("Error fetching dropdown list");
      }
    },
    *getAdCopiesDetails(action: {
      payload: {
        ad_copy_id: number;
      };
      type: string;
    }) {
      try {
        yield put(AdCopiesReducerAction.setIsLoading(true));

        const { data: apiResponse, status }: AxiosResponse = yield call(
          AdCopiesDetailsService,
          action.payload
        );

        if (status === 200) {
          const { id, name, image_url, metrics } = apiResponse.adCopies;

          yield put(
            AdCopiesReducerAction.setAdCopiesDetails({
              id,
              name,
              image_url,
              metrics,
            })
          );
        }
      } catch (error) {
        console.error("Error fetching Ad Copies Details");
      } finally {
        yield put(AdCopiesReducerAction.setIsLoading(false));
      }
    },
    *postAdCopies(action: {
      payload: {
        name: string;
        fb_ads_id: number;
        ig_ads_id: number;
        google_ads_id: number;
        tiktok_ads_id: number;
        image: string;
      };
    }) {
      const {
        name,
        fb_ads_id,
        ig_ads_id,
        google_ads_id,
        tiktok_ads_id,
        image,
      } = action.payload;

      const formData: any = new FormData();
      formData.append("name", name);
      formData.append("fb_ads_id", fb_ads_id);
      formData.append("ig_ads_id", ig_ads_id);
      formData.append("google_ads_id", google_ads_id);
      formData.append("tiktok_ads_id", tiktok_ads_id);
      formData.append("image", image);

      try {
        yield put(AdCopiesReducerAction.setIsLoading(true));

        const { status }: AxiosResponse = yield call(
          PostAdCopiesService,
          formData
        );

        if (status === 200) {
          yield put(AdCopiesReducerAction.setAdCopiesSkip(0));
        }
      } catch (error) {
        console.error("Error generating Ad Copies.");
      } finally {
        yield put(AdCopiesReducerAction.setIsLoading(false));
      }
    },
  },
  sagaType: "takeEvery",
});

export const {
  actions: AdCopiesSagaAction,
  saga: AdCopiesSaga,
  name: AdCopiesSagaName,
} = saga;
