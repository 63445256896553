import { createSlice } from "@reduxjs/toolkit";

interface ClientState {
  clients: any[];
  selectedClient: string | null;
  isLoading: boolean;
  error: string | null;
}

export const initialState: ClientState = {
  clients: [],
  selectedClient: null,
  isLoading: false,
  error: null,
};

const clientSlice = createSlice({
  name: "clientState",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setClientList(state, action) {
      state.clients = action.payload;
    },
    setClientSelected(state, action) {
      state.selectedClient = action.payload;
    },
  },
});

export const {
  actions: ClientReducerAction,
  name: ClientReducerName,
  reducer: ClientReducer,
} = clientSlice;
