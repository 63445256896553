import React from "react";
import { Skeleton } from "@mui/material";

interface SkeletonLoaderProps {
  variant?: "card" | "chart" | "grid";
  count?: number;
  height?: number;
  className?:string;
}

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({
  variant = "card",
  count = 6,
  height = 100,
  className = ""
}) => {
  if (variant === "grid") {
    return (
      <div>
        <Skeleton variant="text" width="30%" height={30} className="mb-4" />
        <div className={className}>
          {[...Array(count)].map((_, index) => (
            <div
              key={index}
              className="p-5 border-r border-r-primary-blue-100 border-t border-t-primary-blue-100 shadow-sm"
            >
              <Skeleton variant="circular" width={50} height={50} />
              <Skeleton
                variant="text"
                width="60%"
                height={25}
                className="mt-3"
              />
              <Skeleton variant="text" width="40%" height={20} />
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (variant === "chart") {
    return (
      <div className="p-4 border-r-primary-blue-100 rounded-lg shadow-sm w-full">
        <Skeleton variant="text" width="30%" height={30} className="mb-4" />
        <Skeleton
          variant="rectangular"
          height={height}
          className="rounded-lg"
        />
      </div>
    );
  }

  return (
    <div className="flex-column p-4 border-r-primary-blue-100 rounded-lg shadow-sm min-w-[40vw] w-full">
      <Skeleton variant="rectangular" height={height} className="rounded-lg" />
      <Skeleton variant="text" width="80%" height={20} className="mt-3" />
      <Skeleton variant="text" width="60%" height={20} />
    </div>
  );
};

export default SkeletonLoader;
