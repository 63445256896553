import { Box, Typography } from "@mui/material";
import CustomSelect from "../formControls/CustomSelect";
import { CircleDeleteIcon, CirclePlusIcon, LongRightArrow } from "../Icons";
import CustomTextField from "../formControls/CustomTextField";
import CustomButton from "../common/CustomButton";

import { SubmitHandler, UseFormHandleSubmit } from "react-hook-form";

interface AddFormProps {
  isEdit: boolean;
  handleSubmit: UseFormHandleSubmit<any>;
  onSubmit: SubmitHandler<any>;
  isLoading: boolean;
  control: any;
  errors: any;
  patients: Array<any>;
  fields: Array<{ id: string }>;
  plateFormFields: Array<any>;
  append: (value: any) => void;
  remove: (index: number) => void;
}

const AddUpdateForm: React.FC<AddFormProps> = ({
  isEdit,
  handleSubmit,
  onSubmit,
  isLoading,
  control,
  errors,
  patients,
  fields,
  plateFormFields,
  append,
  remove,
}) => {
  return (
    <Box className="flex justify-center items-center w-full lg:w-[100%]">
      <Box className="bg-white flex-column lg:w-[70%] rounded-[20px] border border-primary-blue-100">
        {/* Header */}
        <Box className="p-5 flex justify-between items-center gap-[2px] border-b border-b-primary-blue-100 ">
          <Typography className="!text-xl !font-medium">
            {isEdit ? "Edit" : "Add"} Form
          </Typography>
          <CustomButton
            onClick={handleSubmit(onSubmit)}
            className="!gap-[5px] !cursor-pointer !rounded-lg !px-[20px] !py-[8px] !bg-primary-blue-950 flex-center !text-white"
            loading={isLoading}
          >
            <Typography className="!text-sm !font-normal !text-white !normal-case">
              {isEdit ? "Update" : "Save"}
            </Typography>
          </CustomButton>
        </Box>

        {/* Form Fields */}
        <Box className="flex-column gap-[25px] px-20 py-5">
          <CustomTextField
            label=""
            name="form_url"
            placeholder="Enter Form URL"
            control={control}
            errors={errors.form_url?.message}
            rules={{ required: "Form URL is required" }}
          />
          <CustomTextField
            label=""
            name="form_name"
            placeholder="Enter Form Name"
            control={control}
            errors={errors.form_name?.message}
            rules={{ required: "Form Name is required" }}
          />
          <CustomSelect
            name="patient_id"
            control={control}
            placeholder="Select Patient"
            options={patients}
            errors={errors.patient_id?.message}
            rules={{ required: "Patient selection is required" }}
          />
        </Box>

        {/* Form Field Mapping */}
        <Box className="flex flex-col px-20 py-5 gap-[10px]">
          <Box className="flex w-full">
            <Typography className="w-[60%] !text-[18px] !font-medium">
              Form Field
            </Typography>
            <Typography className="w-1/2 !text-[18px] !font-medium">
              Platform Field
            </Typography>
          </Box>

          {fields.map((field, index) => (
            <Box key={field.id} className="flex justify-center w-full">
              {/* Form Field */}
              <Box className="w-[40%]">
                <CustomTextField
                  label=""
                  name={`mappings.${index}.form_field`}
                  placeholder="Enter Form Field"
                  control={control}
                  errors={errors.mappings?.[index]?.form_field?.message}
                  rules={{ required: "Form Field is required" }}
                />
              </Box>

              <span className="flex justify-center items-center w-[10%] mx-8">
                {LongRightArrow}
              </span>

              {/* Platform Field */}
              <Box className="w-[50%] flex gap-3 items-center">
                <CustomSelect
                  options={plateFormFields}
                  name={`mappings.${index}.platform_field`}
                  placeholder="Select Platform Field"
                  control={control}
                  errors={errors.mappings?.[index]?.platform_field?.message}
                  rules={{ required: "Platform Field is required" }}
                />
                <Box className="flex gap-2">
                  <Box
                    onClick={() =>
                      append({ platform_field: "", form_field: "" })
                    }
                    className="cursor-pointer"
                  >
                    {CirclePlusIcon}
                  </Box>
                  {fields.length > 1 && (
                    <Box
                      onClick={() => remove(index)}
                      className="cursor-pointer"
                    >
                      {CircleDeleteIcon}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default AddUpdateForm;
