import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  InputAdornment,
  Checkbox,
  ListItemText,
  Typography,
} from "@mui/material";
import { BasicSelectType, SelectOptionType } from "../../types/forms";
import { ArrowDownIcon } from "../Icons";

export default function BasicSelect(props: BasicSelectType) {
  const {
    options,
    icon,
    value,
    onChange,
    isCheckbox = false,
    placeholder,
  } = props;
  const [open, setOpen] = React.useState(false);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    let selectedValue = event.target.value;

    if (isCheckbox) {
      onChange(
        typeof selectedValue === "string"
          ? [selectedValue]
          : (selectedValue as string[])
      );
    } else {
      onChange(selectedValue as string);
    }
  };

  return (
    <Box sx={{ minWidth: 136, height: "100%" }}>
      <FormControl fullWidth>
        <Select
          multiple={isCheckbox}
          value={value}
          IconComponent={() => null}
          onChange={handleChange}
          displayEmpty
          inputProps={{
            "aria-label": "Without label",
            className: "p-0! !m-0",
          }}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          renderValue={(selected) => {
            if ((selected as string[]).length === 0) {
              return <Typography>{placeholder}</Typography>;
            } else return value;
          }}
          startAdornment={
            icon && (
              <InputAdornment className="!m-0" position="start">
                <Box>{icon()}</Box>
              </InputAdornment>
            )
          }
          endAdornment={
            <InputAdornment className="!m-0" position="end">
              <Box
                className={`${
                  value && "border-l-2 border-l-primary-blue-100 pl-2"
                }`}
              >
                {ArrowDownIcon}
              </Box>
            </InputAdornment>
          }
          sx={{
            cursor: "pointer",
            borderRadius: "6px",
            border: "1px solid #E9ECF2 ",
            backgroundColor: "#F4F5F8",
            "& .MuiSelect-select": {
              height: "100%",
            },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                border: 0,
              },
            "& .MuiOutlinedInput-input": {
              paddingX: "12px !important",
              paddingY: "10px !important",
              backgroundColor: "#F4F5F8",
            },
          }}
        >
          {options?.map((item: SelectOptionType, index: number) => (
            <MenuItem key={item.value} value={item.value}>
              {isCheckbox ? (
                <>
                  {open && (
                    <Checkbox
                      checked={
                        Array.isArray(value) && value.includes(item.value ?? "")
                      }
                    />
                  )}
                  <ListItemText primary={item.label} />
                </>
              ) : (
                item.label
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
