import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import routeConstants from "../utils/routeConstants";
import useAuth from "../hooks/useAuth";

const AuthGuard = ({ children }: any) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(routeConstants.login);
    }
  }, [isAuthenticated, navigate]);

  return children;
};

export default AuthGuard;
