import { createSlice } from "@reduxjs/toolkit";
import { DashboardDataState } from "./types";
import { ComputerIcon, MobileIcon, TabletIcon } from "../../components/Icons";

// SAGA FLOW EXAMPLE FOR FUTURE API INTEGRATION

export const initialState: DashboardDataState = {
  dashboardData: {
    statistics: {
      impressions: {
        score: 0,
        lastScore: 0,
        icon: "",
        percentage: "0%",
        type: "",
      },
      clicks: {
        score: 0,
        lastScore: 0,
        icon: "",
        percentage: "0%",
        type: "",
      },
      conversion: {
        score: 0,
        lastScore: 0,
        icon: "",
        percentage: "0%",
        type: "",
      },
      ctr: {
        score: 0,
        lastScore: 0,
        icon: "",
        percentage: "0%",
        type: "",
      },
      cost: {
        score: 0,
        lastScore: 0,
        icon: "",
        percentage: "0%",
        type: "",
      },
      cost_per_conversion: {
        score: 0,
        lastScore: 0,
        icon: "",
        percentage: "0%",
        type: "",
      },
      cpc: {
        score: 0,
        lastScore: 0,
        icon: "",
        percentage: "0%",
        type: "",
      },
      conversions_on_ad_spend: {
        score: 0,
        lastScore: 0,
        icon: "",
        percentage: "0%",
        type: "",
      },
    },
    overview: {
      impressions: 0,
      clicks: 0,
      conversions: 0,
      conversions_value: 0,
    },
    return_on_ad_spend: {
      Google: 0,
      Facebook: 0,
      Instagram: 0,
      Tiktok: 0,
    },
    cost_per_click: {
      Total: 0,
      Google: 0,
      Facebook: 0,
      Instagram: 0,
      Tiktok: 0,
    },
    cost_per_conversion: {
      Google: {},
      Facebook: {},
      Instagram: {},
      Tiktok: {},
    },
    conversions_by_day: {
      conversions_by_day_graph: {
        Google: {},
        Facebook: {},
        Instagram: {},
        Tiktok: {},
      },
      conversions_by_day_percentage: {
        Facebook: "0%",
        Google: "0%",
        Instagram: "0%",
        Tiktok: "0%",
      },
    },
    top_advertisement_channel_by_conversions: {
      advertising_channels: [
        { platform: "Google", conversions: 0, percentage: "0%" },
        { platform: "Facebook", conversions: 0, percentage: "0%" },
        { platform: "Instagram", conversions: 0, percentage: "0%" },
        { platform: "Tiktok", conversions: 0, percentage: "0%" },
      ],
      total: 0,
    },
    ad_visitors_across_devices: [
      {
        name: "Mobile Phones",
        percentage: 0,
        color: "#20407D",
        icon: MobileIcon,
      },
      {
        name: "Tablets",
        percentage: 0,
        color: "#4B73B8",
        icon: TabletIcon,
      },
      {
        name: "Computers",
        percentage: 0,
        color: "#8D9DD0",
        icon: ComputerIcon,
      },
    ],
    ad_copies: {
      "ad-copies": [],
      total: 0,
    },
  },
  isLoading: false,
  filter: {
    days: 10,
  },
};

const dashboard = createSlice({
  name: "dashboardState",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setDashboardData(state, action) {
      state.dashboardData = action.payload;
    },
    setFilter(state, action) {
      state.filter.days = action.payload;
    },
  },
});

export const {
  actions: DashboardReducerAction,
  name: DashboardReducerName,
  reducer: DashboardReducer,
} = dashboard;
