import { createSlice } from "@reduxjs/toolkit";
import { subDays } from "date-fns";
import { ConversionsInitialStateType } from "./types";

// SAGA FLOW EXAMPLE FOR FUTURE API INTEGRATION

export const initialState: ConversionsInitialStateType = {
  isLoading: false,
  conversions: {
    data: [],
    total: 0,
  },
  filter: {
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
  },
  skip: 0,
};

const conversions = createSlice({
  name: "conversionsState",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setConversions(state, action) {
      state.conversions = action.payload;
    },
    setCampaignFilter(state, action) {
      state.filter = action.payload;
    },
    setConversionskip(state, action) {
      state.skip = action.payload;
    },
    setConversionInitial(state, action) {
      state.conversions.data = [];
      state.conversions.total = 0;
      state.skip = 0;
    },
  },
});

export const {
  actions: ConversionsReducerAction,
  name: ConversionsReducerName,
  reducer: ConversionsReducer,
} = conversions;
