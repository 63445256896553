import {
  BarGraphIcon,
  ChatsIcon,
  ComputerIcon,
  DashboardIcon,
  FacebookIcon,
  GoogleIcon,
  InfoIcon,
  InstagramIcon,
  ListIcon,
  MegaphoneIcon,
  MetaIcon,
  MobileIcon,
  PlayIcon,
  ShieldIcon,
  SocialIconCombined,
  TabletIcon,
  TikTokIcon,
  TreeIcon,
} from "../components/Icons";
import routeConstants from "./routeConstants";

export const dataSourceList = [
  { name: "Google", key: "google", icon: GoogleIcon, isConnected: false },
  { name: "Meta", key: "facebook", icon: MetaIcon, isConnected: false },
  { name: "TikTok", key: "tiktok", icon: TikTokIcon, isConnected: false },
];

export const campaignHeaderOptions = [
  {
    label: "All",
    value: "All",
  },
  {
    icon: GoogleIcon,
    label: "Google Ads",
    value: "google",
  },
  // {
  //   icon: InstagramIcon,
  //   title: "Instagram Ads",
  // },
  {
    icon: FacebookIcon,
    label: "Facebook Ads",
    value: "facebook",
  },
  // {
  //   icon: TikTokIcon,
  //   title: "TikTok Ads",
  // },
];

export const adVisitorDeviceData = [
  {
    name: "Mobile Phones",
    percentage: 25,
    color: "#20407D",
    icon: MobileIcon,
  },
  {
    name: "Tablets",
    percentage: 55,
    color: "#4B73B8",
    icon: TabletIcon,
  },
  {
    name: "Computers",
    percentage: 20,
    color: "#8D9DD0",
    icon: ComputerIcon,
  },
];

export const metricsData = [
  {
    "Trial Partner Metrics": ["Click score", "Convert score"],
  },
  {
    Performance: [
      "Average order value",
      "Reach",
      "Impressions",
      "Cost per mille",
      "Spend",
    ],
  },
];

export const reportsStepOneSocialMediaOptions = [
  { name: "All", icon: SocialIconCombined },
  { name: "Google", icon: GoogleIcon },
  { name: "Meta", icon: FacebookIcon },
  { name: "Tiktok", icon: TikTokIcon },
];

export const reportStepperTitleAndSubtitle = [
  {
    title: "Select Platform",
    subtitle:
      "Lorem ipsum dolor sit amet consectetur. Sapien quis laoreet tempor dolor massa dignissim in tellus tellus.",
  },
  {
    title: "Select Google Campaign",
    subtitle:
      "Kindly choose the campaigns for which you wish to generate a report.",
  },
  {
    title: "Select Metrics",
    subtitle:
      "Lorem ipsum dolor sit amet consectetur. Sapien quis laoreet tempor dolor massa dignissim in tellus tellus.",
  },
  {
    title: "Select Date Range",
    subtitle:
      "Lorem ipsum dolor sit amet consectetur. Sapien quis laoreet tempor dolor massa dignissim in tellus tellus.",
  },
];

export const campaignFilterOptions = [
  {
    title: "Campaign",
  },
  {
    title: "Conversions",
  },
  {
    title: "Reports",
  },
];

export const dateFilterOptions = [
  {
    title: "Last 30 days",
    value: "Last 30 days",
  },
  {
    title: "Last 15 days",
    value: "Last 15 days",
  },
  {
    title: "Last 7 days",
    value: "Last 7 days",
  },
];

export const sidebarItems = [
  { icon: DashboardIcon, label: "Dashboard", link: routeConstants.dashboard },
  { icon: ListIcon, label: "Conversions", link: routeConstants.conversions },
  { icon: MegaphoneIcon, label: "Campaigns", link: routeConstants.campaigns },
  { icon: PlayIcon, label: "Ad Copies", link: routeConstants.adCopies },
  {
    icon: TreeIcon,
    label: "Manage Data Sources",
    link: routeConstants.manageDataSource,
  },
  { icon: BarGraphIcon, label: "Reports", link: routeConstants.reports },
  // {
  //   icon: BookIcon,
  //   label: "Learn about Trial Partners",
  //   link: routeConstants.learnAboutTrialPartners,
  // },
  {
    icon: ChatsIcon,
    label: "Send Feedback",
    link: routeConstants.sendFeedback,
  },
  { icon: InfoIcon, label: "About Us", link: routeConstants.aboutUs },
  {
    icon: ShieldIcon,
    label: "Privacy Policy",
    link: routeConstants.privacyPolicy,
  },
];

export const learnAboutUsItems = [
  {
    title: "How to manage data sources ?",
    thumbnail: require("../assets/Rectangle1.png"),
  },
  {
    title: "How to generate Report ?",
    thumbnail: require("../assets/Rectangle1.png"),
  },
  {
    title: "How to add role from Manage Members ?",
    thumbnail: require("../assets/Rectangle1.png"),
  },
  {
    title: "How to manage data sources ?",
    thumbnail: require("../assets/Rectangle1.png"),
  },
  {
    title: "How to add role from Manage Members ?",
    thumbnail: require("../assets/Rectangle1.png"),
  },
  {
    title: "How to generate Report ?",
    thumbnail: require("../assets/Rectangle1.png"),
  },
];
