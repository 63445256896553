import { Controller } from "react-hook-form";
import { MenuItem, Select, FormControl, Typography } from "@mui/material";
import { styled } from "@mui/system";

interface CustomSelectProps {
  name: string;
  control: any;
  options: { label: string; value: string }[];
  placeholder?: string;
  errors?: string;
  length?: string;
  rules?: any;
  required?: boolean;
}

// Styled Select Component
export const StyledSelect = styled(Select, {
  shouldForwardProp: (prop) => prop !== "length",
})<{ length?: string }>(({ length }) => ({
  width: length || "100%",
  borderRadius: "12px",
  background: "#fff",
  border: "1px solid #E5E5E5",
  fontSize: "16px",
  fontWeight: "400",
  color: "#21232C",
  padding: "2px 4px",
  transition: "none",
  boxShadow: "none",
  "& .MuiSvgIcon-root": {
    color: "#1E3A8A",
  },
  "& .Mui-error": {
    marginLeft: "0px",
    "& .MuiSelect-select": {
      border: "1px solid #C82627",
      borderRadius: "4px",
    },
  },
  "&:hover": {
    border: "1px solid #E5E5E5",
    background: "#fff",
  },
}));

// Placeholder styling
const PlaceholderTypography = styled(Typography)({
  fontFamily: "Poppins",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "100%",
  letterSpacing: "0%",
  color: "#21232C",
  opacity: 0.5,
  padding: "3px 5px",
  borderRadius: "12px",
});

const CustomSelect: React.FC<CustomSelectProps> = ({
  name,
  control,
  options,
  placeholder = "Select an option",
  errors,
  rules,
  required,
  length,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules as any}
      render={({ field }) => (
        <FormControl fullWidth>
          <StyledSelect
            {...field}
            displayEmpty
            required={required}
            error={!!errors}
            length={length}
            renderValue={(selected) => {
              if (!selected) {
                return (
                  <PlaceholderTypography>{placeholder}</PlaceholderTypography>
                );
              }
              return (
                options.find((option) => option.value === selected)?.label ||
                placeholder
              );
            }}
          >
            <MenuItem value="" disabled>
              {placeholder}
            </MenuItem>
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </StyledSelect>
          {errors && (
            <span className="text-red mx-0 text-sm font-semibold mt-1">
              {errors}
            </span>
          )}
        </FormControl>
      )}
    />
  );
};

export default CustomSelect;
