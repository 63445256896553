import { Box } from "@mui/material";
import SectionHeader from "../SectionHeader";
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { useSelector } from "react-redux";
import { dashboardSelector } from "../../../store/dashboard/selector";
import { DashboardState } from "../../../store/dashboard/types";

// Custom function to format numbers (e.g., 13500 -> 13.5K)
const formatNumber = (num: number) => {
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1)}K`;
  }
  return num.toString();
};

// Define colors for each bar
const barColors = {
  impressions: "#20407D",
  clicks: "#4B73B8",
  conversions: "#8D9DD0",
  conversions_value: "#7A83A1",
};

// Define title mapping for clarity
const titleMap: Record<string, string> = {
  impressions: "Impressions",
  clicks: "Clicks",
  conversions: "Conversions",
  conversions_value: "Conversions Value",
};

const OverviewSection = () => {
  const { overview }: DashboardState = useSelector(dashboardSelector);

  const overviewData = Object.entries(overview).map(([key, value]) => ({
    name: titleMap[key] || key,
    value,
    color: barColors[key as keyof typeof barColors] || "#20407D",
  }));

  return (
    <Box className="bg-white rounded-xl border border-primary-blue-100 flex-column">
      <SectionHeader title="Overview" />
      <ResponsiveContainer minWidth={200} height={250}>
        <BarChart
          width={1000}
          height={250}
          data={overviewData}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        >
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip formatter={(value) => formatNumber(Number(value))} />
          <Bar dataKey="value" barSize={40} radius={[5, 5, 0, 0]}>
            {overviewData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default OverviewSection;
