import { Box } from "@mui/material";
import SectionHeader from "../SectionHeader";
import TopAdvertisingChannels from "./topAdvertisingChannels";
import AdVisitorDevices from "./adVisitorDevices";

const ChannelAndAdVisitorsSection = () => {
  return (
    <Box className="flex-column lg:flex-row gap-5 w-full">
      <Box className="bg-white rounded-xl border border-primary-blue-100 lg:w-[50%]">
        <SectionHeader title="Top Advertising channel by Conversions" />
        <TopAdvertisingChannels />
      </Box>
      <Box className="bg-white rounded-xl border border-primary-blue-100 lg:w-[50%]">
        <SectionHeader title="Ad Visitors across Devices" />
        <AdVisitorDevices />
      </Box>
    </Box>
  );
};

export default ChannelAndAdVisitorsSection;
