import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./slice";

// SAGA FLOW EXAMPLE FOR FUTURE API INTEGRATION

const selectDomain = (state: any) => state.dashboardState || initialState;

// Step-4: Making the state available for the files to be called through
export const dashboardSelector = createSelector(
  selectDomain,
  (state) => state.dashboardData
);

export const dashboardFilterSelector = createSelector(
  selectDomain,
  (state) => state.filter.days
);

export const dashboardLoadingSelector = createSelector(
  selectDomain,
  (state) => state.isLoading
);
