import axiosClient from "../apiClient";

// SAGA FLOW EXAMPLE FOR FUTURE API INTEGRATION

export const ManageDataSources = async () => {
  try {
    return await axiosClient.get("/manage-data-sources");
  } catch (error) {
    return error;
  }
};

export const GoogleAuthService = async () => {
  try {
    const response = await axiosClient.get("/google-ads/auth");
    return response.data;
  } catch (error) {
    return error;
  }
};

export const FacebookAuthService = async () => {
  try {
    const response = await axiosClient.get("/facebook-ads/auth");
    return response.data;
  } catch (error) {
    return error;
  }
};

export const DisconnectDataSourceService = async (params: {
  provider: string;
}) => {
  try {
    const response = await axiosClient.get(
      `/disconnect-data-source?provider=${params}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

//TODO: Add later once confirmed
// export const TikTokAuthService = async () => {
//   try {
//     const response = await axiosClient.post("/facebook-ads/auth");
//     return response.data;
//   } catch (error) {
//     return error;
//   }
// };
