import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import routeConstants from "../utils/routeConstants";
import useAuth from "../hooks/useAuth";

const GuestGuard = ({ children }: any) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth(); // Needed in future

  useEffect(() => {
    if (isAuthenticated) {
      navigate(routeConstants.dashboard);
    }
  }, [isAuthenticated, navigate]);

  return children;
};

export default GuestGuard;
