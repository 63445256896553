import { Box, Button, Drawer, Typography, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { DrawerReducerAction } from "../../store/drawer/slice";
import { drawerOpenSelector } from "../../store/drawer/selector";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import routeConstants from "../../utils/routeConstants";
import { sidebarItems } from "../../utils/data";
import { getCurrentUser } from "../../utils/utils";
import { useEffect } from "react";

const Sidebar = () => {
  const dispatch = useDispatch();
  const isDrawerOpen = useSelector(drawerOpenSelector);

  const location = useLocation();

  useEffect(() => {
    if (isDrawerOpen) {
      dispatch(DrawerReducerAction.setDrawerOpen(false));
    }
  }, [dispatch, location.pathname]);

  return (
    <>
      {/* Mobile Sidebar Drawer */}
      <Drawer
        open={isDrawerOpen}
        onClose={() => dispatch(DrawerReducerAction.setDrawerOpen(false))}
        className="lg:hidden"
      >
        <Box className="w-64 h-full bg-white flex flex-col justify-between">
          <MenuItems />
        </Box>
      </Drawer>

      {/* Desktop Sidebar - Hidden on Small Screens */}
      <Box className="hidden lg:block lg:fixed lg:bg-white lg:w-64 h-screen no-scrollbar z-50 overflow-y-auto">
        <Box className="flex flex-col justify-between h-full">
          <MenuItems />
        </Box>
      </Box>
    </>
  );
};

const MenuItems = () => {
  const currentUser = getCurrentUser();
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <Box className="px-5 py-[25px] flex flex-col gap-10 h-full">
      {/* Logo */}
      <Box className="flex justify-center cursor-pointer">
        <img
          src={require("../../assets/logo.png")}
          alt="logo"
          onClick={() => navigate(routeConstants.dashboard)}
        />
      </Box>

      {/* Profile Section */}
      <Box className="flex flex-col gap-2.5">
        <Typography className="!text-sm !font-normal !text-primary-black-600 uppercase">
          My Profile
        </Typography>
        <NavLink
          to={routeConstants.myProfile}
          className={`px-3 py-2.5 rounded-md border border-primary-blue-100 gap-2.5 bg-primary-blue-50 flex justify-between ${
            location.pathname === routeConstants.myProfile
              ? "border border-primary-blue-950"
              : ""
          }`}
        >
          <Box className="flex gap-2 items-center">
            <Box className="flex flex-col items-start">
              <Typography className="!text-sm font-normal text-primary-black-950">
                {`${currentUser?.first_name} ${currentUser?.last_name}`}
              </Typography>
              <Typography className="!w-44 !text-xs font-normal text-primary-blue-800 truncate">
                {currentUser?.email}
              </Typography>
            </Box>
          </Box>
        </NavLink>
      </Box>

      {/* Navigation Buttons */}
      <Box className="flex flex-col gap-2.5 flex-grow">
        {sidebarItems.map(({ icon, label, link }, index) => {
          const isActive =
            location.pathname === link ||
            location.pathname.startsWith(`${link}/`);
          return (
            <NavLink
              key={index}
              to={link}
              className={`px-3 py-2.5 rounded-md flex items-center gap-2.5 ${
                isActive ? "border border-[#E9ECF2] bg-[#F4F5F8]" : ""
              }`}
            >
              {icon}
              <Typography className="!text-sm font-normal text-primary-black-950">
                {label}
              </Typography>
            </NavLink>
          );
        })}
      </Box>

      <Box className="pb-5">
        <Button
          onClick={handleLogout}
          variant="outlined"
          fullWidth
          className="!bg-white !text-primary-blue-950 text-base rounded-lg border !border-primary-blue-950"
          endIcon={<LogoutIcon />}
        >
          <Typography
            variant="button"
            className="!text-sm font-normal text-primary-black-950"
          >
            Logout
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default Sidebar;
