import { createSlice } from "@reduxjs/toolkit";
import { AdCopiesInitialStateType } from "./types";

// SAGA FLOW EXAMPLE FOR FUTURE API INTEGRATION

export const initialState: AdCopiesInitialStateType = {
  isLoading: false,
  adCopies: {
    "ad-copies": [],
    total: 0,
  },
  // filter: {
  //   startDate: subDays(new Date(), 30),
  //   endDate: new Date(),
  //   dataSource: "All",
  // },
  skip: 0,
  dropdownList: {
    dropdown: [],
    total: 0,
  },
  adCopiesDetails: {
    id: 0,
    name: "",
    image_url: "",
    metrics: [],
  },
};

const adCopies = createSlice({
  name: "adCopiesState",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    // Step-3: Saving the payload in state
    setAdCopies(state, action) {
      state.adCopies = action.payload;
    },
    // setCampaignFilter(state, action) {
    //   state.filter = action.payload;
    // },
    setAdCopiesSkip(state, action) {
      state.skip = action.payload;
    },
    setDropdownList(state, action) {
      state.dropdownList = action.payload;
    },
    setAdCopiesDetails(state, action) {
      state.adCopiesDetails = action.payload;
    },
  },
});

export const {
  actions: AdCopiesReducerAction,
  name: AdCopiesReducerName,
  reducer: AdCopiesReducer,
} = adCopies;
