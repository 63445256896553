import React from "react";
import {
  Table,
  TableContainer,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TableBody as MuiTableBody,
  TableFooter as MuiTableFooter,
  TableProps as MuiTableProps,
  TableRowProps as MuiTableRowProps,
  TableCell,
  CircularProgress,
} from "@mui/material";

// Configuration options for the VirtuosoTable
interface VirtuosoTableConfig {
  customScrollbar?: boolean;
  highlightEvenRows?: boolean;
  seperateRows?: boolean;
}

// Props for the Table Footer component
interface TableFootProps {
  context?: {
    type?: string;
    isFetching?: boolean;
    isUninitialized?: boolean;
    nextPageSkip?: number;
    currentRowCount?: number;
    totalRecords?: number;
    tableCellCount: number;
  };
}

// Wrapper for TableHead to conform to react-virtuoso's expectations
const CustomTableHead = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>((props, ref) => <MuiTableHead component="thead" ref={ref} {...props} />);

CustomTableHead.displayName = "TableHead";

// Function to create custom table components
const AllTableComponents = ({
  customScrollbar = true,
  highlightEvenRows = false,
  seperateRows = true,
}: VirtuosoTableConfig) => {
  // Custom Scroller Component
  const Scroller = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement>
  >((props, ref) => (
    <TableContainer
      {...props}
      ref={ref}
      className={`MuiTableContainer-root h-full outline-none overflow-auto relative ${
        customScrollbar ? "table-custom-scrollbar" : ""
      }`}
    >
      {props.children}
    </TableContainer>
  ));
  Scroller.displayName = "Scroller";

  // Wrapper for TableRow to handle styling based on 'seperateRows'
  const CustomTableRow = React.forwardRef<
    HTMLTableRowElement,
    MuiTableRowProps
  >((props, ref) => (
    <MuiTableRow
      {...props}
      ref={ref}
      sx={
        seperateRows
          ? {
              "& > *": { background: "white" },
            }
          : {}
      }
    />
  ));
  CustomTableRow.displayName = "TableRow";

  // Wrapper for Table to handle additional styles if needed
  const CustomTable: React.FC<MuiTableProps> = (props) => (
    <Table
      {...props}
      sx={{
        borderCollapse: "separate",
        tableLayout: "fixed",
        position: "relative",
        ...props.sx,
      }}
    />
  );

  // Wrapper for TableFooter to handle context and display loading or messages
  const CustomTableFooter = React.forwardRef<
    HTMLTableSectionElement,
    TableFootProps
  >((props, ref) => {
    const {
      context: {
        tableCellCount,
        isFetching = false,
        isUninitialized = false,
      } = {},
    } = props;

    // Return null if no fetching, uninitialized state, or display text
    if (!isFetching && !isUninitialized) return null;

    return (
      <MuiTableFooter ref={ref} sx={{ position: "unset" }}>
        <CustomTableRow>
          <TableCell
            colSpan={tableCellCount}
            align="center"
            sx={{
              background: "white",
              borderBottom: 0,
            }}
          >
            {isFetching || isUninitialized ? (
              <CircularProgress size={24} />
            ) : (
              <span>No Records Found</span>
            )}
          </TableCell>
        </CustomTableRow>
      </MuiTableFooter>
    );
  });
  CustomTableFooter.displayName = "CustomTableFooter";

  // Custom TableBody Component with conditional styling
  const CustomTableBodyComponent = React.forwardRef<
    HTMLTableSectionElement,
    React.HTMLAttributes<HTMLTableSectionElement>
  >((props, ref) => (
    <MuiTableBody
      {...props}
      ref={ref}
      sx={
        highlightEvenRows
          ? {
              "& > *:nth-of-type(odd)": { background: "#FAFAFB" },
              "& > *:nth-of-type(even)": { background: "white" },
            }
          : {}
      }
    />
  ));
  CustomTableBodyComponent.displayName = "TableBody";

  const CustomFooter = React.forwardRef<HTMLDivElement, TableFootProps>(
    (props, ref) => {
      const { context: { isFetching = false, isUninitialized = false } = {} } =
        props;

      if (!isFetching && !isUninitialized) return null;
      return (
        <div
          ref={ref}
          style={{
            padding: "2rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {isFetching || isUninitialized ? (
            <CircularProgress size={24} />
          ) : (
            <span>No Records Found</span>
          )}
        </div>
      );
    }
  );

  CustomFooter.displayName = "CustomFooter";

  return {
    Scroller,
    Table: CustomTable,
    TableHead: CustomTableHead,
    TableRow: CustomTableRow,
    TableBody: CustomTableBodyComponent,
    // TableFoot: CustomTableFooter,
    TableFoot: CustomTableFooter,
    Footer: CustomFooter,
  };
};

export default AllTableComponents;
