import { createSlice } from "@reduxjs/toolkit";
import { ManageDataSource } from "./type";

// SAGA FLOW EXAMPLE FOR FUTURE API INTEGRATION

export const initialState: ManageDataSource = {
  isLoading: { key: "all", value: true },
  dataSource: {},
};

const manageDataSource = createSlice({
  name: "manageDataSourceState",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setDataSource(state, action) {
      state.dataSource = action.payload;
    },
  },
});

export const {
  actions: ManageDataSourceReducerAction,
  name: ManageDataSourceReducerName,
  reducer: ManageDataSourceReducer,
} = manageDataSource;
