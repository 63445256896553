import {
  Box,
  Button,
  Fade,
  IconButton,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { FilterIcon } from "../Icons";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { sortByFilterOptions } from "../../utils/appConstants";
import { FormProvider, UseFormReturn } from "react-hook-form";
import StandardSelect from "../formControls/Select";
import { AdCopiesFilterType } from "../../types/adCopiesFilterTypes";
import DateRangePicker from "../common/DateRangePicker";

// ** Types **
interface FilterOption {
  id: number;
  custom_filter_id: number;
  value: string;
}

export interface FilterCategory {
  id: number;
  name: string;
  status: number;
  custom_filter_values: FilterOption[];
}

const AdCopiesHeader = ({
  adCopiesFiltersForm,
  clearFilters,
  applyFilters,
  open,
  setOpen,
  dropdown,
}: {
  adCopiesFiltersForm: UseFormReturn<AdCopiesFilterType>;
  clearFilters: () => void;
  applyFilters: () => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  dropdown: FilterCategory[];
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <Box className="!border !border-primary-blue-100 !rounded-[12px] bg-white p-2 pl-4 py-2 flex justify-between items-center gap-5 lg:gap-0">
      <Typography className="!text-xl !font-medium !leading-6 !text-primary-black-950">
        Ad Copies
      </Typography>
      <Box className="flex-column gap-3 items-end w-fit">
        {/* Popper for Filters */}
        <Popper
          sx={{ zIndex: 99 }}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-end"
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper className="min-w-[300px]">
                <Box className="flex items-center justify-between p-4 pb-0">
                  <Typography className="!font-medium !text-[16px] !text-black">
                    Filters
                  </Typography>
                  <Box className="flex gap-4 items-center">
                    <Link
                      to=""
                      className="font-medium !text-[14px] !underline !decoration-solid !text-primary-blue-800"
                      onClick={clearFilters}
                    >
                      Clear All
                    </Link>
                    <Button
                      sx={{
                        paddingX: "20px",
                        paddingY: "5px",
                        borderRadius: "6px",
                        border: "1px solid #E9ECF2",
                        gap: "10px",
                        backgroundColor: "#20407D",
                      }}
                      onClick={applyFilters}
                    >
                      <Typography className="!text-sm !font-normal !capitalize !text-white">
                        Apply
                      </Typography>
                    </Button>
                  </Box>
                </Box>

                {/* Dynamic Filters */}
                <FormProvider {...adCopiesFiltersForm}>
                  <Box className="flex flex-col gap-5 p-4">
                    {/* Static Date Filter */}

                    {dropdown.map((filter, index) => (
                      <React.Fragment key={index}>
                        {index === 0 ? (
                          <>
                            <DateRangePicker
                              control={adCopiesFiltersForm.control}
                              name="date_range"
                            />
                            <StandardSelect
                              label=""
                              options={sortByFilterOptions}
                              control={adCopiesFiltersForm.control}
                              name={"sort_by_date" as keyof AdCopiesFilterType}
                              placeholder="Sort By : Assending by Date"
                              isSingleSelect={true}
                              style={{ width: "100%" }}
                            />
                          </>
                        ) : null}
                        <StandardSelect
                          key={filter.id}
                          label=""
                          options={filter.custom_filter_values.map((item) => ({
                            value: item.value,
                            label: item.value,
                          }))}
                          control={adCopiesFiltersForm.control}
                          name={
                            filter.name
                              .toLowerCase()
                              .replace(/\s/g, "_") as keyof AdCopiesFilterType
                          }
                          placeholder={filter.name}
                          isSingleSelect={false}
                        />
                      </React.Fragment>
                    ))}
                  </Box>
                </FormProvider>
              </Paper>
            </Fade>
          )}
        </Popper>

        {/* Filter Button */}
        <IconButton
          sx={{
            paddingX: "12px",
            paddingY: "6px",
            borderRadius: "8px",
            border: "1px solid #E9ECF2",
            gap: "10px",
            backgroundColor: "#f4f5f8",
          }}
          onClick={handleClick}
        >
          {FilterIcon}
          <Typography className="!font-normal !text-primary-black-950">
            Filters
          </Typography>
        </IconButton>
      </Box>
    </Box>
  );
};

export default AdCopiesHeader;
