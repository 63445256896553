import { RouterProvider } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  DashboardReducer,
  DashboardReducerName,
} from "./store/dashboard/slice";
import { DashboardSaga, DashboardSagaName } from "./store/dashboard/sagas";
import router from "./routes";
import { AuthProvider } from "./context/AuthContext";
import { DrawerReducer, DrawerReducerName } from "./store/drawer/slice";
import { ReportReducer, ReportReducerName } from "./store/report/slice";
import { createTheme, ThemeProvider } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import {
  CampaignsReducer,
  CampaignsReducerName,
} from "./store/campaigns/slice";
import { CampaignsSaga, CampaignsSagaName } from "./store/campaigns/sagas";
import { ReportsSaga, ReportsSagaName } from "./store/report/sagas";
import {
  ClientReducer,
  ClientReducerName,
} from "./store/client-dropdown/slice";
import { ClientSaga, ClientSagaName } from "./store/client-dropdown/saga";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import {
  ConversionsReducer,
  ConversionsReducerName,
} from "./store/conversions/slice";
import { ConversionsSaga, ConversionsSagaName } from "./store/conversions/saga";
import {
  BreadcrumbReducer,
  BreadcrumbReducerName,
} from "./store/breadcrumb/slice";
import { BreadcrumbSaga, BreadcrumbSagaName } from "./store/breadcrumb/saga";

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
});

function App() {
  // Injecting the Reducers
  useInjectReducer({
    reducer: DashboardReducer,
    key: DashboardReducerName,
  });

  useInjectReducer({
    reducer: DrawerReducer,
    key: DrawerReducerName,
  });

  useInjectReducer({
    reducer: ReportReducer,
    key: ReportReducerName,
  });

  useInjectReducer({
    reducer: CampaignsReducer,
    key: CampaignsReducerName,
  });

  useInjectReducer({
    reducer: ConversionsReducer,
    key: ConversionsReducerName,
  });

  useInjectReducer({
    reducer: ClientReducer,
    key: ClientReducerName,
  });

  useInjectReducer({
    reducer: BreadcrumbReducer,
    key: BreadcrumbReducerName,
  });

  // Injecting the Sagas
  useInjectSaga({
    saga: DashboardSaga,
    key: DashboardSagaName,
  });

  useInjectSaga({
    saga: CampaignsSaga,
    key: CampaignsSagaName,
  });

  useInjectSaga({
    saga: ConversionsSaga,
    key: ConversionsSagaName,
  });

  useInjectSaga({
    saga: ReportsSaga,
    key: ReportsSagaName,
  });

  useInjectSaga({
    saga: ClientSaga,
    key: ClientSagaName,
  });

  useInjectSaga({
    saga: BreadcrumbSaga,
    key: BreadcrumbSagaName,
  });

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        style={{ zIndex: 9999 }}
      />
    </AuthProvider>
  );
}

export default App;
