import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { dataSourceList } from "../utils/data";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  ManageDataSourceReducer,
  ManageDataSourceReducerName,
} from "../store/manage-data-source/slice";
import {
  ManageDataSourceSaga,
  ManageDataSourceSagaAction,
  ManageDataSourceSagaName,
} from "../store/manage-data-source/sagas";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  dataSourceSelector,
  isLoadingSelector,
} from "../store/manage-data-source/selector";
import { DataSourceEnum } from "../store/manage-data-source/type";
import DeleteModal from "../components/common/DeleteModal";

const ManageDataSource = () => {
  const [isOpenDisconnectModal, setIsOpenDisconnectModal] =
    useState<string>("");
  const dispatch = useDispatch();

  useInjectReducer({
    reducer: ManageDataSourceReducer,
    key: ManageDataSourceReducerName,
  });
  // Injecting the Sagas
  useInjectSaga({
    saga: ManageDataSourceSaga,
    key: ManageDataSourceSagaName,
  });

  useEffect(() => {
    dispatch(ManageDataSourceSagaAction.getDataSource());
  }, []);

  const dataSource = useSelector(dataSourceSelector);
  const isLoadingDataSource = useSelector(isLoadingSelector);

  const handleOnConnect = (type: string) => {
    dispatch(ManageDataSourceSagaAction.connectDataSource(type));
  };

  const handleOnDisconnect = (type: string) => {
    dispatch(ManageDataSourceSagaAction.disconnectDataSource(type));
    setIsOpenDisconnectModal("");
  };

  return (
    <Box className=" p-5 lg:px-[50px] lg:py-[30px] flex-column gap-[30px] bg-white rounded-[20px]">
      <Typography className="!text-base !text-primary-black-600">
        Connect the platform profiles with access to the ad account linked with
        TrailPartners. This ensures full data access and faster report loads.
      </Typography>
      <Box className="grid grid-cols-1 md:grid-cols-3 gap-10 lg:gap-[60px] items-center">
        {dataSourceList.map((item) => (
          <Box
            key={item.name}
            className="flex-column rounded-[20px] gap-[50px] items-center border border-primary-blue-100 py-10 px-20 lg:py-[60px] lg:px-[100px]"
          >
            <Box className="border-2 rounded-full border-primary-blue-100 flex-center w-[90px] h-[90px]">
              {item.icon()}
            </Box>
            <Typography className="!text-center !text-xl !font-medium !text-primary-black-950">
              {item.name}
            </Typography>
            <Box>
              <Button
                loadingIndicator={
                  <CircularProgress style={{ color: "white" }} size={16} />
                }
                loading={
                  isLoadingDataSource.key === "all" ||
                  (isLoadingDataSource.key === item.key &&
                    isLoadingDataSource.value)
                }
                disabled={item.key === DataSourceEnum.Tiktok}
                //TODO: Remove when TikTok is enabled
                onClick={() => {
                  if (dataSource[item.key]) {
                    setIsOpenDisconnectModal(item.key);
                  } else {
                    handleOnConnect(item.key);
                  }
                }}
                className={`!px-5 !py-2 !capitalize !rounded-lg !text-base !font-medium !min-w-32 !min-h-10 ${
                  dataSource[item.key]
                    ? "!bg-primary-red-50 !border-2 !border-primary-red-50 !text-primary-red-800"
                    : "!bg-primary-blue-950 !text-white"
                }`}
              >
                {isLoadingDataSource.key === "all" ||
                (isLoadingDataSource.key === item.key &&
                  isLoadingDataSource.value)
                  ? null
                  : dataSource[item.key]
                  ? "Disconnect"
                  : "Connect"}
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
      <DeleteModal
        title="Are you sure you want to disconnect?"
        description="Once disconnected, you’ll lose access until you reconnect."
        width={"550px"}
        deleteButtonContent="Disconnect"
        open={!!isOpenDisconnectModal}
        isLoading={
          isLoadingDataSource.key === isOpenDisconnectModal &&
          isLoadingDataSource.value
        }
        onClose={() => setIsOpenDisconnectModal("")}
        onConfirm={() => handleOnDisconnect(isOpenDisconnectModal)}
      />
    </Box>
  );
};

export default ManageDataSource;
