import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { GalleryIcon, RedirectIcon } from "../Icons";
import { useState } from "react";
import AdInsightModal from "../dashboard/AdInsightModal";
import { DoubleArrowIcon } from "../../assets/CustomIcons";
import { Link } from "react-router-dom";
import {
  AccordianPlatformType,
  adCopiesAccordian,
} from "../../utils/appConstants";
import { InsightDataProps } from "../../types/dashboardTypes";

const AdCopiesCard = ({ adCopy }: any) => {
  const [isAdInsightModalOpen, setIsAdInsightModalOpen] =
    useState<boolean>(false);
  const [adCopiesInsightData, setAdCopiesInsightData] = useState<
    InsightDataProps[]
  >([]);

  const handleModalClose = () => setIsAdInsightModalOpen(false);

  const prepareCopiesInsightData = () => {
    return [
      {
        title: "Avg. Conversions on Ad Spend",
        amount: adCopy?.default_insights.conversions || 0,
      },
      {
        title: "Avg. Spend",
        amount: `$${adCopy?.default_insights.spend || 0}`,
      },
      {
        title: "Avg. CPA",
        amount: adCopy?.default_insights.cost_per_conversion || 0,
      },
      {
        title: "Avg. CPC ( All )",
        amount: `$${adCopy?.default_insights?.cost_per_conversion}` || 0,
      },
      {
        title: "Avg. CTR ( Outbound )",
        amount: adCopy?.default_insights.ctr || 0,
      },
      {
        title: "Avg. Click",
        amount: adCopy?.default_insights.clicks || 0,
      },
    ];
  };

  return (
    <Box className="flex-column p-4 gap-5 rounded-xl border border-primary-blue-100 bg-white">
      <Box className="flex-between-center">
        <Typography className="!text-lg !font-medium !truncate !w-[18rem]">
          {adCopy?.ad_title}
        </Typography>
        <RedirectIcon />
      </Box>
      <Box className="relative">
        <img
          src={adCopy?.ad_image_url ?? require("../../assets/adSample.png")}
          alt="Ad thumbnail"
          className="w-[330px] h-[398px] rounded-[10px]"
        />
        <Box
          sx={{ background: "rgba(0, 0, 0, 0.40)" }}
          className="z-0 lg:z-10 flex-center cursor-pointer p-[7px] rounded absolute bottom-[15px] right-[15px]"
          onClick={() => {
            setIsAdInsightModalOpen(true);
            const data = prepareCopiesInsightData();
            setAdCopiesInsightData(data);
          }}
        >
          <GalleryIcon />
        </Box>
      </Box>
      <Box>
        <Accordion className="!shadow-none !pt-[15px] !pb-2.5">
          <AccordionSummary
            className="!p-0"
            expandIcon={
              <Box className="w-[34px] h-[34px] rounded-full border border-primary-blue-100 flex-center">
                <ExpandLessIcon fontSize="medium" className="rotate-180" />
              </Box>
            }
          >
            <Box className="flex gap-2.5 items-center">
              {adCopy?.account_app?.provider_name &&
                adCopiesAccordian[
                  adCopy?.account_app?.provider_name as AccordianPlatformType
                ]}
              <Typography className="!text-lg !font-medium !leading-6 !text-primary-blue-950 !capitalize">
                {adCopy?.account_app.provider_name}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails className="!p-0 flex flex-col gap-2">
            <Box className="flex flex-col !gap-5">
              <Box className="flex justify-between items-center w-full">
                <Typography className="!text-primary-black-600 !text-base">
                  Conversions
                </Typography>
                <Box className="!px-2 !bg-primary-blue-50 !border !border-primary-blue-200 !rounded-[5px] !text-lg !text-primary-blue-950 font-medium">
                  {adCopy?.default_insights?.conversions}
                </Box>
              </Box>
              <Box className="flex justify-between items-center w-full">
                <Typography className="!text-primary-black-600 !text-base">
                  Spend
                </Typography>
                <Box className="!px-2 !bg-primary-blue-50 !border !border-primary-blue-200 !rounded-[5px] !text-lg !text-primary-blue-950 font-medium">
                  ${adCopy?.default_insights?.spend}
                </Box>
              </Box>
            </Box>
            <Box className="flex gap-2 items-center">
              <Link
                to={""}
                className="!text-[14px] cursor-pointer !font-medium !underline !text-primary-blue-800"
                onClick={() => {
                  setIsAdInsightModalOpen(true);
                  const data = prepareCopiesInsightData();
                  setAdCopiesInsightData(data);
                }}
              >
                See All Results
              </Link>
              <DoubleArrowIcon />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      <AdInsightModal
        handleModalClose={handleModalClose}
        isAdInsightModalOpen={isAdInsightModalOpen}
        insightData={adCopiesInsightData}
        title={adCopy?.ad_title ?? ""}
        image={adCopy?.ad_image_url ?? require("../../assets/adSample.png")}
        platform={[adCopy?.account_app.provider_name]}
      />
    </Box>
  );
};

export default AdCopiesCard;
