import { Modal, Box, Typography, Button } from "@mui/material";
import { Warning } from "@mui/icons-material";

interface DeleteModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  description?: string;
  isLoading?: boolean;
  width?: string;
  deleteButtonContent?: string;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  open,
  onClose,
  onConfirm,
  title = "Are you sure?",
  description = "You won't be able to revert this!",
  isLoading = true,
  width = "450px",
  deleteButtonContent = "Delete",
}) => {
  return (
    <Modal open={open} onClose={onClose} className="flex-center">
      <Box
        className={`bg-white md:w-[${width}] p-6 rounded-xl shadow-lg flex flex-col items-center gap-4`}
      >
        <Warning className="text-yellow-500 !text-6xl" color="error" />
        <Typography className="!text-xl !font-medium">{title}</Typography>
        <Typography className="text-gray-600 text-center">
          {description}
        </Typography>
        <Box className="flex gap-4 mt-4">
          <Button
            loading={isLoading}
            disabled={isLoading}
            onClick={onConfirm}
            variant="contained"
            color="error"
            className="!px-6 !py-2"
          >
            {deleteButtonContent}
          </Button>
          <Button
            onClick={onClose}
            className="!px-6 !py-2 !text-primary-blue-800"
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
