import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./slice";

const selectDomain = (state: any) => state.reportState || initialState;

export const reportListSelector = createSelector(
  selectDomain,
  (state) => state.reportList
);

export const recommendedReportListSelector = createSelector(
  selectDomain,
  (state) => state.recommendedReportList
);

export const reportViewSelector = createSelector(
  selectDomain,
  (state) => state.reportDetailsList
);

export const reportDetailsSelector = createSelector(
  selectDomain,
  (state) => state.reportDetails
);

export const isReportLoadingSelector = createSelector(
  selectDomain,
  (state) => state.isLoading
);

export const downloadReportUrlSelector = createSelector(
  selectDomain,
  (state) => state.downloadUrl
);

export const metricsSelector = createSelector(
  selectDomain,
  (state) => state.metrics
);

export const reportViewSkipSelector = createSelector(
  selectDomain,
  (state) => state.reportViewSkip
);

export const reportsListSkipSelector = createSelector(
  selectDomain,
  (state) => state.reportSkip
);

export const recommendedReportSkipSelector = createSelector(
  selectDomain,
  (state) => state.recommendedReportSkip
);