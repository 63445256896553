import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./slice";

// SAGA FLOW EXAMPLE FOR FUTURE API INTEGRATION

const selectDomain = (state: any) => state.campaignsState || initialState;

// Step-4: Making the state available for the files to be called through
export const campaignsSelector = createSelector(
  selectDomain,
  (state) => state.campaigns
);
export const isCampaignsLoadingSelector = createSelector(
  selectDomain,
  (state) => state.isLoading
);
export const campaignsFilterSelector = createSelector(
  selectDomain,
  (state) => state.filter
);
export const campaignsSkipSelector = createSelector(
  selectDomain,
  (state) => state.skip
);

export const campaignsSearchSelector = createSelector(
  selectDomain,
  (state) => state.search
);
