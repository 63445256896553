import { Box } from "@mui/material";
import React from "react";
import CustomTextField from "../../formControls/CustomTextField";
import { RendererType } from "../../../types/enums/enums";
import { UseFormReturn } from "react-hook-form";
import SaveAndGenerateCardHeader from "../../common/SaveAndGenerateCardHeader";
import { ReportType } from "../../../types/reportTypes";
import { format } from "date-fns";
import { DATE_PICKER_FORMAT } from "../../../utils/appConstants";

const SelectedDateRangeCard = ({
  reportStepperForm,
  setStepper,
  handlePreviousSection,
}: {
  reportStepperForm: UseFormReturn<ReportType>;
  setStepper: React.Dispatch<React.SetStateAction<number>>;
  handlePreviousSection: () => void;
}) => {
  const {
    formState: { errors },
    control,
    watch,
  } = reportStepperForm;

  const startDate = watch("start_date");
  const endDate = watch("end_date");

  const formattedStartDate = startDate
    ? format(new Date(startDate), DATE_PICKER_FORMAT)
    : "";
  const formattedEndDate = endDate
    ? format(new Date(endDate), DATE_PICKER_FORMAT)
    : "";

  return (
    <Box className="border p-[30px] border-primary-blue-100 rounded-[20px] flex-column gap-[30px]">
      {/* Header  */}
      <SaveAndGenerateCardHeader
        title="Selected Date Range"
        setStepper={setStepper}
        step={3}
        handlePreviousSection={handlePreviousSection}
      />

      <Box className="flex-column md:flex-row gap-[25px]">
        <Box className="w-full">
          <CustomTextField
            label="Start Date"
            name="start_date"
            placeholder="DD/MM/YYYY"
            type={RendererType.date}
            control={control}
            errors={errors.start_date?.message?.toString()}
            rules={{
              required: "First Name is Required",
            }}
            value={formattedStartDate}
            editable={false}
          />
        </Box>
        <Box className="w-full">
          <CustomTextField
            label="End Date"
            name="end_date"
            placeholder="DD/MM/YYYY"
            type={RendererType.date}
            control={control}
            errors={errors.end_date?.message?.toString()}
            rules={{
              required: "First Name is Required",
            }}
            value={formattedEndDate}
            editable={false}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SelectedDateRangeCard;
