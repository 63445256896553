import { createSliceSaga } from "../saga-helper";
import { call, put, select } from "redux-saga/effects";
import { ReportReducerAction } from "./slice";
import { AxiosResponse } from "axios";
import {
  deleteReportService,
  ExportReportService,
  GetRecommendedReportsListService,
  GetReportDetailsService,
  GetReportsListService,
  MetricsService,
  ReportService,
  ViewReportService,
} from "../../services/ReportService";
import { format } from "date-fns";
import { DATE_FORMAT } from "../../utils/appConstants";
import {
  recommendedReportListSelector,
  reportListSelector,
  reportViewSelector,
} from "./selector";
import {
  CampaignIdsType,
  PlatformType,
  ReportsListType,
  ReportsViewType,
} from "../../types/reportTypes";
import { toast } from "react-toastify";

// SAGA FLOW EXAMPLE FOR FUTURE API INTEGRATION

const saga = createSliceSaga({
  name: "reports-saga-state",
  caseSagas: {
    *postReport(action: {
      payload: { transformedData: any; resolve: () => void };
    }) {
      const {
        report_id,
        end_date,
        campaignIds,
        metrics,
        platforms,
        report_name,
        start_date,
      } = action.payload.transformedData;

      const formData = new FormData();
      if (report_id) {
        formData.append("report_id", report_id);
      }

      platforms?.forEach((platform: PlatformType) => {
        formData.append("platform[]", platform?.name?.toLowerCase());
      });

      campaignIds?.forEach((campaign: CampaignIdsType) => {
        formData.append("campaign_id[]", campaign?.id);
      });
      metrics?.forEach((metricName: string) => {
        formData.append("fields[]", metricName);
      });
      formData.append("report_name", report_name);
      formData.append("start_date", format(start_date, DATE_FORMAT));
      formData.append("end_date", format(end_date, DATE_FORMAT));

      try {
        yield put(ReportReducerAction.setIsLoading(true));

        const { status }: AxiosResponse = yield call(ReportService, formData);

        if (status === 200) {
          yield put(ReportReducerAction.setReportListSkip(0));
        }
      } catch (error) {
        console.error("Error generating a report.");
      } finally {
        if (action.payload.resolve) {
          action.payload.resolve();
          yield put(ReportReducerAction.setIsLoading(false));
        }
      }
    },
    *getReportsList(action: {
      payload: {
        skip: number;
        limit: number;
      };
    }) {
      try {
        const reportList: { data: ReportsListType[]; total: number } =
          yield select(reportListSelector);
        yield put(ReportReducerAction.setIsLoading(true));

        const { data: apiResponse, status }: AxiosResponse = yield call(
          GetReportsListService,
          action.payload
        );

        if (status === 200) {
          let responseData = apiResponse?.data?.reports;
          if (reportList?.data?.length > 0 && responseData?.length > 0)
            responseData = reportList?.data.concat(responseData);
          yield put(
            ReportReducerAction.setReportList({
              data: responseData,
              total: apiResponse?.data?.total,
            })
          );
        }
      } catch (error) {
        console.error("Error generating a report.");
      } finally {
        yield put(ReportReducerAction.setIsLoading(false));
      }
    },
    *getRecommendedReportsList(action: {
      payload: {
        skip: number;
        limit: number;
        resolve: () => void;
      };
    }) {
      try {
        const recommendedReportList: {
          data: [];
          total: number;
        } = yield select(recommendedReportListSelector);
        yield put(ReportReducerAction.setIsLoading(true));

        const { data: apiResponse, status }: AxiosResponse = yield call(
          GetRecommendedReportsListService,
          action.payload
        );

        if (status === 200) {
          let responseData = apiResponse?.data?.reports;
          if (
            recommendedReportList?.data?.length > 0 &&
            responseData?.length > 0
          )
            responseData = recommendedReportList?.data.concat(responseData);
          yield put(
            ReportReducerAction.setRecommendedList({
              data: responseData,
              total: apiResponse?.data?.total,
            })
          );
        }
      } catch (error) {
        console.error("Error generating a report.");
      } finally {
        if (action.payload.resolve) {
          action.payload.resolve();
        }
      }
    },
    *getReportView(action: {
      payload: {
        report_id: number;
        skip: number;
        limit: number;
      };
    }) {
      try {
        const reportViewList: { data: ReportsViewType[]; total: number } =
          yield select(reportViewSelector);
        yield put(ReportReducerAction.setIsLoading(true));

        const { data: apiResponse, status }: AxiosResponse = yield call(
          ViewReportService,
          action.payload
        );

        if (status === 200) {
          let responseData = apiResponse.data?.reports;
          if (reportViewList?.data?.length > 0 && responseData.length > 0)
            responseData = [...reportViewList?.data, ...responseData];
          yield put(
            ReportReducerAction.setReportView({
              data: responseData,
              total: apiResponse?.data?.total,
            })
          );
        }
      } catch (error) {
        console.error("Error generating a report.");
      } finally {
        yield put(ReportReducerAction.setIsLoading(false));
      }
    },
    *getExportReport(action: {
      payload: {
        report_id: number;
        skip: number;
        limit: number;
      };
    }) {
      try {
        yield put(ReportReducerAction.setIsLoading(true));

        const { data: apiResponse, status }: AxiosResponse = yield call(
          ExportReportService,
          action.payload
        );

        if (status === 200) {
          const exportReport = apiResponse.data;

          yield put(
            ReportReducerAction.setDownloadReportUrl(exportReport.downloadUrl)
          );
        }
      } catch (error) {
        console.error("Error exporting a report.");
      } finally {
        yield put(ReportReducerAction.setIsLoading(false));
      }
    },
    *getMetrics() {
      try {
        yield put(ReportReducerAction.setIsLoading(true));

        const { data, status }: AxiosResponse = yield call(MetricsService);
        if (status === 200) {
          yield put(ReportReducerAction.setMetrics(data?.data));
        }
      } catch (error) {
        console.error("Error fetching mterics");
      } finally {
        yield put(ReportReducerAction.setIsLoading(false));
      }
    },
    *deleteReport(action: { payload: { id: string; resolve: () => void } }) {
      try {
        const { status, data }: AxiosResponse = yield call(
          deleteReportService,
          action.payload.id
        );

        if (status === 200) {
          const reportList: { data: ReportsListType[]; total: number } =
            yield select(reportListSelector);

          const updatedData = reportList.data.filter(
            (item) => item.id !== Number(action.payload.id)
          );
          yield put(
            ReportReducerAction.setReportList({
              total: reportList.total - 1,
              data: updatedData,
            })
          );
          toast.success(data.message);
        }
      } catch (error) {
        console.error("Error deleting conversion!");
      } finally {
        if (action.payload.resolve) {
          action.payload.resolve();
        }
      }
    },
    *getReportDetails(action: {
      payload: {
        id: string;
      };
    }) {
      try {
        yield put(ReportReducerAction.setIsLoading(true));

        const { data: apiResponse, status }: AxiosResponse = yield call(
          GetReportDetailsService,
          action.payload
        );

        if (status === 200) {
          yield put(ReportReducerAction.setReportDetails(apiResponse?.data.report));
        }
      } catch (error) {
        console.error("Error generating a report.");
      } finally {
        yield put(ReportReducerAction.setIsLoading(false));
      }
    },
  },
  sagaType: "takeEvery",
});

export const {
  actions: ReportsSagaAction,
  saga: ReportsSaga,
  name: ReportsSagaName,
} = saga;
