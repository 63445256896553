import axiosClient from "../apiClient";
import { FeedBackFormType } from "../types/forms";

export const FeedbackService = async (params: FeedBackFormType) => {
  try {
    const response = await axiosClient.post("/feedback", params);
    return response.data;
  } catch (error) {
    return error;
  }
};
