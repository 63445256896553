import { Box, Divider, LinearProgress, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { dashboardSelector } from "../../../../store/dashboard/selector";
import { DashboardState } from "../../../../store/dashboard/types";
import { adVisitorDeviceData } from "../../../../utils/data";

export interface AdVisitorsAcrossDevices {
  mobile_phones: string;
  tablets: string;
  computers: string;
}

const AdVisitorDevices = () => {
  const { ad_visitors_across_devices }: DashboardState =
    useSelector(dashboardSelector);

  // Convert API response to match UI data structure
  const transformAdVisitorsData = (data: AdVisitorsAcrossDevices) => {
    return adVisitorDeviceData.map((device) => {
      // Create a mapping between frontend names and API keys
      const apiKeyMap: Record<string, keyof AdVisitorsAcrossDevices> = {
        "Mobile Phones": "mobile_phones",
        Tablets: "tablets",
        Computers: "computers",
      };

      const apiKey = apiKeyMap[device.name] || "";
      return {
        ...device,
        percentage: apiKey ? parseFloat(data[apiKey] || "0") : 0,
      };
    });
  };

  const formattedData = transformAdVisitorsData(
    ad_visitors_across_devices && typeof ad_visitors_across_devices === "object"
      ? (ad_visitors_across_devices as unknown as AdVisitorsAcrossDevices)
      : { mobile_phones: "0", tablets: "0", computers: "0" }
  );

  return (
    <Box className="w-[325px] md:w-full p-5 flex-column gap-[30px] border-t border-t-primary-blue-100">
      {/* Header Section */}
      <Box className="flex gap-[30px] flex-col lg:flex-row">
        {formattedData.map((device, index) => (
          <Box className="flex gap-[30px]" key={device.name}>
            <Box className="flex gap-2.5 items-center">
              {device.icon()}
              <Typography className="!text-base !text-primary-black-600">
                {device.name}
              </Typography>
            </Box>
            {index !== formattedData.length - 1 && (
              <Divider orientation="vertical" className="w-1" />
            )}
          </Box>
        ))}
      </Box>

      {/* Progress Bars Section */}
      <Box className="flex-column gap-5">
        {formattedData.map((device) => (
          <Box key={device.name} className="flex-column gap-2.5">
            <LinearProgress
              sx={{
                "& .MuiLinearProgress-bar": {
                  borderRadius: "10px",
                  backgroundColor: device.color,
                },
              }}
              variant="determinate"
              value={device.percentage}
              className="!h-[14px] !bg-primary-blue-50 !rounded-md !rounded-l-none"
            />
            <Box className="flex gap-2.5 items-center">
              {device.icon()}
              <Typography className="!text-base !font-semibold">
                {device.percentage}%
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default AdVisitorDevices;
