import { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import {
  deleteConversationFormFieldService,
  getFormDetails,
  getPatientList,
  getPlateFormFields,
  saveFormForCampaignsService,
} from "../../services/ConversionsService";
import { useNavigate, useParams } from "react-router-dom";
import routeConstants from "../../utils/routeConstants";
import AddUpdateForm from "./AddUpdateForm";
import { useDispatch } from "react-redux";
import { BreadcrumbSagaAction } from "../../store/breadcrumb/saga";
import { Box, CircularProgress } from "@mui/material";
import DeleteModal from "../common/DeleteModal";

interface FormValues {
  form_id?: string;
  form_url: string;
  form_name: string;
  patient_id: string;
  mappings: {
    id: string;
    platform_field: string;
    form_field: string;
    form_field_id?: string;
  }[];
}

const EditFormContainer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFetchLoading, setIsFetchLoading] = useState<boolean>(true);
  const [patients, setPatients] = useState<{ label: string; value: string }[]>(
    []
  );
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<string>("");
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const [plateFormFields, setPlateFormFields] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    if (id) {
      fetchFormData(id);
    }
  }, [id]);

  useEffect(() => {
    fetchPatients();
    fetchPlateFormFields();

    dispatch(
      BreadcrumbSagaAction.updateBreadcrumbs({
        path: routeConstants.conversionAccountDetails,
        dynamicName: "Edit Form",
      })
    );
  }, []);

  const fetchFormData = async (id: string) => {
    try {
      setIsFetchLoading(true);
      const { data } = await getFormDetails(id);

      if (data.length > 0) {
        const formData = data[0];

        // Setting form values
        setValue("form_id", formData.id);
        setValue("form_url", formData.form_url);
        setValue("form_name", formData.name);
        setValue("patient_id", formData.id.toString());

        // Mapping form fields
        const mappedFields = formData.formFields.map((field: any) => ({
          form_field_id: field.id,
          platform_field: field.platform_field,
          form_field: field.form_field,
        }));

        setValue("mappings", mappedFields);
      }
    } catch (err) {
      console.error("Error fetching form data:", err);
    } finally {
      setIsFetchLoading(false);
    }
  };

  const fetchPatients = async () => {
    try {
      const { data } = await getPatientList({});
      setPatients(
        data.map((patient: any) => ({
          label: patient.name,
          value: patient.id.toString(),
        }))
      );
    } catch (err) {
      console.error("Error fetching patients:", err);
    }
  };

  const fetchPlateFormFields = async () => {
    try {
      const data = await getPlateFormFields();
      setPlateFormFields(
        Object.values(data).map((field) => ({
          label: field as string,
          value: field as string,
        }))
      );
    } catch (err) {
      console.error("Error fetching patients:", err);
    }
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      form_url: "",
      form_name: "",
      patient_id: "",
      mappings: [{ platform_field: "", form_field: "", form_field_id: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "mappings",
  });

  const onSubmit = async (data: FormValues) => {
    const formData = new FormData();

    formData.append("patient_id", String(data.patient_id));
    formData.append("name", data.form_name);
    formData.append("form_url", data.form_url);
    if (data.form_id) {
      formData.append("form_id", String(data.form_id));
    }

    data.mappings.forEach((mapping) => {
      formData.append("form_field_id[]", mapping.id);
      formData.append("platform_fields[]", mapping.platform_field);
      formData.append("form_fields[]", mapping.form_field);
    });

    if (data.mappings.some((mapping) => mapping.form_field_id)) {
      data.mappings.forEach((mapping) => {
        if (mapping.form_field_id) {
          formData.append("form_field_id[]", String(mapping.form_field_id));
        }
      });
    }

    try {
      setIsLoading(true);
      const { status, data }: AxiosResponse = await saveFormForCampaignsService(
        formData
      );
      if (status) {
        setValue("form_id", data?.[0]?.id);
        toast.success("Form has been saved successfully!");
        navigate(routeConstants.conversions);
      }
    } catch (error) {
      console.error("Error saving form:", error);
      toast.error("Failed to save form. Please try again!");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteFormField = async (fieldIndex: string) => {
    try {
      const findId = fields[Number(fieldIndex)]?.form_field_id;
      if (findId) {
        setIsDeleteLoading(true);
        const response = await deleteConversationFormFieldService(findId);
        if (response.status === 200) {
          toast.success("Form field has been deleted successfully!");
          remove(Number(fieldIndex));
          setIsDeleteLoading(false);
          setIsOpenDeleteModal("");
        }
      }
    } catch (error) {
      console.error("Error deleting form field:", error);
      toast.error("Failed to delete form field. Please try again!");
    }
  };
  return (
    <>
      {/* Transparent Loader */}
      {isFetchLoading ? (
        <Box className="w-full flex justify-center items-center">
          <Box className="bg-white flex justify-center items-center min-w-[70%] h-[70vh] rounded-[20px] border border-primary-blue-100">
            <CircularProgress color="primary" />
          </Box>
        </Box>
      ) : (
        <AddUpdateForm
          isEdit={true}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          isLoading={isLoading}
          control={control}
          errors={errors}
          patients={patients}
          fields={fields}
          plateFormFields={plateFormFields}
          append={append}
          remove={(fieldIndex) => {
            const findId = fields[Number(fieldIndex)]?.form_field_id;
            if (findId) {
              setIsOpenDeleteModal(String(fieldIndex));
            } else {
              remove(Number(fieldIndex));
            }
          }}
        />
      )}
      <DeleteModal
        open={!!isOpenDeleteModal}
        isLoading={isDeleteLoading}
        onClose={() => setIsOpenDeleteModal("")}
        onConfirm={() => handleDeleteFormField(isOpenDeleteModal)}
      />
    </>
  );
};

export default EditFormContainer;
