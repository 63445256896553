import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Select,
  MenuItem,
  IconButton,
  CircularProgress,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import CustomTextField from "../formControls/CustomTextField";
import { GridSearchIcon } from "@mui/x-data-grid";
import { RendererType } from "../../types/enums/enums";
import { useForm } from "react-hook-form";
import { EyeIconForTable } from "../Icons";
import { useNavigate, useParams } from "react-router-dom";
import routeConstants from "../../utils/routeConstants";
import { getConversationAccountDetailService } from "../../services/ConversionsService";
import { debounce } from "lodash";
import { BreadcrumbSagaAction } from "../../store/breadcrumb/saga";
import { useDispatch } from "react-redux";
import { CampaignsReducerAction } from "../../store/campaigns/slice";
import { CampaignsSagaAction } from "../../store/campaigns/sagas";
import { useSelector } from "react-redux";
import {
  campaignsFilterSelector,
  campaignsSelector,
  campaignsSkipSelector,
  isCampaignsLoadingSelector,
} from "../../store/campaigns/selector";

interface ConversationData {
  id: number;
  email: string | null;
  client_name: string | null;
  first_name: string | null;
  last_name: string | null;
  phone: string | null;
  formatted_created_at?: string;
}

type OptionType = {
  id: string;
  campaign_name: string;
};

const ConversionAccountDetails: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const accountDetailsForm = useForm();
  const [conversationData, setConversationData] = useState<ConversationData[]>(
    []
  );
  const limit = 10;
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [provider, setProvider] = useState<string>("All");
  const [headers, setHeaders] = useState({});
  const [formHeaderData, setFormHeaderData] = useState<{
    name: string;
    url: string;
  }>({
    name: "",
    url: "",
  });

  // Campaigns AutoComplete code
  const campaignsFilter = useSelector(campaignsFilterSelector);
  const campaignsSkip = useSelector(campaignsSkipSelector);
  const campaignList = useSelector(campaignsSelector);
  const isCampaignsLoading = useSelector(isCampaignsLoadingSelector);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([]);

  const fetchData = useCallback(async () => {
    if (!id) return;
    setLoading(true);
    try {
      const response = await getConversationAccountDetailService({
        skip: (page - 1) * limit,
        limit,
        form_id: id,
        search,
        provider: provider === "All" ? "" : provider,
        campaign_ids: selectedOptions.map((option) => option.id),
      });
      setConversationData(response.data.data || []);
      setTotal(response.data.total || 0);
      setHeaders(response.data.header || {});
      setFormHeaderData(response.data["form-data"]);
    } catch (error) {
      console.error("Failed to fetch conversation details", error);
      setConversationData([]);
    } finally {
      setPageLoading(false);
      setLoading(false);
    }
  }, [id, page, search, provider, selectedOptions]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    dispatch(CampaignsReducerAction.setCampaignSkip(0));
    dispatch(CampaignsReducerAction.setCampaigns({ data: [], total: 0 }));
    dispatch(
      CampaignsSagaAction.getCampaigns({
        skip: 0,
        limit: 10,
        search: searchTerm,
      })
    );
  }, [searchTerm, campaignsFilter]);

  useEffect(() => {
    setHasMore(campaignList.data.length < campaignList.total);
  }, [campaignList]);

  useEffect(() => {
    if (formHeaderData.name) {
      dispatch(
        BreadcrumbSagaAction.updateBreadcrumbs({
          path: routeConstants.conversionAccountDetails,
          dynamicName: formHeaderData.name,
        })
      );
    }
  }, [formHeaderData]);

  const debouncedSearch = useCallback(
    debounce((query: string) => {
      setSearch(query);
      setPage(1);
    }, 500),
    []
  );

  const campaingnsDebouncedSearch = useCallback(
    debounce((query: string) => {
      setSearchTerm(query);
    }, 500),
    []
  );

  const onLoadMore = () => {
    if (hasMore && !isCampaignsLoading) {
      const newSkip = campaignsSkip + 10;
      dispatch(CampaignsReducerAction.setCampaignSkip(newSkip));
      dispatch(
        CampaignsSagaAction.getCampaigns({
          skip: newSkip,
          limit: 10,
          search: searchTerm,
        })
      );
    }
  };

  const handleScroll = (event: React.UIEvent<HTMLUListElement>) => {
    const listboxNode = event.currentTarget;
    if (
      listboxNode.scrollTop + listboxNode.clientHeight >=
      listboxNode.scrollHeight - 50
    ) {
      onLoadMore();
    }
  };

  return (
    <Box className="rounded-[20px] bg-white flex-column gap-4">
      {pageLoading ? (
        <Box className="flex justify-center items-center min-h-[70vh]">
          <CircularProgress size={42} />
        </Box>
      ) : formHeaderData.name &&
        formHeaderData.url &&
        Object.keys(headers).length ? (
        <React.Fragment>
          {/* Header */}
          <Box className="flex flex-col gap-[2px] px-[20px] py-[20px] border-b border-b-primary-blue-100">
            <Typography className="!text-xl font-semibold">
              {formHeaderData.name}
            </Typography>
            <Typography
              className="!text-l font-normal text-primary-black-600 cursor-pointer hover:underline"
              onClick={() => window.open(formHeaderData.url, "_blank")}
            >
              {formHeaderData.url}
            </Typography>
          </Box>

          {/* Search and Filters */}
          <Box className="flex justify-between items-center px-[20px]">
            <CustomTextField
              label=""
              name="search"
              icon={<GridSearchIcon />}
              placeholder="Search"
              type={RendererType.search}
              control={accountDetailsForm.control}
              onChange={() => {
                const value = (accountDetailsForm.getValues("search") ||
                  "") as string;
                debouncedSearch(value);
              }}
            />
            <Box className="flex gap-4">
              {/* Provider Dropdown */}
              <Select
                value={provider}
                onChange={(e) => {
                  setProvider(e.target.value);
                  setPage(1);
                }}
                size="small"
                className="!w-[150px]"
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="google">Google Ads</MenuItem>
                <MenuItem value="facebook">Facebook Ads</MenuItem>
              </Select>
              <Box className="w-[300px]">
                <Autocomplete
                  multiple
                  options={campaignList.data} // Make sure it updates correctly
                  getOptionLabel={(option) => option.campaign_name}
                  value={selectedOptions}
                  onChange={(_, newValue) => setSelectedOptions(newValue)}
                  onInputChange={(e, value) => campaingnsDebouncedSearch(value)}
                  disableCloseOnSelect
                  filterOptions={(x) => x} // Ensure all options appear
                  loading={isCampaignsLoading}
                  ListboxProps={{ onScroll: handleScroll }}
                  sx={{
                    ".MuiAutocomplete-inputRoot": {
                      padding: "6px 16px",
                      background: "#fff",
                      color: "#20407D !important", // Apply text color
                      height: "auto",
                      minHeight: "31px !important",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select or Search Campaigns"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isCampaignsLoading ? (
                              <CircularProgress size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
          </Box>

          {/* Table */}
          <Box className="overflow-x-auto">
            <TableContainer className="border border-primary-blue-100">
              <Table className="border-collapse">
                <TableHead>
                  <TableRow className="border border-primary-blue-100">
                    <TableCell
                      className="border border-primary-blue-100 !text-primary-black-600 !font-normal"
                      // padding="checkbox"
                    >
                      #
                      {/* <CustomCheckbox
                  name={`select-all-row`}
                  control={accountDetailsForm.control}
                  className="w-[18px] h-[18px] m-6"
                /> */}
                    </TableCell>
                    {Object.values(headers).map((headerLabel, index) => (
                      <TableCell
                        key={`dynamic-cell-head-${index}`}
                        className="border border-primary-blue-100 !text-primary-black-600 !font-normal"
                      >
                        {String(headerLabel)}
                      </TableCell>
                    ))}
                    <TableCell className="border border-primary-blue-100 !text-primary-black-600 !font-normal" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell
                        colSpan={
                          Object.keys(headers).length > 7
                            ? Object.keys(headers).length
                            : 7
                        }
                        className="text-center"
                      >
                        <Box className="flex justify-center items-center min-h-96">
                          <CircularProgress size={36} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ) : conversationData.length === 0 && !loading ? (
                    <TableRow>
                      <TableCell
                        colSpan={
                          Object.keys(headers).length > 7
                            ? Object.keys(headers).length
                            : 7
                        }
                        className="text-center"
                      >
                        <Box className="flex flex-col justify-center items-center min-h-96">
                          <Box className="text-primary-blue-200">
                            <Search fontSize="large" />
                          </Box>
                          <Typography
                            variant="h6"
                            className="mt-2 text-primary-blue-200"
                          >
                            No data available
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ) : (
                    conversationData.map((row, index) => (
                      <TableRow
                        key={index}
                        className="border border-primary-blue-100"
                      >
                        <TableCell
                          className="border border-primary-blue-100"
                          // padding="checkbox"
                        >
                          {(page - 1) * limit + index + 1}
                          {/* <CustomCheckbox
                      name={`row-${row.id}`}
                      control={accountDetailsForm.control}
                      className="w-[18px] h-[18px] m-6"
                    /> */}
                        </TableCell>
                        {Object.keys(headers).map((key, colIndex) => (
                          <TableCell
                            className="border border-primary-blue-100"
                            key={`dynamic-cell-value-${colIndex}`}
                          >
                            {row[key as keyof ConversationData] ?? "-"}
                          </TableCell>
                        ))}
                        <TableCell className="border border-primary-blue-100 !text-center">
                          <IconButton
                            className="w-10 h-10 !p-0 !m-0 !rounded-lg !bg-primary-blue-50"
                            sx={{ border: "3px solid #E9ECF2" }}
                            onClick={() =>
                              navigate(
                                `${routeConstants.conversionDetails}/${row.id}`
                              )
                            }
                          >
                            {EyeIconForTable}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {/* Pagination */}
          {conversationData.length ? (
            <Box className="flex justify-between items-center p-6">
              {/* Results Text */}
              <Typography className="text-sm">
                Showing {Math.min((page - 1) * limit + 1, total)} to{" "}
                {Math.min(page * limit, total)} of {total} results
              </Typography>
              <Box className="flex items-center space-x-4">
                <Pagination
                  count={Math.ceil(total / limit)}
                  page={page}
                  onChange={(_, value) => setPage(value)}
                  shape="rounded"
                  className="!space-x-2"
                  sx={{
                    "& .MuiPaginationItem-root": {
                      borderRadius: "6px",
                      minWidth: "32px",
                      height: "32px",
                      border: "1px solid #D1D5DB",
                      color: "#374151",
                    },
                    "& .Mui-selected": {
                      backgroundColor: "#20407D !important",
                      color: "#fff",
                      borderColor: "#20407D",
                    },
                    "& .MuiPaginationItem-root:hover": {
                      backgroundColor: "#20407D",
                      color: "#fff",
                    },
                  }}
                />
              </Box>
            </Box>
          ) : null}
        </React.Fragment>
      ) : (
        <Box className="flex flex-col justify-center items-center min-h-96">
          <Box className="text-primary-blue-400">
            <Search fontSize="large" />
          </Box>
          <Typography variant="h6" className="mt-2 text-primary-blue-400">
            No information available for this conversation.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ConversionAccountDetails;
