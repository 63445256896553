import {
  Box,
  Button,
  CircularProgress,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { ExportIcon } from "../../Icons";
import { columns } from "./GeneratedReportTableColumnHeaders";
import { useSelector } from "react-redux";
import {
  downloadReportUrlSelector,
  isReportLoadingSelector,
  reportViewSelector,
  reportViewSkipSelector,
} from "../../../store/report/selector";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ReportsSagaAction } from "../../../store/report/sagas";
import { TableVirtuoso } from "react-virtuoso";
import VirtuosoTableComponents from "../../../components/table/AllTableComponents";
import { ReportReducerAction } from "../../../store/report/slice";
import { useParams } from "react-router-dom";
import CustomButton from "../../common/CustomButton";
import { BreadcrumbSagaAction } from "../../../store/breadcrumb/saga";
import routeConstants from "../../../utils/routeConstants";

const TableComponents = VirtuosoTableComponents({});

const GeneratedReport = () => {
  const dispatch = useDispatch();
  const { id: reportId } = useParams();
  const [tableRows, setTableRows] = useState<any>([]);
  const [hasMore, setHasMore] = useState(true);
  const reportView = useSelector(reportViewSelector);
  const downloadUrl = useSelector(downloadReportUrlSelector);
  const reportViewSkip = useSelector(reportViewSkipSelector);
  const isReportListLoading = useSelector(isReportLoadingSelector);

  const transformReportViewToTableRow = (reportView: any) => {
    return {
      id: reportView.report_id,
      platform: reportView.platform,
      campaign: reportView.campaign_name,
      clicks: reportView?.clicks ? parseInt(reportView.clicks) : 0,
      impression: reportView.Impressions ? parseInt(reportView.Impressions) : 0,
      spend: reportView.spend ? parseFloat(reportView.spend) : 0,
      conversions: reportView.Conversions
        ? parseInt(reportView.Conversions)
        : 0,
      startDate: reportView.start_date,
      endDate: reportView.end_date,
    };
  };

  useEffect(() => {
    const rows = reportView?.data.map((item: any) =>
      transformReportViewToTableRow(item)
    );
    setTableRows(rows);
  }, [reportView]);

  useEffect(() => {
    dispatch(ReportReducerAction.setCampaignSkip(0));
    dispatch(
      ReportsSagaAction.getReportView({
        report_id: reportId,
        skip: 0,
        limit: 10,
      })
    );
    dispatch(
      BreadcrumbSagaAction.updateBreadcrumbs({
        path: routeConstants.generatedReport,
      })
    );
    return () => {
      dispatch(ReportReducerAction.setCampaignSkip(0));
      dispatch(
        ReportReducerAction.setReportView({
          data: [],
          total: 0,
        })
      );
    };
  }, [reportId]);

  useEffect(() => {
    setHasMore(reportView.data.length < reportView.total);
  }, [reportView]);

  const onLoadMore = () => {
    if (hasMore) {
      dispatch(ReportReducerAction.setCampaignSkip(reportViewSkip + 10));
      dispatch(
        ReportsSagaAction.getReportView({
          report_id: reportId,
          skip: reportViewSkip + 10,
          limit: 10,
        })
      );
    }
  };

  const CustomTableRows = ({ row }: { row: any }) => {
    return (
      <>
        {columns.map((column, columnIndex) => (
          <TableCell
            key={columnIndex}
            className="!border border-primary-black-50"
          >
            {row && (
              <TableCell className="!border-none !p-0" key={column.field}>
                {row[column?.field]}
              </TableCell>
            )}
          </TableCell>
        ))}
      </>
    );
  };

  const tblContext = {
    isFetching: isReportListLoading,
    isUninitialized: false,
    currentRowCount: reportView?.data?.length,
    totalRecords: reportView?.data?.total ?? 0,
    tableCellCount: columns.length,
  };

  const fixedHeaderContent = useCallback(
    () => (
      <TableRow className="bg-white">
        {columns.map((col) => (
          <TableCell
            key={col.field}
            className="!border !border-primary-black-50 !text-primary-black-600 !font-normal"
            style={{ width: col.width }}
          >
            {col.headerName}
          </TableCell>
        ))}
      </TableRow>
    ),
    []
  );
  const handleExport = () => {
    dispatch(
      ReportsSagaAction.getExportReport({
        report_id: reportId,
        skip: reportViewSkip,
        limit: 10,
      })
    );
  };

  useEffect(() => {
    if (downloadUrl) {
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "Report.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [downloadUrl]);

  return (
    <Box className="flex justify-center relative">
      <Box className="w-full flex-column bg-white  outline-none rounded-xl">
        {/* Header */}
        <Box className="flex-column p-5 py-2.5 items-start gap-[15px] border-b border-primary-black-50">
          <Box className="flex-between-center w-full">
            <Typography className="!text-xl !font-medium">
              Generated Report
            </Typography>

            <CustomButton
              className="!text-sm lg:!text-base !font-medium text-white"
              onClick={handleExport}
              disabled={!tableRows?.length}
            >
              Export
              <i className="pl-[10px]">{ExportIcon}</i>
            </CustomButton>
          </Box>
        </Box>

        <Box className="rounded-xl pb-5">
          {isReportListLoading && tableRows?.length === 0 ? (
            <Box className="flex flex-col justify-center items-center p-10">
              <CircularProgress size={24} />
              Loading reports...
            </Box>
          ) : tableRows?.length === 0 ? (
            <Box className="flex flex-col justify-center items-center p-10">
              <Typography>
                No reports found! Please generate reports and save them to view
                them
              </Typography>
            </Box>
          ) : (
            <Box height={"70vh"} width="100%">
              <TableVirtuoso
                context={tblContext}
                data={tableRows}
                components={TableComponents}
                fixedHeaderContent={fixedHeaderContent}
                fixedFooterContent={() => <></>}
                itemContent={(row) => {
                  return <CustomTableRows row={tableRows[row]} />;
                }}
                endReached={onLoadMore}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default GeneratedReport;
