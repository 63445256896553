// store/breadcrumb/slice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface BreadcrumbItem {
  label: string;
  path?: string;
}

interface BreadcrumbState {
  items: BreadcrumbItem[];
}

export const initialState: BreadcrumbState = {
  items: [],
};

const BreadcrumbSlice = createSlice({
  name: "breadcrumb",
  initialState,
  reducers: {
    setBreadcrumbs(state, action: PayloadAction<BreadcrumbItem[]>) {
      state.items = action.payload;
    },
  },
});

export const {
  actions: BreadcrumbReducerAction,
  name: BreadcrumbReducerName,
  reducer: BreadcrumbReducer,
} = BreadcrumbSlice;
