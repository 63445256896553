import { Box, Typography } from "@mui/material";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { dashboardSelector } from "../../../../store/dashboard/selector";
import { useSelector } from "react-redux";
import { DashboardState } from "../../../../store/dashboard/types";

const getPlatformColor = (platform: string) => {
  const colors: Record<string, string> = {
    Google: "#f9a825",
    Facebook: "#2196f3",
    Instagram: "#e91e63",
    Tiktok: "#00bcd4",
  };
  return colors[platform] || "#ccc";
};

const TopAdvertisingChannels = () => {
  const { top_advertisement_channel_by_conversions }: DashboardState =
    useSelector(dashboardSelector);

  // Transform Redux state to match chart data format
  const formattedData =
    top_advertisement_channel_by_conversions.advertising_channels.map(
      (channel) => ({
        name: channel.platform,
        conversions: channel.conversions.toLocaleString(),
        percentage: parseFloat(channel.percentage),
        color: getPlatformColor(channel.platform),
      })
    );

  return (
    <Box className="flex-column lg:flex-row p-5 pr-0 items-center border-t border-t-primary-blue-100">
      <Box className="w-full max-w-[500px] mx-auto p-4 bg-white rounded-lg">
        <Box className="flex justify-between pb-2 border-primary-blue-100 text-primary-black-600 font-medium">
          <Typography className="!flex !flex-wrap !w-min">
            Advertising Channels
          </Typography>
          <Typography>Conversions</Typography>
          <Typography></Typography>
        </Box>

        {/* Rows */}
        {formattedData.map((channel, index) => (
          <Box
            key={index}
            className={`grid grid-cols-5 text-right py-3 ${
              formattedData.length - 1 === index ? "" : "border-b"
            } border-primary-blue-100`}
          >
            <Box className="flex items-center col-span-2">
              <Box
                className="w-4 h-4 rounded-full mr-2"
                sx={{ backgroundColor: channel.color }}
              />
              <Typography>{channel.name}</Typography>
            </Box>
            <Typography className="col-span-2">
              {channel.conversions}
            </Typography>
            <Typography className="col-span-1">
              {channel.percentage}%
            </Typography>
          </Box>
        ))}
      </Box>

      <Box className="relative w-full h-[250px]">
        <ResponsiveContainer minWidth={200}>
          <PieChart>
            {/* Donut Chart */}
            <Pie
              data={formattedData}
              dataKey="percentage"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={80}
              outerRadius={120}
              fill="#8884d8"
              labelLine={false}
            >
              {formattedData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
        {/* Total Cost in Center */}
        <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
          <Typography className="!text-md !text-primary-black-600">
            Total
          </Typography>
          <Typography className="!text-primary-black-950 !text-2xl !font-bold">
            {top_advertisement_channel_by_conversions.total}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TopAdvertisingChannels;
