import { Box, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { UseFormReturn } from "react-hook-form";
import { PlatformType, ReportType } from "../../../../types/reportTypes";
import { reportsStepOneSocialMediaOptions } from "../../../../utils/data";

const ReportsStepOne = ({
  reportStepperForm,
}: {
  reportStepperForm: UseFormReturn<ReportType>;
}) => {
  const { watch, setValue } = reportStepperForm;

  const selectedValues = watch("platform") || [];

  const isAllChecked =
    selectedValues.length === reportsStepOneSocialMediaOptions.length - 1;

  const handleCheckboxChange = (option: PlatformType) => {
    if (option.name === "All") {
      if (isAllChecked) {
        setValue("platform", []);
      } else {
        setValue("platform", reportsStepOneSocialMediaOptions.slice(1));
      }
    } else {
      const isSelected = selectedValues.some(
        (value: any) => value.name === option.name
      );

      if (isSelected) {
        const updatedValues = selectedValues.filter(
          (value: any) => value.name !== option.name
        );
        setValue("platform", updatedValues);
      } else {
        setValue("platform", [...selectedValues, option]);
      }
    }
  };

  return (
    <Box className="grid grid-cols-1 md:grid-cols-2 gap-5">
      {reportsStepOneSocialMediaOptions.map((option, index) => (
        <Box
          key={index}
          className="flex-between-center w-full border rounded-xl p-5 border-primary-blue-100"
        >
          <Box className="flex items-center gap-[5px]">
            <Checkbox
              checked={
                option.name === "All"
                  ? isAllChecked
                  : selectedValues.some(
                      (value: PlatformType) => value.name === option.name
                    )
              }
              onChange={() => handleCheckboxChange(option)}
            />
            <Typography>{option.name}</Typography>
          </Box>
          {option.icon()}
        </Box>
      ))}
    </Box>
  );
};

export default ReportsStepOne;
