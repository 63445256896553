import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  TableCell,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  isReportLoadingSelector,
  reportListSelector,
  reportsListSkipSelector,
} from "../../../../store/report/selector";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ReportsSagaAction } from "../../../../store/report/sagas";
import { useNavigate } from "react-router-dom";
import routeConstants from "../../../../utils/routeConstants";
import { ReportReducerAction } from "../../../../store/report/slice";
import VirtuosoList from "../../../common/VirtuosoList";
import { CustomBlueDeleteIcon, EditIcon, WhitePlusIcon } from "../../../Icons";
import LoginIcon from "@mui/icons-material/Login";
import DeleteModal from "../../../common/DeleteModal";
import { formatName } from "../../../../utils/utils";

const SavedReports = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reportList = useSelector(reportListSelector);
  const isReportListLoading = useSelector(isReportLoadingSelector);
  const reportListSkip = useSelector(reportsListSkipSelector);
  const [hasMore, setHasMore] = useState(true);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<string>("");
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(ReportReducerAction.setReportListSkip(0));
    dispatch(
      ReportReducerAction.setReportList({
        data: [],
        total: 0,
      })
    );
    dispatch(ReportsSagaAction.getReportsList({ skip: 0, limit: 10 }));
  }, []);

  const handleReportsDetails = (id: number) => {
    navigate(routeConstants.generatedReport + `/${id}`);
  };

  useEffect(() => {
    setHasMore(reportList?.data?.length < reportList?.total);
  }, [reportList]);

  const onLoadMore = () => {
    if (hasMore) {
      dispatch(ReportReducerAction.setReportListSkip(reportListSkip + 10));
      dispatch(
        ReportsSagaAction.getReportsList({
          skip: reportListSkip + 10,
          limit: 10,
        })
      );
    }
  };

  const handleDeleteForm = async (id: string) => {
    setIsDeleteLoading(true);
    await new Promise<void>((resolve) => {
      dispatch(ReportsSagaAction.deleteReport({ id, resolve }));
    });
    setIsDeleteLoading(false);
    setIsOpenDeleteModal("");
  };
  const CustomTableRows = ({ row }: { row: any }) => {
    return (
      <TableCell className="flex-between-center w-full">
        <Box key={row.id} className="flex-between-center">
          <Box className="flex-column md:flex-row gap-[5px]">
            <Typography>{formatName(row.report_name)}</Typography>
          </Box>
          <Box className="flex gap-1">
            <Button
              className="!min-w-0 !p-1 !text-base !text-primary-blue-950 !border !border-primary-blue-400 !rounded-lg !bg-primary-blue-100 !normal-case"
              onClick={() => handleReportsDetails(row?.id)}
              startIcon={<LoginIcon />}
            ></Button>
            <Button
              className="!min-w-0 !p-1 !text-base !text-primary-blue-950 !border !border-primary-blue-400 !rounded-[10px] !bg-primary-blue-100"
              onClick={() => {
                navigate(routeConstants.generateReports, {
                  state: { reportId: row.id },
                });
              }}
            >
              <Box>{EditIcon}</Box>
            </Button>
            <Button
              className="!min-w-0 !p-1 !text-base !text-primary-blue-950 !border !border-primary-blue-400 !rounded-[10px] !bg-primary-blue-100"
              onClick={() => setIsOpenDeleteModal(row.id)}
            >
              <Box>{CustomBlueDeleteIcon}</Box>
            </Button>
          </Box>
        </Box>
      </TableCell>
    );
  };

  return (
    <Box className="bg-white flex-column w-full lg:w-[80%] rounded-[20px] border border-primary-blue-100 ">
      {/* header */}
      <Box className="flex-between-center gap-5 p-5 border-b border-b-primary-blue-100 ">
        <Typography className="!text-xl !font-medium">Saved Reports</Typography>
        <Box>
          <IconButton
            onClick={() => navigate(routeConstants.generateReports)}
            sx={{
              color: "white",
              paddingX: "20px",
              paddingY: "8px",
              borderRadius: "8px",
              border: "1px solid #E9ECF2",
              gap: "10px",
              backgroundColor: "#20407D",
              "&:hover": {
                borderColor: "#20407D",
                backgroundColor: "white",
                color: "#20407D",
              },
            }}
          >
            {WhitePlusIcon}
            <Typography className="!text-sm !font-normal transition-colors duration-300">
              Generate report
            </Typography>
          </IconButton>
        </Box>
      </Box>

      {isReportListLoading || reportList?.data?.length ? (
        <Box className="flex-column gap-[15px] pt-0 p-5">
          <VirtuosoList
            data={reportList?.data}
            dataLength={reportList?.data?.length}
            isLoading={isReportListLoading}
            onLoadMore={onLoadMore}
            totalData={reportList?.total}
            CustomTableRows={CustomTableRows}
            tableCellCount={1}
            height={"400px"}
            CustomTableRowsCustomClassName="flex"
          />
        </Box>
      ) : (
        <Box className="flex flex-col justify-center items-center !h-[300px]">
          <Typography>
            No saved reports found! Please generate reports and save them to
            view them here.
          </Typography>
        </Box>
      )}
      <DeleteModal
        open={!!isOpenDeleteModal}
        isLoading={isDeleteLoading}
        onClose={() => setIsOpenDeleteModal("")}
        onConfirm={() => handleDeleteForm(isOpenDeleteModal)}
      />
    </Box>
  );
};

export default SavedReports;
