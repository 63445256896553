import { AxiosResponse } from "axios";
import { createSliceSaga } from "../saga-helper";
import { DashboardReducerAction } from "./slice";
import { call, put } from "redux-saga/effects";
import { getDashboardDataService } from "../../services/dashboardServices";

// SAGA FLOW EXAMPLE FOR FUTURE API INTEGRATION

const saga = createSliceSaga({
  name: "dashboard-saga-state",
  caseSagas: {
    *getDashBoardData(action: {
      payload: {
        days: number;
      };
    }) {
      try {
        yield put(DashboardReducerAction.setIsLoading(true));
        // Step-2: Calling the Service file API and setting the data in reducer
        const { data, status }: AxiosResponse = yield call(
          getDashboardDataService,
          action.payload
        );
        if (status === 200) {
          yield put(DashboardReducerAction.setDashboardData(data));
        }
      } catch (error) {
        console.error("Error fetching users list");
      } finally {
        yield put(DashboardReducerAction.setIsLoading(false));
      }
    },
  },
  sagaType: "takeEvery",
});

export const {
  actions: DashboardSagaAction,
  saga: DashboardSaga,
  name: DashboardSagaName,
} = saga;
