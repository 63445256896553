import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./slice";

const selectDomain = (state: any) => state.clientState || initialState;

export const selectClients = createSelector(
  selectDomain,
  (state) => state.clients
);

export const selectSelectedClient = createSelector(
  selectDomain,
  (state) => state.selectedClient
);

export const selectClientLoading = createSelector(
  selectDomain,
  (state) => state.isLoading
);

export const selectClientError = createSelector(
  selectDomain,
  (state) => state.error
);
