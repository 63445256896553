import { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { Controller, FieldValues } from "react-hook-form";
import FormFieldLabel from "./FormFieldLabel";
import { FieldConfig } from "../../types/forms";

/**
 * Filters items based on the input value.
 * @param inputVal - The input value to filter items.
 * @param items - The items to filter.
 * @returns The filtered items.
 */
const filterItems = <T extends { label: string | JSX.Element }>(
  inputVal: string,
  items?: T[] | []
): T[] | [] | undefined => {
  const searchValue = inputVal.toLowerCase();
  return items
    ? items.filter((item) => {
        if (typeof item.label === "string") {
          return item.label.toLowerCase().includes(searchValue.toLowerCase());
        }
        return false;
      })
    : [];
};

/**
 * StandardSelect Component
 * @param props - FieldConfig properties
 */
const StandardSelect = <T extends FieldValues, U extends FieldValues = T>({
  name,
  label = "",
  placeholder,
  editable = true,
  control,
  options,
  defaultValue,
  isSingleSelect = true,
  customClassName,
  borderColor,
  customHeight,
  rules,
  icon,
  errors,
  onChange,
}: FieldConfig<T, U>) => {
  // State variables for search input and results
  const [inputValue, setInputValue] = useState<string>("");
  const [searchResult, setSearchResult] = useState<
    Array<{
      label: string | JSX.Element;
      value: string;
    }>
  >([]);
  const filteredTags = inputValue ? searchResult : options;

  useEffect(() => {
    setSearchResult(filterItems(inputValue, options) ?? []);
  }, [inputValue, options]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: SelectChangeEvent<string[]>, field: any) => {
    if (isSingleSelect) {
      field.onChange(event.target.value);
      onChange?.();
    } else {
      const value = event.target.value as string[];
      if (value.includes("selectAll")) {
        field.onChange(
          (field.value as string[]).length === (options?.length ?? 0) - 1
            ? []
            : options
                ?.filter((option) => option.value !== "selectAll")
                .map((item) => item.value)
        );
      } else {
        field.onChange(value);
        onChange?.();
      }
    }
  };

  return (
    <Controller
      name={name!}
      control={control}
      rules={rules}
      render={({ field }) => {

        // Add this to ensure field.value is always valid
        const validValue = isSingleSelect
          ? field.value && options?.some((opt) => opt.value === field.value)
            ? field.value
            : ""
          : Array.isArray(field.value)
          ? (field.value as string[]).filter((val) =>
              options?.some((opt) => opt.value === val)
            )
          : [];

        return (
          <Stack>
            <FormFieldLabel label={label} />
            <Box className="flex items-center relative w-full">
              <FormControl variant="outlined" fullWidth error={!!errors}>
                <Select
                  multiple={!isSingleSelect}
                  value={
                    validValue ?? defaultValue ?? (isSingleSelect ? "" : [])
                  }
                  disabled={!editable}
                  onChange={(event) => handleChange(event, field)}
                  displayEmpty
                  startAdornment={
                    icon && (
                      <InputAdornment className="!m-0" position="start">
                        <Box>{icon}</Box>
                      </InputAdornment>
                    )
                  }
                  sx={{
                    ".MuiSelect-select": {
                      padding: "6px 16px",
                      background: "#fff",
                      color: "#6b7280",
                      height: customHeight
                        ? `${customHeight} !important`
                        : "auto",
                      minHeight: "31px !important",
                    },
                    ".MuiSelect-root": {
                      backgroundColor: "#F4F5F8 !important",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      border: !errors
                        ? `1px solid ${
                            borderColor ? borderColor : "#D6D6DD"
                          } !important`
                        : "",
                    },
                    "&:hover fieldset, &.Mui-focused fieldset": {
                      borderColor: `${
                        borderColor ? borderColor : !errors && "#D6D6DD"
                      } !important`,
                    },
                    ".Mui-disabled": {
                      color: "#6b7280",
                      WebkitTextFillColor: "#6b7280",
                      opacity: 0.6,
                    },
                    ".MuiSelect-iconOutlined": {
                      color: borderColor ?? "inherit",
                    },
                    ".css-1r85cow-MuiTypography-root": {
                      lineHeight: 1.75,
                    },
                    ".css-11kmrwd-MuiTypography-root": {
                      lineHeight: 1.75,
                    },
                  }}
                  renderValue={(selected) => {
                    if (isSingleSelect && (selected as string[]).length === 0) {
                      return (
                        <Typography
                          color="#21232C"
                          style={{
                            height: customHeight,
                            display: "flex",
                            alignItems: "center",
                            padding: "4px 12px",
                            fontSize: "16px",
                          }}
                        >
                          {placeholder}
                        </Typography>
                      );
                    }
                    if (isSingleSelect) {
                      const selectedOption = options?.find(
                        (option) => option.value === selected.toString()
                      );
                      return (
                        <Typography
                          color="#21232C"
                          className={`${customClassName} max-w-[200px]`}
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            borderRadius: "4px",
                            padding: "4px 12px",
                            height: customHeight,
                            display: "flex",
                            alignItems: "center",
                            fontSize: "16px",
                          }}
                        >
                          {selectedOption?.label}
                        </Typography>
                      );
                    }
                    // Handle multi-select case
                    const selectedValues = (selected as string[]) || [];
                    const selectedLabels = options
                      ?.filter((option) =>
                        selectedValues.includes(option?.value)
                      )
                      .map((option) => option.label)
                      .join(", ");

                    return (
                      <Typography
                        color="#21232C"
                        className={`${customClassName} max-w-[200px]`}
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          borderRadius: "4px",
                          padding: "4px 12px",
                          height: customHeight,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {selectedLabels || placeholder}
                      </Typography>
                    );
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                    PaperProps: {
                      style: { maxHeight: 300 },
                      sx: {
                        ".MuiMenu-list": {
                          display: "flex",
                          flexDirection: "column",
                        },
                      },
                    },
                    MenuListProps: { autoFocusItem: false },
                  }}
                  SelectDisplayProps={{
                    className: `${customClassName} !bg-[#F4F5F8]`,
                  }}
                >
                  {filteredTags?.map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option.value}
                      sx={{
                        paddingY: 0,
                        paddingX: 0,
                        "&.Mui-selected": {
                          backgroundColor: "#F4F5F8 !important",
                        },
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      {!isSingleSelect && (
                        <Checkbox
                          className="!p-0 !ml-5 !text-primary-blue-950"
                          checked={
                            (field.value as string[])?.some(
                              (item) => item === option.value
                            ) ||
                            (option.value === "selectAll" &&
                              (field.value as string[]).length ===
                                (options?.length ?? 0) - 1)
                          }
                        />
                      )}
                      <ListItemText
                        primary={
                          <Typography
                            style={{
                              padding: "12px 20px",
                            }}
                          >
                            {option.value}
                          </Typography>
                        }
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <span className="text-red1 mx-0 text-sm font-semibold">
              {errors}
            </span>
          </Stack>
        );
      }}
    />
  );
};

export default StandardSelect;
