import { format } from "date-fns";
import axiosClient from "../apiClient";
import { DATE_FORMAT } from "../utils/appConstants";

export const CampaignsService = async (params: {
  source?: string[];
  skip: number;
  limit: number;
  start_date?: string;
  end_date?: string;
  search: string;
}) => {
  try {
    let startDateString;
    let endDateString;
    if (params.start_date && params.end_date) {
      startDateString = format(params.start_date, DATE_FORMAT);
      endDateString = format(params.end_date, DATE_FORMAT);
    }

    let queryString = `?skip=${params.skip}&limit=${params.limit}`;

    if (params.source && !params.source.includes("All")) {
      queryString = `${queryString}&source=${params.source}`;
    }

    if (params.start_date && !params.end_date) {
      queryString = `${queryString}&start_date=${startDateString}&end_date=${endDateString}`;
    }
    if (params.search) {
      queryString = `${queryString}&search=${params.search}`;
    }

    const response = await axiosClient.get(`/campaigns${queryString}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
