import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import SectionHeader from "../SectionHeader";
import { Box, Typography } from "@mui/material";
import { StyledLineChart } from "../conversionsByDaySection";
import { dashboardSelector } from "../../../store/dashboard/selector";
import { useSelector } from "react-redux";
import { DashboardState } from "../../../store/dashboard/types";

const formatDate = (dateStr: string) => {
  const date = new Date(dateStr);
  const day = date.getDate();
  const suffix = ["th", "st", "nd", "rd"][
    day % 10 > 3 || Math.floor((day % 100) / 10) === 1 ? 0 : day % 10
  ];
  return `${day}${suffix}`;
};

const CostPerConversionSection = () => {
  const { cost_per_conversion }: DashboardState =
    useSelector(dashboardSelector);

  // Transform API response to chart format
  const chartData = Object.keys(cost_per_conversion["Google"] || {}).map(
    (date) => ({
      date: formatDate(date),
      Google: cost_per_conversion.Google[date],
      Facebook: cost_per_conversion.Facebook[date],
      Instagram: cost_per_conversion.Instagram[date],
      Tiktok: cost_per_conversion.Tiktok[date],
    })
  );

  const platforms = [
    { platform: "Google", color: "#F4B400" },
    { platform: "Facebook", color: "#3b5998" },
    { platform: "Instagram", color: "#BA3478" },
    { platform: "Tiktok", color: "#69C9D0" },
  ];

  return (
    <Box className="bg-white rounded-xl border border-primary-blue-100">
      <SectionHeader title="Cost per Conversion" />
      <ResponsiveContainer
        minWidth={200}
        className="p-5 pl-0 !w-full !h-[400px] border-t border-t-primary-blue-100"
      >
        <StyledLineChart data={chartData}>
          <CartesianGrid
            stroke="#E9ECF2"
            strokeDasharray="0"
            vertical={false}
          />
          <XAxis
            dataKey="date"
            className="text-xs text-primary-black-600"
            label={{
              value: "February - March 2025",
              position: "insideBottom",
              offset: -20,
            }}
            axisLine={{ stroke: "#E9ECF2" }}
            tick={{ fill: "#666" }}
            stroke="#E9ECF2"
            minTickGap={0}
          />
          <YAxis
            domain={[0, 80]}
            ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80]}
            className="text-xs text-primary-black-600"
            tickLine={true}
            stroke="#E9ECF2"
            tick={{ fill: "#666" }}
            axisLine={{ stroke: "#E9ECF2" }}
            label={{
              value: "Cost per Conversion",
              angle: -90,
              position: "insideLeft",
              offset: 20,
              dy: 30,
            }}
          />
          <Tooltip />
          {platforms.map(({ platform, color }) => (
            <Line
              key={platform}
              type="monotone"
              dataKey={platform}
              stroke={color}
              strokeWidth={2}
            />
          ))}
        </StyledLineChart>
      </ResponsiveContainer>
      <Box className="flex-column flex-center w-full md:flex-row gap-5 mt-6">
        {platforms.map((item) => (
          <Box key={item.platform} className="pb-5 flex gap-2.5 items-center">
            <Box
              sx={{ background: item.color }}
              className={`w-3 h-3 rounded-full mr-2`}
            />
            <Typography className="!text-sm">{item.platform}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CostPerConversionSection;
