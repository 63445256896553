import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { TrailPartnersAuthService } from "../../services/TrailPartnersAuthService";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { ClientSagaAction } from "../../store/client-dropdown/saga";
import useAuth from "../../hooks/useAuth";
import { LoginResponseType } from "../../types/AuthTypes";

interface LoginParamType {
  code: string;
}

const getQueryParams = (location: any) => {
  const params = new URLSearchParams(location.search);
  return {
    code: params.get("code"),
  };
};

const LoginWithTrialPartners = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { code } = getQueryParams(location);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<string>("");
  const { setAuthStatus } = useAuth();

  useEffect(() => {
    if (!code) {
      setIsError("The code is not available in your URL.");
      return;
    }
    handleLogin(code);
  }, [code]);

  const fetchClientsAndSetAuth = () => {
    return new Promise((resolve) => {
      dispatch(
        ClientSagaAction.fetchClientsAndSetAuth({ isLogin: true, resolve })
      );
    });
  };

  const handleLogin = async (code: string) => {
    const loginParams: LoginParamType = { code };

    try {
      setIsLoading(true);
      const response: LoginResponseType = await TrailPartnersAuthService(
        loginParams
      );

      if (response.status === 200) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("currentUser", JSON.stringify(response.data.user));
        await fetchClientsAndSetAuth();
        setAuthStatus(true);
      } else {
        setIsError("Something went wrong. Try again.");
      }
    } catch (error) {
      setIsError("An error occurred while logging in. Please try again.");
      console.error("Login error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box className="login-container">
      <Box className="absolute top-0 left-0 bg-primary-blue-200 rounded-b-[80px] h-3/5 w-full">
        <Box className="py-[30px] px-[40px]">
          <img
            src={require("../../assets/logo.png")}
            alt="logo"
            className="sm:w-[240px] sm:h-[45px]"
          />
        </Box>
      </Box>
      <Box className="relative top-10 flex justify-center items-center h-[60vh] sm:h-[40vh] w-[80vw] sm:w-[60vw] rounded-[30px] bg-white z-10">
        {isLoading ? (
          <Box className="flex flex-col gap-4 justify-center items-center">
            <CircularProgress size={56} />
            <Typography>Please Wait...</Typography>
          </Box>
        ) : (
          <Box className="flex flex-col gap-4 justify-center items-center">
            {isError ? <Typography color="main">{isError}</Typography> : null}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LoginWithTrialPartners;
