import { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import {
  getPatientList,
  getPlateFormFields,
  saveFormForCampaignsService,
} from "../../services/ConversionsService";
import { useNavigate } from "react-router-dom";
import routeConstants from "../../utils/routeConstants";
import AddUpdateForm from "./AddUpdateForm";

interface FormValues {
  form_id?: string;
  form_url: string;
  form_name: string;
  patient_id: string;
  mappings: {
    platform_field: string;
    form_field: string;
    form_field_id?: string;
  }[];
}

const AddFormContainer = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [patients, setPatients] = useState<{ label: string; value: string }[]>(
    []
  );
  const [plateFormFields, setPlateFormFields] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    fetchPatients();
    fetchPlateFormFields();
  }, []);

  const fetchPatients = async () => {
    try {
      const { data } = await getPatientList({});
      setPatients(
        data.map((patient: any) => ({
          label: patient.name,
          value: patient.id.toString(),
        }))
      );
    } catch (err) {
      console.error("Error fetching patients:", err);
    }
  };

  const fetchPlateFormFields = async () => {
    try {
      const data = await getPlateFormFields();
      setPlateFormFields(
        Object.values(data).map((field) => ({
          label: field as string,
          value: field as string,
        }))
      );
    } catch (err) {
      console.error("Error fetching patients:", err);
    }
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      form_url: "",
      form_name: "",
      patient_id: "",
      mappings: [{ platform_field: "", form_field: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "mappings",
  });

  const onSubmit = async (data: FormValues) => {
    const formData = new FormData();

    formData.append("patient_id", String(data.patient_id));
    formData.append("name", data.form_name);
    formData.append("form_url", data.form_url);
    if (data.form_id) {
      formData.append("form_id", String(data.form_id));
    }

    data.mappings.forEach((mapping) => {
      formData.append("platform_fields[]", mapping.platform_field);
      formData.append("form_fields[]", mapping.form_field);
    });

    if (data.mappings.some((mapping) => mapping.form_field_id)) {
      data.mappings.forEach((mapping) => {
        if (mapping.form_field_id) {
          formData.append("form_field_id[]", String(mapping.form_field_id));
        }
      });
    }

    try {
      setIsLoading(true);
      const { status, data }: AxiosResponse = await saveFormForCampaignsService(
        formData
      );
      if (status) {
        setValue("form_id", data?.[0]?.id);
        toast.success("Form has been saved successfully!");
        navigate(routeConstants.conversions);
      }
    } catch (error) {
      console.error("Error saving form:", error);
      toast.error("Failed to save form. Please try again!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AddUpdateForm
      isEdit={false}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      isLoading={isLoading}
      control={control}
      errors={errors}
      patients={patients}
      fields={fields}
      plateFormFields={plateFormFields}
      append={append}
      remove={remove}
    />
  );
};

export default AddFormContainer;
