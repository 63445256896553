import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./slice";

// SAGA FLOW EXAMPLE FOR FUTURE API INTEGRATION

const selectDomain = (state: any) => state.conversionsState || initialState;

// Step-4: Making the state available for the files to be called through
export const conversionsSelector = createSelector(
  selectDomain,
  (state) => state.conversions
);
export const isConversionsLoadingSelector = createSelector(
  selectDomain,
  (state) => state.isLoading
);
export const conversionsFilterSelector = createSelector(
  selectDomain,
  (state) => state.filter
);
export const conversionsSkipSelector = createSelector(
  selectDomain,
  (state) => state.skip
);
