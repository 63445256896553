import { createSlice } from "@reduxjs/toolkit";
import { ReportDetailsType, ReportsInitalStateType } from "../../types/reportTypes";

export const initialReportDetails: ReportDetailsType = {
  report_id: 0,
  report_name: "",
  start_date: "",
  end_date: "",
  platforms: [],
  campaigns: [],
  campaign_names: [],
  matrics: [],
  metricValues: {},
};

export const initialState: ReportsInitalStateType = {
  reportDetailsList: {
    data: [],
    total: 0,
  },
  reportList: {
    data: [],
    total: 0,
  },
  recommendedReportList: {
    data: [],
    total: 0,
  },
  reportDetails: initialReportDetails,
  downloadUrl: "",
  metrics: [],
  isLoading: false,
  reportViewSkip: 0,
  reportSkip: 0,
  recommendedReportSkip: 0,
};

const report = createSlice({
  name: "reportState",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setReportList(state, action) {
      state.reportList = action.payload;
    },
    setRecommendedList(state, action) {
      state.recommendedReportList = action.payload;
    },
    setReportView(state, action) {
      state.reportDetailsList = action.payload;
    },
    setReportDetails(state, action) {
      state.reportDetails = action.payload;
    },
    setDownloadReportUrl(state, action) {
      state.downloadUrl = action.payload;
    },
    setMetrics(state, action) {
      state.metrics = action.payload;
    },
    setCampaignSkip(state, action) {
      state.reportViewSkip = action.payload;
    },
    setReportListSkip(state, action) {
      state.reportSkip = action.payload;
    },
    setRecommendedReportSkip(state, action) {
      state.recommendedReportSkip = action.payload;
    },
  },
});

export const {
  actions: ReportReducerAction,
  name: ReportReducerName,
  reducer: ReportReducer,
} = report;
