import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ClientDropDown } from "../../components/Icons";
import { useDispatch, useSelector } from "react-redux";
import { ClientSagaAction } from "../../store/client-dropdown/saga";
import {
  selectClientLoading,
  selectClients,
  selectSelectedClient,
} from "../../store/client-dropdown/selector";

// Styled Select Component
const StyledSelect = styled(Select)({
  background: "#F4F5F8",
  borderRadius: "6px",
  padding: 0,
  border: "1px solid #E9ECF2",
  gap: "10px",
  "& .MuiSelect-select": {
    display: "flex",
    alignItems: "center",
    padding: "10px",
  },
  "& fieldset": {
    border: "none",
  },
});

// Styled Loading Box (matches StyledSelect appearance)
const StyledLoadingBox = styled(Box)({
  background: "#F4F5F8",
  borderRadius: "6px",
  padding: "0px",
  border: "1px solid #E9ECF2",
  minWidth: "192px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
  minHeight: "45px",
});

// Dropdown Component
const ClientDropdown: React.FC = () => {
  const dispatch = useDispatch();
  const clients = useSelector(selectClients);
  const selectedClient = useSelector(selectSelectedClient);
  const loading = useSelector(selectClientLoading);
  const [isChanging, setIsChanging] = useState(false);

  useEffect(() => {
    const fetchClientsAndSetAuth = () => {
      return new Promise((resolve) => {
        dispatch(ClientSagaAction.fetchClientsAndSetAuth({ resolve }));
      });
    };

    if (!clients?.length && !selectedClient) {
      fetchClientsAndSetAuth();
    }
  }, [clients, selectedClient, dispatch]);

  // Handle change in selected client
  const handleChange = async (event: SelectChangeEvent<unknown>) => {
    const clientId = event.target.value as string;
    setIsChanging(true);
    localStorage.setItem("selectedClientId", clientId);

    // Ensure saga action is completed before continuing
    await new Promise<void>((resolve) => {
      dispatch(ClientSagaAction.setClient({ clientId, resolve }));
    });

    setIsChanging(false);
    window.location.reload();
  };

  return loading || isChanging ? (
    <StyledLoadingBox>
      <CircularProgress size={20} />
      <Typography>Loading...</Typography>
    </StyledLoadingBox>
  ) : (
    <StyledSelect
      value={selectedClient}
      onChange={handleChange}
      displayEmpty
      IconComponent={ExpandMoreIcon}
    >
      {clients?.map((client: any) => (
        <MenuItem key={client.id} value={client.id}>
          <Box display="flex" alignItems="center" gap={1}>
            {ClientDropDown}
            <Typography>{client.name}</Typography>
          </Box>
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export default ClientDropdown;
