import { AxiosResponse } from "axios";
import { createSliceSaga } from "../saga-helper";
import { call, put, select } from "redux-saga/effects";
import { CampaignsReducerAction } from "./slice";
import { CampaignsService } from "../../services/CampaignsService";
import { campaignsSelector } from "./selector";
import { CampaignsListType } from "./types";

const saga = createSliceSaga({
  name: "campgains-saga-state",
  caseSagas: {
    *getCampaigns(action: {
      payload: {
        source?: string[];
        start_date?: string;
        end_date?: string;
        skip: number;
        limit: number;
        search: string;
      };
      type: string;
    }) {
      try {
        const campaignsList: CampaignsListType = yield select(
          campaignsSelector
        );
        yield put(CampaignsReducerAction.setIsLoading(true));
        // Step-2: Calling the Service file API and setting the data in reducer
        const { data: apiResponse, status }: AxiosResponse = yield call( 
          CampaignsService,
          action.payload
        );

        if (status === 200) {
          let responseData = apiResponse.data;
          if (campaignsList.data.length > 0 && responseData.length > 0)
            responseData = [...campaignsList.data, ...responseData];

          yield put(
            CampaignsReducerAction.setCampaigns({
              total: apiResponse.total ?? 0,
              data: responseData,
            })
          );
        }
      } catch (error) {
        console.error("Error fetching Campaigns!");
      } finally {
        yield put(CampaignsReducerAction.setIsLoading(false));
      }
    },
  },
  sagaType: "takeEvery",
});

export const {
  actions: CampaignsSagaAction,
  saga: CampaignsSaga,
  name: CampaignsSagaName,
} = saga;
