import { Box } from "@mui/material";
import ProfileForm from "../components/profile/ProfileForm";

const Profile = () => {
  return (
    <Box className="rounded-[30px] p-[30px] bg-white flex-column gap-7">
      <ProfileForm />
    </Box>
  );
};

export default Profile;
