import { LoginWithCodeParamType } from "../types/AuthTypes";
import axiosClient from "../apiClient";

export const TrailPartnersAuthService = async (
  params: LoginWithCodeParamType
) => {
  try {
    const response = await axiosClient.post("/callback/oauth", params);
    return response.data;
  } catch (error) {
    return error;
  }
};
