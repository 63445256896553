import { Box, Button, TableCell, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import routeConstants from "../../../../utils/routeConstants";
import { useNavigate } from "react-router-dom";
import { ReportReducerAction } from "../../../../store/report/slice";
import { useDispatch } from "react-redux";
import { ReportsSagaAction } from "../../../../store/report/sagas";
import LoginIcon from "@mui/icons-material/Login";
import {
  recommendedReportListSelector,
  recommendedReportSkipSelector,
} from "../../../../store/report/selector";
import { useSelector } from "react-redux";
import VirtuosoList from "../../../common/VirtuosoList";

const TopRecommendedReports = () => {
  const limit = 10;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const recommendedReportList = useSelector(recommendedReportListSelector);
  const reportListSkip = useSelector(recommendedReportSkipSelector);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(ReportReducerAction.setRecommendedReportSkip(0));
    dispatch(
      ReportReducerAction.setRecommendedList({
        data: [],
        total: 0,
      })
    );
    const fetchRecommendedReportData = async () => {
      setIsLoading(true);
      await new Promise<void>((resolve) => {
        dispatch(
          ReportsSagaAction.getRecommendedReportsList({
            skip: 0,
            limit: limit,
            resolve,
          })
        );
      });
      setIsLoading(false);
    };
    fetchRecommendedReportData();
  }, [dispatch]);

  useEffect(() => {
    setHasMore(
      recommendedReportList?.data?.length < recommendedReportList?.total
    );
  }, [recommendedReportList]);

  const onLoadMore = async () => {
    if (hasMore) {
      setIsLoading(true);
      dispatch(ReportReducerAction.setRecommendedReportSkip(reportListSkip + 10));
      await new Promise<void>((resolve) => {
        dispatch(
          ReportsSagaAction.getRecommendedReportsList({
            skip: reportListSkip + 10,
            limit: limit,
            resolve,
          })
        );
      });
      setIsLoading(false);
    }
  };

  const CustomTableRows = ({ row }: { row: any }) => {
    return (
      <TableCell className="flex-between-center w-full">
        <Box className="flex-between-center">
          <Box className="flex-column md:flex-row gap-[5px]">
            <Typography className="!text-balance">
              {row?.report_name} -
            </Typography>
            <Typography className="text-primary-black-600">
               {row?.insights || 0}
            </Typography>
          </Box>
          <Box>
            <Button
              // onClick={() => navigate(routeConstants.generatedReport)}
              className="!py-2 !px-5 !text-base !text-primary-blue-950 !border !border-primary-blue-400 !rounded-lg !bg-primary-blue-100 !normal-case"
              startIcon={<LoginIcon />}>
            </Button>
          </Box>
        </Box>
      </TableCell>
    );
  };

  return (
    <Box className="bg-white flex-column w-full lg:w-[80%] rounded-[20px] border border-primary-blue-100">
      {/* header */}
      <Box className="flex-between-center gap-5 p-5 border-b border-b-primary-blue-100">
        <Typography className="!text-xl !font-medium">
          Top Recommended Reports
        </Typography>
      </Box>
      {isLoading || recommendedReportList?.data?.length ? (
        <Box className="flex-column gap-[15px] p-5">
          <VirtuosoList
            data={recommendedReportList?.data}
            dataLength={recommendedReportList?.data?.length}
            isLoading={isLoading}
            onLoadMore={onLoadMore}
            totalData={recommendedReportList?.total}
            CustomTableRows={CustomTableRows}
            tableCellCount={1}
            height={"400px"}
            CustomTableRowsCustomClassName="flex"
          />
        </Box>
      ) : (
        <Box className="flex flex-col justify-center items-center !h-[300px]">
          <Typography>No recommended reports found!</Typography>
        </Box>
      )}
    </Box>
  );
};

export default TopRecommendedReports;
