const routeConstants = {
  login: "/login-with-trialpartners",
  dashboard: "/",
  myProfile: "/profile",
  campaigns: "/campaigns",
  manageDataSource: "/manage-data-source",
  reports: "/reports",
  generateReports: "/generate-reports",
  generatedReport: "/reports/generated-report",
  conversions: "/conversions",
  conversionsAddForm: "/conversions/add-form",
  conversionsUpdateForm: "/conversions/edit-form",
  conversionAccountDetails: "/conversions/account-details",
  conversionDetails: "/conversions/conversion-details",
  adCopies: "/ad-copies",
  learnAboutTrialPartners: "/learn-about-trial-partners",
  sendFeedback: "/send-feedback",
  aboutUs: "/about-us",
  privacyPolicy: "/privacy-policy",
};

export default routeConstants;
