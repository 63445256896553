import { AxiosResponse } from "axios";
import axiosClient from "../apiClient";

// SAGA FLOW EXAMPLE FOR FUTURE API INTEGRATION

export const UserService = async () => {
  try {
    return await axiosClient.get("https://dummyjson.com/users");
  } catch (error) {
    return error;
  }
};

export const getDashboardDataService = async (params: {
  days: number;
}): Promise<AxiosResponse> => {
  try {
    const queryString = `?days=${params.days}&limit=4`;

    const response = await axiosClient.get(`dashboard${queryString}`);
    return response.data;
  } catch (error) {
    console.error("Error in getDashboardDataService:", error);
    throw error;
  }
};
