import { AxiosResponse } from "axios";
import { createSliceSaga } from "../saga-helper";
import { call, put, select } from "redux-saga/effects";
import { ConversionsReducerAction } from "./slice";
import { ConversionsListType } from "./types";
import {
  deleteConversationService,
  getConversationListService,
} from "../../services/ConversionsService";
import { conversionsSelector } from "./selector";

const saga = createSliceSaga({
  name: "conversions-saga-state",
  caseSagas: {
    *getConversions(action: {
      payload: {
        start_date: string;
        end_date: string;
        skip: number;
        limit: number;
      };
      type: string;
    }) {
      try {
        const ConversionsList: ConversionsListType = yield select(
          conversionsSelector
        );
        yield put(ConversionsReducerAction.setIsLoading(true));
        // Step-2: Calling the Service file API and setting the data in reducer
        const { data: apiResponse, status }: AxiosResponse = yield call(
          getConversationListService,
          action.payload
        );
        if (status === 200) {
          let responseData = apiResponse.data;
          if (ConversionsList.data.length > 0 && responseData.length > 0)
            responseData = [...ConversionsList.data, ...responseData];

          yield put(
            ConversionsReducerAction.setConversions({
              total: apiResponse.total ?? 0,
              data: responseData,
            })
          );
        }
      } catch (error) {
        console.error("Error fetching Conversions!");
      } finally {
        yield put(ConversionsReducerAction.setIsLoading(false));
      }
    },
    *deleteConversion(action: {
      payload: { id: string; resolve: () => void };
    }) {
      try {
        yield put(ConversionsReducerAction.setIsLoading(true));

        // Step-2: API Call to Delete Conversation
        const { status }: AxiosResponse = yield call(
          deleteConversationService,
          action.payload.id
        );

        if (status === 200) {
          const ConversionsList: ConversionsListType = yield select(
            conversionsSelector
          );

          // Step-3: Remove Deleted Item from Redux Store
          const updatedData = ConversionsList.data.filter(
            (item) => item.id !== action.payload.id
          );
          yield put(
            ConversionsReducerAction.setConversions({
              total: ConversionsList.total - 1,
              data: updatedData,
            })
          );
        }
      } catch (error) {
        console.error("Error deleting conversion!");
      } finally {
        yield put(ConversionsReducerAction.setIsLoading(false));
        if (action.payload.resolve) {
          action.payload.resolve();
        }
      }
    },
  },
  sagaType: "takeEvery",
});

export const {
  actions: ConversionsSagaAction,
  saga: ConversionsSaga,
  name: ConversionsSagaName,
} = saga;
