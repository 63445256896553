import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

export const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "#",
    width: 80,
    headerClassName: "table-header",
    headerAlign: "center",
    cellClassName: "!text-center",
  },
  {
    field: "platform",
    headerName: "Platform",
    headerClassName: "table-header",
    resizable: false,
    editable: false,
    width: 100,
  },
  {
    field: "campaign_name",
    headerName: "Campaign",
    minWidth: 889,
    resizable: false,
    editable: false,
    renderCell: (item) => (
      <Box className={"flex gap-2 items-center"}>
        <img src={require("../../assets/sampleImage.png")} alt="random" />
        <p>{item.value}</p>
      </Box>
    ),
    headerClassName: "table-header",
  },
  {
    field: "status",
    headerName: "Status",
    headerClassName: "table-header",
    type: "string",
    cellClassName: "flex-center",
    renderCell: (item) => (
      <Box
        className={`flex-center w-11/12 h-8 rounded-md font-medium ${
          item.value === "Enable" ? "green-chip" : "blue-chip"
        }`}
      >
        {item.value}
      </Box>
    ),
    width: 110,
  },
];
