import { Box, CircularProgress, Typography } from "@mui/material";

const LoadingComponent = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="90vh" // Full screen height for centering
      gap={2} // Adds spacing between elements
    >
      <CircularProgress size={40} />
      <Typography variant="body1" color="textSecondary">
        Loading, please wait...
      </Typography>
    </Box>
  );
};

export default LoadingComponent;
