import { Box, Typography } from "@mui/material";
import SectionHeader from "../../SectionHeader";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { useSelector } from "react-redux";
import { dashboardSelector } from "../../../../store/dashboard/selector";

// Define colors for each platform
const platformColors: Record<string, string> = {
  Google: "#F4B400",
  Facebook: "#4B73B8",
  Instagram: "#BA3478",
  Tiktok: "#60C0C8",
};

// Format API response into chart data
const formatCPCData = (data: Record<string, number>) => {
  return Object.entries(data)
    .filter(([platform]) => platform !== "Total") // Exclude "Total" from individual chart data
    .map(([platform, cost]) => ({
      platform,
      cost,
      fill: platformColors[platform] || "#8884d8", // Default fallback color
    }));
};

const CostPerClick = () => {
  const { cost_per_click }: { cost_per_click: Record<string, number> } =
    useSelector(dashboardSelector);

  // Transform API response into chart data
  const costPerClickData = formatCPCData(cost_per_click);
  const totalCost = cost_per_click.Total || 0;

  return (
    <Box className="bg-white rounded-xl border border-primary-blue-100 w-full">
      <SectionHeader title="Cost per Click" />
      <Box className="xl:grid grid-cols-2 p-5 items-center border-t border-t-primary-blue-100">
        <Box className="relative w-full h-[250px] mb-10 lg:mb-0">
          <ResponsiveContainer minWidth={200}>
            <PieChart>
              {/* Donut Chart */}
              <Pie
                data={costPerClickData}
                dataKey="cost"
                nameKey="platform"
                cx="50%"
                cy="50%"
                innerRadius={80}
                outerRadius={120}
                fill="#8884d8"
                labelLine={false}
              >
                {costPerClickData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.fill} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
          {/* Total Cost in Center */}
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
            }}
          >
            <Typography className="!text-md !text-primary-black-600">
              Total
            </Typography>
            <Typography className="!text-primary-black-950 !text-2xl !font-bold">
              ${totalCost.toFixed(2)}
            </Typography>
          </Box>
        </Box>

        <Box className="flex-column gap-3 p-5 rounded-[10px] border border-primary-blue-100">
          {costPerClickData.map((item, index) => (
            <Box
              key={index}
              className={`flex gap-[13px] ${
                index !== costPerClickData.length - 1 &&
                "pb-2 border-b border-b-primary-blue-100"
              }`}
            >
              <Box
                className="w-3 h-3 rounded-full mt-1"
                sx={{ background: item.fill }}
              />
              <Box className="flex-column gap-2.5">
                <Typography className="!text-md !leading-6 !text-primary-black-600">
                  {item.platform}
                </Typography>
                <Typography className="!text-xl !font-bold">
                  ${item.cost.toFixed(2)}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default CostPerClick;
