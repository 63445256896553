import React from "react";
import { Controller, FieldValues } from "react-hook-form";
import { Stack, Typography, TextareaAutosize } from "@mui/material";
import { styled } from "@mui/system";
import FormFieldLabel from "./FormFieldLabel";
import { FieldConfig } from "../../types/forms";

// Styled TextareaAutosize component
const StyledTextareaAutosize = styled(TextareaAutosize)<{ error?: boolean }>(
  ({ error }) => ({
    width: "100%",
    outline: "none",
    padding: "8px",
    border: error ? "1px solid #C82627" : "1px solid #E5E5E5",
    borderRadius: "12px",
    minHeight: "32px",
    overflowY: "auto",
    resize: "none",
    color: "#21232C",
    backgroundColor: "#fff",
    "&:focus": {
      border: "1px solid #1E3A8A", // Blue border on focus
      outline: "none",
    },
  })
);

const CustomTextArea = <T extends FieldValues, U extends FieldValues = T>({
  name,
  label = "",
  rows,
  control,
  onChange,
  customClassName = "",
  required,
  placeholder,
  errors,
  rules,
}: FieldConfig<T, U> & { errors?: string }) => {
  return (
    <Controller
      name={name!}
      control={control}
      rules={rules as any}
      render={({ field }) => (
        <Stack className={"flex-col gap-2"}>
          {label && <FormFieldLabel label={label} required={required} />}
          {rows && (
            <StyledTextareaAutosize
              className={`${customClassName} custom-scrollbar`}
              minRows={rows}
              placeholder={placeholder}
              onChange={(e) => {
                field.onChange(e.target.value);
                onChange?.();
              }}
              value={field.value ?? ""}
              error={!!errors}
            />
          )}
          {errors && (
            <Typography
              sx={{ color: "#C82627", fontSize: "14px", fontWeight: "600" }}
            >
              {errors}
            </Typography>
          )}
        </Stack>
      )}
    />
  );
};

export default CustomTextArea;
