// store/breadcrumb/selector.ts
import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./slice";

const selectDomain = (state: any) => state.breadcrumb || initialState;

export const breadcrumbSelector = createSelector(
  selectDomain,
  (state) => state.items
);
