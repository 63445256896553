import { useEffect, useState } from "react";
import AdCopiesHeader, {
  FilterCategory,
} from "../components/adCopies/AdCopiesHeader";
import { Box, CircularProgress, Typography } from "@mui/material";
import AdCopiesCard from "../components/common/AdCopiesCard";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  AdCopiesReducer,
  AdCopiesReducerAction,
  AdCopiesReducerName,
} from "../store/adcopies/slice";
import {
  AdCopiesSaga,
  AdCopiesSagaAction,
  AdCopiesSagaName,
} from "../store/adcopies/sagas";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  adCopiesSelector,
  adCopiesSkipSelector,
  dropdownListSkipSelector,
  isAdCopiesLoadingSelector,
} from "../store/adcopies/selector";
import VirtuosoList from "../components/common/VirtuosoList";
import { AdCopiesFilterType, SORT } from "../types/adCopiesFilterTypes";
import { useForm } from "react-hook-form";

const AdCopies = () => {
  let limit = 12;
  const { dropdown }: { dropdown: FilterCategory[] } = useSelector(
    dropdownListSkipSelector
  );
  useInjectReducer({
    reducer: AdCopiesReducer,
    key: AdCopiesReducerName,
  });

  // Injecting the Sagas
  useInjectSaga({
    saga: AdCopiesSaga,
    key: AdCopiesSagaName,
  });
  const [open, setOpen] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const adCopiesList = useSelector(adCopiesSelector);
  const adCopiesSkip = useSelector(adCopiesSkipSelector);
  const isAdCopiesLoading = useSelector(isAdCopiesLoadingSelector);
  const adCopiesFiltersForm = useForm<AdCopiesFilterType>();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(AdCopiesSagaAction.getDropdownList());
    dispatch(
      AdCopiesSagaAction.getAdCopies({
        skip: 0,
        limit,
      })
    );
  }, []);

  useEffect(() => {
    setHasMore(adCopiesList["ad-copies"].length < adCopiesList?.total);
  }, [adCopiesList]);

  const onLoadMore = () => {
    if (hasMore) {
      dispatch(AdCopiesReducerAction.setAdCopiesSkip(adCopiesSkip + limit));
      dispatch(
        AdCopiesSagaAction.getAdCopies({
          skip: adCopiesSkip + limit,
          limit,
        })
      );
    }
  };

  const CustomTableRows = ({ row }: { row: any }) => {
    return <AdCopiesCard adCopy={row} />;
  };

  const applyFilters = () => {
    const filters = adCopiesFiltersForm.getValues();
    const { date_range, ...otherFilters } = filters;
    const { start_date, end_date } =
      (date_range as { start_date?: string; end_date?: string }) || {};
    dispatch(
      AdCopiesReducerAction.setAdCopies({
        total: 0,
        "ad-copies": [],
      })
    );
    dispatch(
      AdCopiesSagaAction.getAdCopies({
        skip: 0,
        limit,
        start_date,
        end_date,
        ...otherFilters,
      })
    );
    setOpen(false);
  };

  const clearFilters = () => {
    // Extract default values dynamically
    const defaultValues = Object.fromEntries(
      dropdown.map((filter) => [
        filter.name.toLowerCase().replace(/\s/g, "_"),
        filter.custom_filter_values.length > 0 ? [] : "", // Reset multi-select as empty array, others as empty string
      ])
    );

    // Reset the form with default values
    adCopiesFiltersForm.reset({
      date_range: "",
      sort_by_date: "asc" as SORT,
      ...defaultValues,
    });
    dispatch(
      AdCopiesReducerAction.setAdCopies({
        total: 0,
        "ad-copies": [],
      })
    );
    dispatch(
      AdCopiesSagaAction.getAdCopies({
        skip: 0,
        limit,
      })
    );
    setOpen(false);
  };

  return (
    <Box className="flex flex-col gap-6">
      <AdCopiesHeader
        open={open}
        setOpen={setOpen}
        dropdown={dropdown}
        adCopiesFiltersForm={adCopiesFiltersForm}
        applyFilters={applyFilters}
        clearFilters={clearFilters}
      />
      <Box>
        {isAdCopiesLoading && adCopiesList["ad-copies"]?.length === 0 ? (
          <Box className=" flex flex-col justify-center items-center">
            <CircularProgress size={24} />
            Loading Ad Copies...
          </Box>
        ) : adCopiesList["ad-copies"]?.length === 0 ? (
          <Box className="flex flex-col justify-center items-center">
            <Typography>No Ad Copies found!</Typography>
          </Box>
        ) : (
          <VirtuosoList
            data={adCopiesList["ad-copies"] ?? []}
            dataLength={adCopiesList["ad-copies"].length}
            isLoading={isAdCopiesLoading}
            onLoadMore={onLoadMore}
            totalData={adCopiesList.total}
            CustomTableRows={CustomTableRows}
            tableCellCount={1}
            height={"400px"}
            CustomTableRowsCustomClassName="flex"
            useGridLayout={true}
          />
        )}
      </Box>
    </Box>
  );
};

export default AdCopies;
