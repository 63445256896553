import { createBrowserRouter } from "react-router-dom";
import MainRoutes from "./MainRoutes";
import LoginWithTrialPartners from "../pages/auth/login-with-trialpartners";
import GuestGuard from "./GuestGaurds";
import routeConstants from "../utils/routeConstants";

const router = createBrowserRouter([
  // {
  //   path: "/login",
  //   element: (
  //     <GuestGuard>
  //       {/* use '/login' to access login page */}
  //       <Login />
  //     </GuestGuard>
  //   ),
  // },
  {
    path: routeConstants.login,
    element: (
      <GuestGuard>
        <LoginWithTrialPartners />
      </GuestGuard>
    ),
  },
  MainRoutes,
]);

export default router;
