import { Box, Typography } from "@mui/material";
import SaveAndGenerateCardHeader from "../../common/SaveAndGenerateCardHeader";
import { CancelIcon, InfoDarkIcon } from "../../Icons";
import { UseFormReturn } from "react-hook-form";
import { ReportType } from "../../../types/reportTypes";
import { formatName } from "../../../utils/utils";

const SelectedMetricsCard = ({
  reportStepperForm,
  setStepper,
  handlePreviousSection,
}: {
  reportStepperForm: UseFormReturn<ReportType>;
  setStepper: React.Dispatch<React.SetStateAction<number>>;
  handlePreviousSection: () => void;
}) => {
  const selectedMetricsList = reportStepperForm.watch("metrics") ?? [];

  const handleItemCancel = (itemToRemove: string) => {
    reportStepperForm.setValue(
      "metrics",
      selectedMetricsList.filter((metric: string) => metric !== itemToRemove)
    );
  };

  return (
    <Box className="border p-[30px] border-primary-blue-100 rounded-[20px] flex-column gap-[30px]">
      {/* Header  */}
      <SaveAndGenerateCardHeader
        title="Selected Metrics"
        setStepper={setStepper}
        step={2}
        handlePreviousSection={handlePreviousSection}
      />

      <Box className="grid  grid-cols-1 md:grid-cols-2 gap-5">
        {selectedMetricsList.length > 0 &&
          selectedMetricsList.map((option: string, index: number) => (
            <Box
              key={index}
              className="flex-between-center w-full border rounded-xl p-5 border-primary-blue-100"
            >
              <Box className="flex items-center gap-[15px]">
                <Box
                  onClick={() => handleItemCancel(option)}
                  className="flex-center cursor-pointer !w-6 !h-6 !rounded-full !bg-primary-black-50"
                >
                  {CancelIcon}
                </Box>
                <Typography>{formatName(option)}</Typography>
              </Box>
              {InfoDarkIcon}
            </Box>
          ))}
      </Box>
      {!(selectedMetricsList.length > 0) && (
        <p className="text-center text-sm">No data found!</p>
      )}
    </Box>
  );
};

export default SelectedMetricsCard;
