import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./header";
import Sidebar from "./sidebar";

const MainLayout = () => {
  return (
    <Box className="flex">
      {/* Sidebar (Fixed for Desktop) */}
      <Sidebar />

      {/* Main Content */}
      <Box className="flex-1 min-h-screen lg:ml-[256px] overflow-hidden">
        <Header />

        {/* Page Content */}
        <Box className="h-full bg-primary-blue-50 w-full p-4 md:p-6 lg:p-[30px] mt-[65px] lg:mt-[78px] overflow-auto">
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
