import { AxiosResponse } from "axios";
import axiosClient from "../apiClient";
import { format } from "date-fns";
import { DATE_FORMAT } from "../utils/appConstants";

export const getPatientList = async ({
  per_page = 1000,
}: {
  per_page?: number;
}) => {
  try {
    const response = await axiosClient.get(
      `/form/patient-list?per_page=${per_page}`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching patient list:", error);
    throw error;
  }
};

export const saveFormForCampaignsService = async (
  formData: FormData
): Promise<AxiosResponse> => {
  try {
    const response = await axiosClient.post("/form/save", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in saveFormForCampaignsService:", error);
    throw error;
  }
};

export const getConversationListService = async (params: {
  skip: number;
  limit: number;
  start_date: string;
  end_date: string;
}): Promise<AxiosResponse> => {
  try {
    const startDateString = format(params.start_date, DATE_FORMAT);
    const endDateString = format(params.end_date, DATE_FORMAT);

    const queryString = `?start_date=${startDateString}&end_date=${endDateString}&skip=${params.skip}&limit=${params.limit}`;

    const response = await axiosClient.get(`form/list${queryString}`);
    return response.data;
  } catch (error) {
    console.error("Error in getConversationListService:", error);
    throw error;
  }
};

export const getPlateFormFields = async () => {
  try {
    const response = await axiosClient.get(`form/platform-fields`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching patient list:", error);
    throw error;
  }
};

export const getConversationAccountDetailService = async (params: {
  skip: number;
  limit: number;
  form_id: string | undefined;
  search: string;
  provider: string;
  campaign_ids: Array<string>;
}): Promise<AxiosResponse> => {
  const queryString = `?skip=${params.skip}&limit=${params.limit}&form_id=${params.form_id}&search=${params.search}&provider=${params.provider}`;

  const response = await axiosClient.get(`/conversions${queryString}`, {
    params: {
      campaign_ids: params.campaign_ids,
    },
  });
  return response.data;
};

export const getConversationDetailService = async (params: {
  conversion_id: string | undefined;
}): Promise<AxiosResponse> => {
  const queryString = `?conversion_id=${params.conversion_id}`;

  const response = await axiosClient.get(`conversions/view${queryString}`);
  return response.data;
};

export const getFormDetails = async (id: string) => {
  try {
    const response = await axiosClient.get(`form/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching patient list:", error);
    throw error;
  }
};

export const deleteConversationService = async (id: string) => {
  try {
    let body = {
      form_id: id,
    };
    const response = await axiosClient.post(`form/delete`, body);
    return response.data;
  } catch (error) {
    console.error("Error fetching patient list:", error);
    throw error;
  }
};

export const deleteConversationFormFieldService = async (id: string) => {
  try {
    let body = {
      form_field_id: id,
    };
    const response = await axiosClient.post(`form/delete/form-field`, body);
    return response.data;
  } catch (error) {
    console.error("Error fetching patient list:", error);
    throw error;
  }
};
