import { Box, Typography } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
  Cell,
} from "recharts";
import SectionHeader from "../../SectionHeader";
import { useSelector } from "react-redux";
import { dashboardSelector } from "../../../../store/dashboard/selector";
import { DashboardState } from "../../../../store/dashboard/types";

// Define colors for each platform
const platformColors: Record<string, string> = {
  Google: "#F4B400",
  Facebook: "#3B5998",
  Instagram: "#C13584",
  Tiktok: "#69C9D0",
};

// Format the API response into an array for Recharts
const formatROASData = (data: Record<string, number>) => {
  return Object.entries(data).map(([platform, value]) => ({
    platform,
    ROAS: value,
    color: platformColors[platform] || "#8884d8", // Default fallback color
  }));
};

const AdSpentReturn = () => {
  const { return_on_ad_spend }: DashboardState = useSelector(dashboardSelector);

  // Ensure return_on_ad_spend is transformed into a Record<string, number>
  const adSpentReturnData = formatROASData(
    Object.entries(return_on_ad_spend || {}).reduce((acc, [key, value]) => {
      if (typeof value === "number") {
        acc[key] = value;
      }
      return acc;
    }, {} as Record<string, number>)
  );

  // Custom legend rendering
  const renderLegend = () => {
    return (
      <Box className="!grid !grid-cols-2 md:!grid-cols-4 w-[60%] md:flex-row gap-3 !mt-[45px] m-auto">
        {adSpentReturnData.map((entry, index) => (
          <Box
            key={index}
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            <Box
              sx={{
                width: 12,
                height: 12,
                backgroundColor: entry.color,
                borderRadius: "50%",
              }}
            />
            <Typography variant="body2">{entry.platform}</Typography>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Box className="bg-white rounded-xl border border-primary-blue-100 w-full">
      <SectionHeader title="Return on Ad Spend (Conversions on Ad Spend)" />
      <ResponsiveContainer
        minWidth={200}
        className="p-5 pl-0 !w-full !h-[500px] md:!h-[650px] xl:!h-[400px] border-t border-t-primary-blue-100"
      >
        <BarChart data={adSpentReturnData} barGap={20} barCategoryGap={40}>
          <CartesianGrid stroke="#E9ECF2" vertical={false} />
          <XAxis
            dataKey="platform"
            tick={{ fill: "#666" }}
            stroke="#E9ECF2"
            label={{
              value: "Platform",
              position: "insideBottom",
              offset: -20,
            }}
          />
          <YAxis
            domain={[0, 40]}
            ticks={[0, 10, 20, 30, 40]}
            tick={{ fill: "#666" }}
            stroke="#E9ECF2"
            label={{
              value: "ROAS",
              angle: -90,
              position: "insideLeft",
              offset: 10,
            }}
          />
          <Tooltip />
          <Legend content={renderLegend} />
          <Bar dataKey="ROAS" barSize={50} radius={[5, 5, 0, 0]}>
            {adSpentReturnData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default AdSpentReturn;
