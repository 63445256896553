import React, { useEffect, useState } from "react";
import { CMSPageService } from "../services/CMSPageService";
import { AxiosResponse } from "axios";
import { Box, Typography } from "@mui/material";
import LoadingComponent from "../components/common/LoadingComponent";

export interface CMSPage {
  id: number;
  name: string;
  key: string;
  value: string; // HTML content
  type: string;
  created_at: string; // ISO date string
  updated_at: string; // ISO date string
  value_url: string | null;
}

const PrivacyPolicy = () => {
  const [responseData, setResponseData] = useState<CMSPage>({} as CMSPage);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getCMSPageData();
  }, []);

  const getCMSPageData = async () => {
    try {
      const { data, status }: AxiosResponse = await CMSPageService(1);
      if (status === 200) {
        setResponseData(data?.data[0]);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <LoadingComponent />
  ) : (
    <>
      <Box className="bg-white flex-column  w-full rounded-[20px] border border-primary-blue-100">
        <Box className="flex-between-center gap-5 p-5 border-b border-b-primary-blue-100 ">
          <Typography className="!text-xl !font-medium">
            {responseData.name}
          </Typography>
        </Box>

        <Box className="py-6 px-5">
          <div
            dangerouslySetInnerHTML={{
              __html: responseData?.value || "No content available",
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default PrivacyPolicy;
