import React from "react";

interface ItemProps {
  children: React.ReactNode;
  [x: string]: any;
}

interface ListProps {
  style?: React.CSSProperties;
  children: React.ReactNode;
  [x: string]: any;
}

const gridComponents = {
  List: React.forwardRef<HTMLDivElement, ListProps>(
    ({ style, children, ...props }, ref) => (
      <div
        ref={ref}
        {...props}
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          ...style,
        }}
      >
        {children}
      </div>
    )
  ),

  Item: React.forwardRef<HTMLDivElement, ItemProps>(
    ({ children, ...props }, ref) => (
      <div
        ref={ref}
        {...props}
        className="w-full sm:w-[49%] lg:w-[24%]"
        style={{
          padding: "0.5rem",
          display: "flex",
          flex: "none",
          alignContent: "stretch",
          boxSizing: "border-box",
          justifyContent: "center",
        }}
      >
        {children}
      </div>
    )
  ),
};

export { gridComponents };
